import React, { useState, useEffect, useCallback, useMemo } from "react";
import ClientDataService from "../services/CustomerService";
import { useTable, useSortBy, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import { toastService } from '../services/toastService';

// Reusable Components
const SearchFilters = ({ 
  clientName, 
  setClientName, 
  clientCode, 
  setClientCode, 
  onSearch,
}) => (
  <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Ime Klijenta
        </label>
        <input
          type="text"
          value={clientName}
          onChange={e => setClientName(e.target.value)}
          placeholder="Unesite ime klijenta..."
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Šifra Klijenta
        </label>
        <input
          type="text"
          value={clientCode}
          onChange={e => setClientCode(e.target.value)}
          placeholder="Unesite šifru klijenta..."
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
    </div>
    <div className="flex flex-wrap gap-2">
      <button
        onClick={onSearch}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        <i className="fas fa-search mr-2"></i>
        Pretraga
      </button>
    </div>
  </div>
);

const ActionButton = ({ onClick, icon, label, variant = "primary" }) => {
  const baseClasses = "inline-flex items-center px-2.5 py-1.5 text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200";
  const variants = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500",
    info: "bg-cyan-600 hover:bg-cyan-700 text-white focus:ring-cyan-500",
    warning: "bg-yellow-600 hover:bg-yellow-700 text-white focus:ring-yellow-500",
  };

  return (
    <button 
      onClick={onClick}
      className={`${baseClasses} ${variants[variant]}`}
      title={label}
    >
      <i className={`fas ${icon}`}></i>
      <span className="ml-1.5">{label}</span>
    </button>
  );
};

const ClientsList = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientCode, setClientCode] = useState("");
  const [clientName, setClientName] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const loadClients = async () => {
    try {
      setLoading(true);
      const response = await ClientDataService.getAll();
      if (Array.isArray(response.data)) {
        const sortedClients = response.data.sort((a, b) => 
          (a.isActive === b.isActive) ? 0 : a.isActive ? 1 : -1
        );
        setClients(sortedClients);
        setError(null);
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (err) {
      setError("Došlo je do greške prilikom učitavanja klijenata");
      toastService.error("Greška pri učitavanju podataka");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadClients();
  }, []);

  const handleSearch = useCallback(() => {
    const filteredClients = clients.filter((client) => {
      const nameMatches = !clientName || 
        client.nameOfTheLegalEntity.toLowerCase().includes(clientName.toLowerCase());
      const codeMatches = !clientCode || 
        (client.customerCode && client.customerCode.toLowerCase().includes(clientCode.toLowerCase()));
      return nameMatches && codeMatches;
    });
    setClients(filteredClients);
  }, [clients, clientName, clientCode]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        id: 'row-number',
        accessor: (row, i) => i + 1,
        Cell: ({ value }) => (
          <span className="text-gray-500 font-medium">
            {value}
          </span>
        ),
        width: 50,
        sortType: 'number'
      },
      {
        Header: "Šifra",
        accessor: "customerCode",
        width: 120,
      },
      {
        Header: "Naziv",
        accessor: "nameOfTheLegalEntity",
        width: 200,
      },
      {
        Header: "Grad",
        accessor: "city",
        width: 120,
      },
      {
        Header: "PIB",
        accessor: "pib",
        width: 100,
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ value }) => (
          <span className={`
            px-2 py-1 rounded-full text-xs font-medium
            ${value ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}
          `}>
            {value ? 'Aktivan' : 'Neaktivan'}
          </span>
        ),
        width: 100,
      },
      {
        Header: "Kontakt",
        accessor: "contactPerson",
        Cell: ({ row }) => (
          <div>
            <div>{row.original.contactPerson}</div>
            <div className="text-sm text-gray-500">{row.original.contactNumber}</div>
            <div className="text-sm text-gray-500">{row.original.email}</div>
          </div>
        ),
        width: 200,
      },
      {
        Header: "Valuta Placanja",
        accessor: "paymentCurrency",
        width: 80,
        Cell: ({ value }) => (
          <span className="text-gray-900 font-medium">
            {value || 'RSD'}
          </span>
        ),
      },
      {
        Header: "Akcije",
        accessor: "actions",
        Cell: ({ row: { original } }) => (
          <div className="flex items-center space-x-1">
            <ActionButton
              onClick={() => navigate(`/clients/edit/${original.customer_id}`)}
              icon="fa-edit"
              label="Izmeni"
              variant="primary"
            />
            <ActionButton
              onClick={() => navigate('/discountsOfClient', { 
                state: { clientID: original.customer_id } 
              })}
              icon="fa-percent"
              label="Rabat"
              variant="info"
            />
            <ActionButton
              onClick={() => navigate('/addressesOfClient', { 
                state: { clientID: original.customer_id } 
              })}
              icon="fa-building"
              label="Jedinice"
              variant="warning"
            />
          </div>
        ),
        width: 280,
      },
    ],
    [navigate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: clients,
      initialState: {
        pageSize,
        sortBy: [{ id: 'row-number', desc: false }]
      }
    },
    useSortBy,
    usePagination
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Lista Klijenata</h1>
        <ActionButton
          onClick={() => navigate('/clients/add')}
          icon="fa-plus"
          label="Dodaj Klijenta"
          variant="primary"
        />
      </div>

      <SearchFilters
        clientName={clientName}
        setClientName={setClientName}
        clientCode={clientCode}
        setClientCode={setClientCode}
        onSearch={handleSearch}
      />

      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`
                        px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
                        ${column.id === 'row-number' ? 'text-center' : ''}
                      `}
                      style={{ width: column.width }}
                    >
                      {column.render('Header')}
                      {column.id !== 'row-number' && column.id !== 'actions' && (
                        <span className="ml-2">
                          {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr 
                    {...row.getRowProps()}
                    className={`
                      hover:bg-gray-50 transition-colors duration-150
                      ${!row.original.isActive ? 'bg-red-50' : ''}
                    `}
                  >
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        className={`
                          px-6 py-4 whitespace-nowrap text-sm text-gray-900
                          ${cell.column.id === 'row-number' ? 'text-center' : ''}
                          ${cell.column.id === 'actions' ? 'text-right' : ''}
                        `}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Prethodna
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Sledeća
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Prikazano{' '}
                <span className="font-medium">{pageIndex * pageSize + 1}</span>
                {' '}-{' '}
                <span className="font-medium">
                  {Math.min((pageIndex + 1) * pageSize, clients.length)}
                </span>
                {' '}od{' '}
                <span className="font-medium">{clients.length}</span>
                {' '}rezultata
              </p>
            </div>
            <div className="flex gap-x-2">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                  setTablePageSize(Number(e.target.value));
                }}
                className="form-select rounded-md border-gray-300 text-base"
              >
                {[10, 20, 30, 40, 50].map(size => (
                  <option key={size} value={size}>
                    Prikaži {size}
                  </option>
                ))}
              </select>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsList;