import React, { useState } from "react";
import { useUserLogin } from "../components/hooks/useUserLogin";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faSignInAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import "./Login.css";
import Credits from "./Credits";
import logo from "./../images/logo.jpg";

const InputField = ({ label, type, value, onChange, placeholder, icon }) => (
  <div className="relative mb-6">
    <label 
      className="block text-sm font-medium text-gray-700 mb-2" 
      htmlFor={type}
    >
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <FontAwesomeIcon icon={icon} className="text-blue-400" />
      </div>
      <input
        type={type}
        id={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`
          block w-full pl-10 pr-3 py-2.5 
          border border-gray-300 rounded-lg
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
          transition duration-150 ease-in-out
          hover:border-blue-300
          ${type === 'password' ? 'tracking-wider' : ''}
        `}
      />
    </div>
  </div>
);

const Login = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    error,
    handleLogin,
  } = useUserLogin();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await handleLogin(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 to-blue-800 py-12 px-4 sm:px-6 lg:px-8 flex flex-col justify-center">
      <div className="max-w-md w-full mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-xl shadow-xl p-8"
        >
          {/* Header */}
          <div className="text-center mb-8">
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
              className="h-32 w-32 mx-auto mb-6"
            >
              <img 
                src={logo}
                alt="Virginia Logo" 
                className="w-full h-full object-contain rounded-lg"
              />
            </motion.div>
            <h2 className="text-3xl font-bold text-gray-900 mb-2">
              Dobrodošli nazad
            </h2>
            <p className="text-gray-600">
              Prijavite se na Virdžinija Portal
            </p>
          </div>

          {/* Error Message */}
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-6 p-4 rounded-lg bg-red-50 border border-red-200"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faExclamationCircle} className="text-red-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </motion.div>
          )}

          {/* Login Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            <InputField
              label="Korisničko ime"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Unesite korisničko ime"
              icon={faUser}
            />

            <InputField
              label="Šifra"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Unesite šifru"
              icon={faLock}
            />

            <div className="pt-2">
              <button
                type="submit"
                disabled={isLoading}
                className={`
                  w-full flex justify-center items-center
                  px-4 py-3 rounded-lg
                  text-white font-medium
                  bg-blue-600 hover:bg-blue-700
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                  transition duration-150 ease-in-out
                  ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}
                `}
              >
                {isLoading ? (
                  <>
                    <svg 
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" 
                      xmlns="http://www.w3.org/2000/svg" 
                      fill="none" 
                      viewBox="0 0 24 24"
                    >
                      <circle 
                        className="opacity-25" 
                        cx="12" 
                        cy="12" 
                        r="10" 
                        stroke="currentColor" 
                        strokeWidth="4"
                      />
                      <path 
                        className="opacity-75" 
                        fill="currentColor" 
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Prijavljivanje...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                    Prijavi se
                  </>
                )}
              </button>
            </div>
          </form>

          {/* Credits Section */}
          <div className="mt-8 pt-6 border-t border-gray-200">
            <Credits />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Login;
