import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import DiscountService from "../services/DiscountService";
import CustomerService from "../services/CustomerService";
import BrandService from "../services/BrandService"; // Assuming this exists
import { useTable, useSortBy } from "react-table";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toastService } from '../services/toastService';

// Reusable Components
const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4">
                <div className="flex justify-between items-center px-6 py-4 border-b">
                    <h3 className="text-lg font-medium text-gray-900">{title}</h3>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-500"
                    >
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <div className="px-6 py-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

const FormField = ({ label, name, type = "text", formik, children }) => (
    <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
            {label}
        </label>
        {children || (
            <input
                type={type}
                name={name}
                value={formik.values[name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`
                    w-full px-3 py-2 border rounded-md shadow-sm
                    ${formik.touched[name] && formik.errors[name]
                        ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                        : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                    }
                `}
            />
        )}
        {formik.touched[name] && formik.errors[name] && (
            <p className="mt-1 text-sm text-red-600">{formik.errors[name]}</p>
        )}
    </div>
);

const ActionButton = ({ onClick, icon, label, variant = "primary" }) => {
    const baseClasses = "inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200";
    const variants = {
        primary: "bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500",
        danger: "bg-red-600 hover:bg-red-700 text-white focus:ring-red-500",
    };

    return (
        <button 
            onClick={onClick}
            className={`${baseClasses} ${variants[variant]}`}
        >
            <i className={`fas ${icon} mr-2`}></i>
            {label}
        </button>
    );
};

const ClientDiscounts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { clientID } = location.state || {};
    const [customer, setCustomer] = useState(null);
    const [discounts, setDiscounts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const addedBrandNames = new Set(discounts.map(d => d.brand.brandName));

    const validationSchema = Yup.object().shape({
        selectedBrand: Yup.string().required('Morate izabrati brend.'),
        brandDiscount: Yup.number()
            .required('Rabat je obavezan.')
            .min(0, 'Rabat ne može biti negativan.')
            .max(100, 'Rabat ne može biti veći od 100%'),
    });

    const addFormik = useFormik({
        initialValues: {
            selectedBrand: '',
            brandDiscount: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await DiscountService.createCustomerDiscount(
                    clientID, 
                    values.selectedBrand, 
                    values.brandDiscount
                );
                await loadDiscounts();
                setIsAddModalOpen(false);
                toastService.success('Uspešno dodat rabat!');
            } catch (error) {
                toastService.error('Greška pri dodavanju rabata!');
            }
        },
    });

    const editFormik = useFormik({
        initialValues: {
            selectedBrand: '',
            brandDiscount: '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                await DiscountService.updateCustomerDiscount(
                    clientID, 
                    values.selectedBrand, 
                    values.brandDiscount
                );
                await loadDiscounts();
                setIsEditModalOpen(false);
                toastService.success('Uspešno ažuriran rabat!');
            } catch (error) {
                toastService.error('Greška pri ažuriranju rabata!');
            }
        },
    });

    const loadDiscounts = async () => {
        try {
            const response = await DiscountService.findDiscountsOfSpecificClient(clientID);
            setDiscounts(response || []);
        } catch (error) {
            toastService.error('Greška pri učitavanju rabata!');
        }
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                const [customerResponse, brandsResponse] = await Promise.all([
                    CustomerService.get(clientID),
                    BrandService.getAll()
                ]);
                setCustomer(customerResponse.data);
                setBrands(brandsResponse.data);
                await loadDiscounts();
            } catch (error) {
                toastService.error('Greška pri učitavanju podataka!');
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, [clientID]);

    const handleDelete = async (brandName) => {
        if (window.confirm("Da li ste sigurni da želite da obrišete ovaj rabat?")) {
            try {
                await DiscountService.deleteCustomerDiscount(clientID, brandName, 0);
                await loadDiscounts();
                toastService.success('Uspešno obrisan rabat!');
            } catch (error) {
                toastService.error('Greška pri brisanju rabata!');
            }
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "#",
                id: 'row-number',
                Cell: ({ row }) => (
                    <span className="text-gray-500 font-medium">
                        {row.index + 1}
                    </span>
                ),
                width: 50,
            },
            {
                Header: "Brend",
                accessor: "brand.brandName",
                width: 200,
            },
            {
                Header: "Rabat (%)",
                accessor: "discount",
                Cell: ({ value }) => (
                    <span className="font-medium">{value}%</span>
                ),
                width: 100,
            },
            {
                Header: "Akcije",
                accessor: "actions",
                Cell: ({ row: { original } }) => (
                    <div className="flex items-center space-x-2">
                        <ActionButton
                            onClick={() => {
                                setSelectedDiscount(original);
                                editFormik.setValues({
                                    selectedBrand: original.brand.brandName,
                                    brandDiscount: original.discount,
                                });
                                setIsEditModalOpen(true);
                            }}
                            icon="fa-edit"
                            label="Izmeni"
                            variant="primary"
                        />
                        <ActionButton
                            onClick={() => handleDelete(original.brand.brandName)}
                            icon="fa-trash"
                            label="Obriši"
                            variant="danger"
                        />
                    </div>
                ),
                width: 200,
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: discounts,
            initialState: {
                sortBy: [{ id: 'row-number', desc: false }]
            }
        },
        useSortBy
    );

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-[400px]">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h1 className="text-2xl font-semibold text-gray-900">Rabati Klijenta</h1>
                    <p className="mt-1 text-sm text-gray-600">
                        {customer?.nameOfTheLegalEntity}
                    </p>
                </div>
                <div className="flex space-x-3">
                    <button
                        onClick={() => navigate('/clients')}
                        className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <i className="fas fa-arrow-left mr-2"></i>
                        Nazad
                    </button>
                    <button
                        onClick={() => {
                            addFormik.resetForm();
                            setIsAddModalOpen(true);
                        }}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <i className="fas fa-plus mr-2"></i>
                        Dodaj Rabat
                    </button>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className="overflow-x-auto">
                    <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className={`
                                                px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
                                                ${column.id === 'row-number' ? 'text-center' : ''}
                                            `}
                                            style={{ width: column.width }}
                                        >
                                            {column.render('Header')}
                                            {column.id !== 'row-number' && column.id !== 'actions' && (
                                                <span className="ml-2">
                                                    {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                                                </span>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} className="hover:bg-gray-50">
                                        {row.cells.map(cell => (
                                            <td
                                                {...cell.getCellProps()}
                                                className={`
                                                    px-6 py-4 whitespace-nowrap text-sm text-gray-900
                                                    ${cell.column.id === 'row-number' ? 'text-center' : ''}
                                                `}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Add Modal */}
            <Modal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                title="Dodaj Novi Rabat"
            >
                <form onSubmit={addFormik.handleSubmit}>
                    <FormField label="Brend" name="selectedBrand" formik={addFormik}>
                        <select
                            name="selectedBrand"
                            value={addFormik.values.selectedBrand}
                            onChange={addFormik.handleChange}
                            className="w-full px-3 py-2 border rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value="">Izaberite Brend</option>
                            {brands
                                .filter(brand => !addedBrandNames.has(brand.brandName))
                                .map((brand, index) => (
                                    <option key={index} value={brand.brandName}>
                                        {brand.brandName}
                                    </option>
                                ))
                            }
                        </select>
                    </FormField>
                    <FormField 
                        label="Rabat (%)" 
                        name="brandDiscount" 
                        type="number" 
                        formik={addFormik} 
                    />
                    <div className="flex justify-end space-x-3 mt-6">
                        <button
                            type="button"
                            onClick={() => setIsAddModalOpen(false)}
                            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                            Odustani
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                        >
                            Dodaj
                        </button>
                    </div>
                </form>
            </Modal>

            {/* Edit Modal */}
            <Modal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                title="Izmeni Rabat"
            >
                <form onSubmit={editFormik.handleSubmit}>
                    <FormField label="Brend" name="selectedBrand" formik={editFormik}>
                        <select
                            name="selectedBrand"
                            value={editFormik.values.selectedBrand}
                            onChange={editFormik.handleChange}
                            className="w-full px-3 py-2 border rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value="">Izaberite Brend</option>
                            {brands.map((brand, index) => (
                                <option key={index} value={brand.brandName}>
                                    {brand.brandName}
                                </option>
                            ))}
                        </select>
                    </FormField>
                    <FormField 
                        label="Rabat (%)" 
                        name="brandDiscount" 
                        type="number" 
                        formik={editFormik} 
                    />
                    <div className="flex justify-end space-x-3 mt-6">
                        <button
                            type="button"
                            onClick={() => setIsEditModalOpen(false)}
                            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                            Odustani
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                        >
                            Sačuvaj
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default ClientDiscounts;
