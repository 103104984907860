import React, { useState, useEffect, useMemo, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import IndentEntryDataService from '../services/IndentEntryService';
import { useTable, useSortBy } from "react-table";
import { formatNumber, formatNumberKG, formatDate } from './utils';
import { toastService } from '../services/toastService';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import QRCode from 'qrcode';
import { ApplicationContext } from './ApplicationContext';

// Reusable Components
const SummaryCard = ({ label, value }) => (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <div className="text-sm font-medium text-gray-500 mb-1">{label}</div>
    <div className="text-2xl font-bold text-blue-600">{value}</div>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex justify-center items-center min-h-[400px]">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
  </div>
);

const CustomerInfoCard = ({ title, data }) => (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
    <div className="space-y-3">
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className="flex items-center">
          <span className="text-sm font-medium text-gray-500 w-24">{key}:</span>
          <span className="text-sm text-gray-900">{value}</span>
        </div>
      ))}
    </div>
  </div>
);

// QR Code Component
const IPSQRCode = ({ data, isLoading }) => (
  <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6">
    <h3 className="text-lg font-semibold text-gray-900 mb-3 sm:mb-4">IPS QR Kod</h3>
    <div className="flex flex-col items-center justify-center">
      {isLoading ? (
        <div className="animate-pulse w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 bg-gray-200 rounded"></div>
      ) : data ? (
        <img
          src={`data:image/png;base64,${data}`}
          alt="IPS QR Code"
          className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 border-2 border-blue-100 rounded-md shadow-md"
        />
      ) : (
        <div className="text-sm text-gray-500">QR kod nije dostupan</div>
      )}
      <p className="text-xs sm:text-sm text-gray-600 mt-2 sm:mt-3 text-center font-medium">
        Skenirajte za plaćanje putem IPS sistema
      </p>
    </div>
  </div>
);

// Helper function to format price for NBS
const formatPriceForNBS = (price) => {
  if (!price) return "RSD0,00";

  // Convert to number and ensure we have 2 decimal places
  const numPrice = parseFloat(price);
  
  // Format with 2 decimal places and replace dot with comma
  let formatted = numPrice.toFixed(2).replace(".", ",");
  
  // Add RSD prefix
  return "RSD" + formatted;
};

// Helper function to truncate text
const truncateField = (value, maxLen) => {
  if (!value) return "";
  return value.toString().substring(0, maxLen);
};

const IndentEntries = () => {
  const { code: indentCode } = useParams();
  const navigate = useNavigate();
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [qrLoading, setQrLoading] = useState(false);
  const { userRole } = useContext(ApplicationContext);
  useEffect(() => {
    const fetchEntries = async () => {
      try {
        setLoading(true);
        const response = await IndentEntryDataService.findIndentEntries(indentCode);
        setEntries(response.data);
        setError(null);
      } catch (err) {
        setError("Došlo je do greške prilikom učitavanja stavki porudžbine");
        toastService.error("Greška pri učitavanju podataka");
      } finally {
        setLoading(false);
      }
    };
    fetchEntries();
  }, [indentCode]);

  // Generate IPS QR code when entries are loaded
  useEffect(() => {
    const generateIPSQRCode = async () => {
      if (!entries.length) return;

      try {
        setQrLoading(true);

        const customer = entries[0]?.customer;
        const totals = entries.reduce((acc, item) => ({
          cost: acc.cost + (item.finalPriceForGivenQuantity || 0),
          weight: acc.weight + (item.articleWeightForGivenQuantity || 0),
          packages: acc.packages + (item.numberOfPackages || 0)
        }), { cost: 0, weight: 0, packages: 0 });

        // Format data for IPS QR code - using the correct format from NBS documentation
        const account = "265110031008430707"; // Account without dashes
        const nbsAmount = formatPriceForNBS(totals.cost); // Pass the exact invoice amount
        const reference = indentCode || "12345";
        const payeeName = "Virdžinija d.o.o."; // Correct company name
        const description = truncateField(`Porudžbina ${indentCode}`, 35);
        const sfCode = "189";

        const textBody =
          `K:PR|V:01|C:1|` +
          `R:${account}|` +
          `N:${payeeName}|` +
          `I:${nbsAmount}|` +
          `SF:${sfCode}|` +
          `S:${description}|` +
          `RO:${reference}`;

        console.log("[DEBUG] Sending this text_body to NBS:", textBody);
        console.log("[DEBUG] Invoice amount:", totals.cost, "Formatted amount:", nbsAmount);

        // Call NBS API to generate QR code
        try {
          const response = await axios.post("https://nbs.rs/QRcode/api/qr/v1/generate", textBody, {
            headers: {
              'Content-Type': 'text/plain'
            }
          });

          console.log("[DEBUG] NBS API response:", response.data);

          if (response.data && response.data.s && response.data.s.code === 0) {
            console.log("[DEBUG] Successfully retrieved base64 QR code");
            setQrCodeData(response.data.i);
          } else {
            const errCode = response.data?.s?.code;
            const errDesc = response.data?.s?.desc || "Unknown error";
            console.error(`[DEBUG] NBS API error: ${errCode} - ${errDesc}`);

            if (response.data?.e) {
              console.error("[DEBUG] Additional error info:", response.data.e);
            }

            // Fallback to local QR code generation if NBS API fails
            generateLocalQRCode(textBody);
          }
        } catch (apiError) {
          console.error("[DEBUG] Error calling NBS API:", apiError);
          // Fallback to local QR code generation
          generateLocalQRCode(textBody);
        }
      } catch (err) {
        console.error("Failed to generate QR code:", err);
        setQrCodeData(null);
      } finally {
        setQrLoading(false);
      }
    };

    // Fallback function to generate QR code locally
    const generateLocalQRCode = async (textBody) => {
      try {
        console.log("[DEBUG] Falling back to local QR code generation");
        console.log("[DEBUG] Using text body:", textBody);
        
        // Make sure we're using the same format as the NBS API expects
        const qrDataUrl = await QRCode.toDataURL(textBody, {
          errorCorrectionLevel: 'M',
          margin: 2,
          width: 300,
          color: {
            dark: '#000000',
            light: '#ffffff'
          }
        });

        // Remove the data URL prefix to get just the base64 data
        const base64Data = qrDataUrl.replace('data:image/png;base64,', '');
        setQrCodeData(base64Data);
      } catch (localQrError) {
        console.error("[DEBUG] Local QR generation failed:", localQrError);
        setQrCodeData(null);
      }
    };

    generateIPSQRCode();
  }, [entries, indentCode]);

  const firstEntry = entries?.[0];
  const comment = firstEntry?.indent?.comment || "";

  // Calculate totals
  const totals = useMemo(() => {
    if (!entries.length) return { cost: 0, weight: 0, packages: 0 };

    return entries.reduce((acc, item) => ({
      cost: acc.cost + (item.finalPriceForGivenQuantity || 0),
      weight: acc.weight + (item.articleWeightForGivenQuantity || 0),
      packages: acc.packages + (item.numberOfPackages || 0)
    }), { cost: 0, weight: 0, packages: 0 });
  }, [entries]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (row, i) => i + 1,
        className: "text-center",
        width: 50,
      },
      {
        Header: "Artikal",
        accessor: "article.name",
        className: "font-medium",
        width: 250,
      },
      {
        Header: "Cena",
        accessor: "articleWholeSalePrice",
        Cell: ({ value }) => formatNumber(value),
        className: "text-right",
      },
      {
        Header: "Valuta",
        accessor: "customer.paymentCurrency",
        className: "text-center",
      },
      {
        Header: "Jed. Mere",
        accessor: "article.unitOfMeasurement",
        className: "text-center",
      },
      {
        Header: "Količina",
        accessor: "requestedQuantity",
        className: "text-right",
      },
      {
        Header: "Rabat",
        accessor: "articleBrandDiscount",
        Cell: ({ value }) => `${value}%`,
        className: "text-right",
      },
      {
        Header: "Ukupna Cena",
        accessor: "finalPriceForGivenQuantity",
        Cell: ({ value }) => formatNumber(value),
        className: "text-right font-medium",
      },
      {
        Header: "Broj Paketa",
        accessor: "numberOfPackages",
        className: "text-right",
      },
      {
        Header: "Težina (KG)",
        accessor: "articleWeightForGivenQuantity",
        Cell: ({ value }) => formatNumberKG(value),
        className: "text-right",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: entries,
    },
    useSortBy
  );

  const generatePDF = () => {
    const doc = new jsPDF('l', 'mm', 'a4');

    // Set font
    doc.setFont("helvetica");

    // Add header with blue color
    doc.setTextColor(51, 122, 183);
    doc.setFontSize(24);
    doc.text(`Porudžbina ${indentCode}`, 14, 20);

    // Reset text color to black
    doc.setTextColor(0, 0, 0);

    // Add date with smaller font
    doc.setFontSize(10);
    doc.text(`Datum: ${formatDate(new Date())}`, 14, 30);

    // Add customer and delivery information
    const customer = entries[0]?.customer;
    const deliveryAddress = entries[0]?.indent?.deliveryAddress;

    // Layout configuration - more compact and full width
    const margin = 14;
    const cardSpacing = 3;
    const availableWidth = 270; // A4 landscape width minus margins

    // Calculate card widths to use full width
    let cardWidth;
    let qrCardWidth;

    if (customer && deliveryAddress) {
      // If both customer and delivery address exist, divide space equally
      cardWidth = (availableWidth - (2 * cardSpacing)) / 3;
      qrCardWidth = cardWidth;
    } else if (customer || deliveryAddress) {
      // If only one exists, divide space equally with QR
      cardWidth = (availableWidth - cardSpacing) / 2;
      qrCardWidth = cardWidth;
    } else {
      // If neither exists, QR takes full width
      qrCardWidth = availableWidth;
    }

    // Make all cards the same height for better alignment
    const cardHeight = 45; // Match the QR card height
    const qrCardHeight = 45; // Keep QR card height the same

    // Create compact info cards with minimal styling
    const createInfoCard = (title, data, x, y, width, height) => {
      // Simple card background
      doc.setFillColor(252, 253, 255);
      doc.setDrawColor(230, 236, 245);
      doc.roundedRect(x, y, width, height, 2, 2, 'FD');

      // Compact title
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(51, 102, 153);
      doc.text(title, x + 3, y + 6);

      // Thin divider
      doc.setDrawColor(230, 236, 245);
      doc.setLineWidth(0.2);
      doc.line(x + 3, y + 8, x + width - 3, y + 8);

      // Compact content
      doc.setTextColor(60, 60, 60);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);

      // Add data with tighter spacing
      let yOffset = y + 13;
      data.forEach(line => {
        doc.text(line, x + 3, yOffset);
        yOffset += 4;
      });
    };

    // Position tracking
    let currentX = margin;

    // Customer info card
    if (customer) {
      createInfoCard("KUPAC", [
        customer.nameOfTheLegalEntity,
        customer.city,
        customer.address,
        `PIB: ${customer.pib}`,
        `Kontakt: ${customer.contactPerson}`,
        `Tel: ${customer.contactNumber}`
      ], currentX, 35, cardWidth, cardHeight);
      currentX += cardWidth + cardSpacing;
    }

    // Delivery address card
    if (deliveryAddress) {
      createInfoCard("ADRESA ISPORUKE", [
        deliveryAddress.city,
        deliveryAddress.address
      ], currentX, 35, cardWidth, cardHeight);
      currentX += cardWidth + cardSpacing;
    }

    // Add QR code if available - larger and more prominent
    if (qrCodeData) {
      try {
        // QR code container - make it much larger than other cards
        doc.setFillColor(255, 255, 255);
        doc.setDrawColor(230, 236, 245);

        // Position the QR card at the same Y level but with increased dimensions
        doc.roundedRect(currentX, 35, qrCardWidth, qrCardHeight, 2, 2, 'FD');

        // Compact title
        doc.setFontSize(8);
        doc.setFont("helvetica", "bold");
        doc.setTextColor(51, 102, 153);
        doc.text("IPS QR KOD", currentX + 3, 35 + 6);

        if (userRole === "ROLE_ADMIN") {

          // Add the QR code image - maximized size for better visibility
          // Use almost the full height of the card for the QR code
          const qrSize = qrCardHeight - 10; // Keep the same QR code size

          // Center the QR code in the available space
          const qrX = currentX + (qrCardWidth - qrSize) / 2;
          const qrY = 35 + 8; // Position after title

          doc.addImage(
            `data:image/png;base64,${qrCodeData}`,
            'PNG',
            qrX,
            qrY,
            qrSize,
            qrSize
          );
        }

        // Add small caption below QR code
        doc.setFontSize(6);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(100, 100, 100);
      } catch (err) {
        console.error("Error adding QR code to PDF:", err);
      }
    }

    // Adjust starting Y position for summary boxes - directly after customer/delivery info
    const summaryY = 35 + cardHeight + 5; // Add a bit more spacing
    const boxHeight = 15;

    // Function to create compact summary box
    const drawSummaryBox = (x, label, value) => {
      // Simple box
      doc.setFillColor(252, 253, 255);
      doc.setDrawColor(230, 236, 245);
      doc.roundedRect(x, summaryY, 80, boxHeight, 2, 2, 'FD');

      // Compact label
      doc.setFontSize(7);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(51, 102, 153);
      doc.text(label, x + 3, summaryY + 5);

      // Compact value
      doc.setFontSize(9);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(60, 60, 60);
      const cleanValue = value.toString().replace(/<[^>]+>/g, '');
      doc.text(cleanValue, x + 3, summaryY + 11);
    };

    // Draw summary boxes with equal spacing
    const boxWidth = 80;
    const totalBoxes = 3;
    const boxSpacing = (availableWidth - (boxWidth * totalBoxes)) / (totalBoxes - 1);

    drawSummaryBox(margin, "UKUPNA CENA", `${Number(totals.cost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} RSD`);
    drawSummaryBox(margin + boxWidth + boxSpacing, "UKUPNO PAKETA", totals.packages);
    drawSummaryBox(margin + (boxWidth + boxSpacing) * 2, "UKUPNA TEŽINA", `${Number(totals.weight).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} KG`);

    // Adjust table Y position
    let tableY = summaryY + boxHeight + 3;

    // Add comment section if exists - compact version
    if (comment) {
      // Simple comment box
      doc.setFillColor(250, 252, 254);
      doc.setDrawColor(230, 236, 245);
      doc.roundedRect(margin, tableY, availableWidth, 12, 2, 2, 'FD');

      // Compact label and text on same line
      doc.setFontSize(7);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(51, 102, 153);
      doc.text("NAPOMENA:", margin + 3, tableY + 7);

      doc.setFont("helvetica", "normal");
      doc.setTextColor(70, 70, 70);
      doc.text(comment, margin + 25, tableY + 7);

      tableY += 15;
    }

    // Prepare table data with proper formatting
    const tableData = entries.map((entry, index) => [
      index + 1,
      entry.article.name,
      Number(entry.articleWholeSalePrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      entry.customer.paymentCurrency,
      entry.article.unitOfMeasurement,
      entry.requestedQuantity,
      `${entry.articleBrandDiscount}%`,
      Number(entry.finalPriceForGivenQuantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      entry.numberOfPackages,
      Number(entry.articleWeightForGivenQuantity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    ]);

    // Generate table with improved styling and full width
    doc.autoTable({
      startY: tableY,
      head: [[
        '#',
        'Artikal',
        'Cena',
        'Valuta',
        'Jed. Mere',
        'Kolicina',
        'Rabat',
        'Ukupna Cena',
        'Broj Paketa',
        'Težina (KG)'
      ]],
      body: tableData,
      styles: {
        fontSize: 9,
        cellPadding: 3,
        lineColor: [230, 230, 230],
        lineWidth: 0.5,
      },
      headStyles: {
        fillColor: [51, 122, 183],
        textColor: 255,
        fontSize: 9,
        fontStyle: 'bold',
        halign: 'center',
      },
      columnStyles: {
        0: { cellWidth: 10, halign: 'center' },
        1: { cellWidth: 'auto' }, // Auto width for article name to use available space
        2: { cellWidth: 25, halign: 'right' },
        3: { cellWidth: 20, halign: 'center' },
        4: { cellWidth: 20, halign: 'center' },
        5: { cellWidth: 20, halign: 'right' },
        6: { cellWidth: 20, halign: 'right' },
        7: { cellWidth: 30, halign: 'right' },
        8: { cellWidth: 25, halign: 'right' },
        9: { cellWidth: 25, halign: 'right' }
      },
      margin: { left: margin, right: margin }, // Set margins to match the rest of the document
      tableWidth: 'auto', // Use full available width
      alternateRowStyles: {
        fillColor: [252, 252, 252]
      },
      didDrawCell: (data) => {
        // Add subtle border to cells
        doc.setDrawColor(230, 230, 230);
        doc.setLineWidth(0.1);
      }
    });

    // Save the PDF
    doc.save(`Porudzbina-${indentCode}.pdf`);
  };

  if (loading) return <LoadingSpinner />;

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  const customer = entries[0]?.customer;
  const deliveryAddress = entries[0]?.indent?.deliveryAddress;

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6">
      {/* Header Section */}
      <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6 mb-4 sm:mb-6">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center">
          <div className="mb-4 sm:mb-0">
            <h1 className="text-xl sm:text-2xl font-bold text-gray-900 flex items-center mb-2">
              <i className="fas fa-shopping-cart mr-2 sm:mr-3 text-blue-600"></i>
              <span className="truncate">Porudžbina {indentCode}</span>
            </h1>
            <p className="text-xs sm:text-sm text-gray-500">
              Datum: {formatDate(new Date())}
            </p>
            <p className="text-xs text-gray-400 mt-1">
              Virdžinija d.o.o. | PIB: 101957870 | MB: 07939833
            </p>
          </div>
          <div className="flex gap-2 sm:gap-3">
            <button
              onClick={generatePDF}
              className="px-3 sm:px-4 py-2 text-xs sm:text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center flex-1 sm:flex-none justify-center"
            >
              <i className="fas fa-print mr-1 sm:mr-2"></i>
              <span className="hidden sm:inline">Štampaj PDF</span>
            </button>
            <button
              onClick={() => navigate("/indents")}
              className="px-3 sm:px-4 py-2 text-xs sm:text-sm font-medium text-gray-700 bg-white border border-gray-300 
                rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
                focus:ring-blue-500 flex items-center flex-1 sm:flex-none justify-center"
            >
              <i className="fas fa-arrow-left mr-1 sm:mr-2"></i>
              Nazad
            </button>
          </div>
        </div>
      </div>

      {/* Customer and Delivery Information */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 md:gap-6 mb-4 sm:mb-6">
        {customer && (
          <CustomerInfoCard
            title="Podaci o kupcu"
            data={{
              Naziv: customer.nameOfTheLegalEntity,
              Grad: customer.city,
              Adresa: customer.address,
              PIB: customer.pib,
              Kontakt: customer.contactPerson,
              Telefon: customer.contactNumber
            }}
          />
        )}

        {deliveryAddress && (
          <CustomerInfoCard
            title="Adresa isporuke"
            data={{
              Grad: deliveryAddress.city,
              Adresa: deliveryAddress.address
            }}
          />
        )}

        {/* IPS QR Code */}
        {userRole === "ROLE_ADMIN" && (
          <IPSQRCode data={qrCodeData} isLoading={qrLoading} />
        )}
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 md:gap-6 mb-4 sm:mb-6">
        <SummaryCard
          label="Ukupna Cena"
          value={formatNumber(totals.cost)}
        />
        <SummaryCard
          label="Ukupno Paketa"
          value={totals.packages}
        />
        <SummaryCard
          label="Ukupna Težina"
          value={formatNumberKG(totals.weight)}
        />
      </div>

      {/* Comment Section */}
      {comment && (
        <div className="mb-4 sm:mb-6">
          <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-2 sm:mb-3">Napomena</h3>
            <p className="text-gray-600 text-sm">{comment}</p>
          </div>
        </div>
      )}

      {/* Table */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`
                        px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
                        ${column.className || ''}
                        hover:bg-gray-100 cursor-pointer transition-colors duration-200
                      `}
                      style={column.width ? { width: column.width } : {}}
                    >
                      <div className="flex items-center">
                        {column.render('Header')}
                        <span className="ml-2">
                          {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="hover:bg-gray-50 transition-colors duration-150">
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        className={`
                          px-6 py-4 whitespace-nowrap text-sm
                          ${cell.column.className || 'text-gray-900'}
                        `}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default IndentEntries;