export function formatDate(date, includeTime = false) {
  const dateObj = date instanceof Date ? date : new Date(date);
  
  if (includeTime) {
    return new Intl.DateTimeFormat("sr-RS", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(dateObj);
  }
  
  return new Intl.DateTimeFormat("sr-RS", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(dateObj);
}

export function getAllowedRoles() {
  return ["ROLE_ADMIN", "ROLE_USER", "ROLE_MODERATOR", "ROLE_FAKTURISTA", "ROLE_MAGACIONER"];
}

export const phoneNumberRegEx = /^\+\d{3}\d{9}$/;

export function formatNumber(number) {
  return <span>{Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} RSD</span>
}

export function formatNumberKG(number) {
  return <span>{Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} KG</span>
}

export function discountedPrice(price, discount) {
  return (Number(price) * (1 - Number(discount / 100)).toFixed(2));
}

export function priceWithPDV(price, discount) {
  return (Number(price) * (1 + Number(discount / 100)).toFixed(2));
}

export function formatNumberWithoutPostfix(number) {
  return <span>{Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
}

export const removeSpecialCharacters = (text) => {
  if (!text) return text;
  const specialChars = {
    'š': 's',
    'Š': 'S',
    'đ': 'dj',
    'Đ': 'Dj',
    'ž': 'z',
    'Ž': 'Z',
    'č': 'c',
    'Č': 'C',
    'ć': 'c',
    'Ć': 'C',
    '_': ' ',
  };
  return text.replace(/[šŠđĐžŽčČćĆ_]/g, (match) => specialChars[match] || match);
};
