import React, { useContext, useEffect } from "react";
import { ApplicationContext } from "./ApplicationContext";
import { useNavigate } from "react-router-dom";

// Reusable Components
const InfoSection = ({ title, children }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
    <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
    {children}
  </div>
);

const InfoField = ({ label, value }) => (
  <div className="py-3 border-b border-gray-200 last:border-0">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 text-sm text-gray-900">{value || "—"}</dd>
  </div>
);

const Badge = ({ active }) => (
  <span className={`
    inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
    ${active 
      ? 'bg-green-100 text-green-800' 
      : 'bg-red-100 text-red-800'
    }
  `}>
    {active ? 'Aktivan' : 'Neaktivan'}
  </span>
);

const UserInfo = () => {
  const { userRole, loggedInClient } = useContext(ApplicationContext);
  const navigate = useNavigate();

  // Add effect to handle body scroll
  useEffect(() => {
    // Prevent background scroll when modal is open
    document.body.style.overflow = 'hidden';
    
    // Cleanup function to restore scroll when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // Handle backdrop click
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      navigate(-1);
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-[9999] overflow-y-auto"
      onClick={handleBackdropClick}
    >
      <div className="min-h-screen px-4 text-center flex items-center justify-center">
        <div 
          className="inline-block w-full max-w-4xl p-6 my-8 text-left align-middle bg-white shadow-xl rounded-lg relative"
          onClick={e => e.stopPropagation()} // Prevent clicks inside modal from closing it
        >
          <div className="absolute top-4 right-4">
            <button
              onClick={() => navigate(-1)}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
              aria-label="Close"
            >
              <i className="fas fa-times text-gray-500 text-xl"></i>
            </button>
          </div>

          <div className="mb-8">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-semibold text-gray-900">
                Korisnički Profil
              </h1>
              {loggedInClient?.isActive !== undefined && (
                <Badge active={loggedInClient.isActive} />
              )}
            </div>
          </div>

          {!loggedInClient || Object.keys(loggedInClient).length === 0 ? (
            <div className="min-h-[400px] flex items-center justify-center">
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Nema dostupnih podataka o korisniku
                </h3>
                <button
                  onClick={() => navigate('/')}
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <i className="fas fa-arrow-left mr-2"></i>
                  Nazad na početnu
                </button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <InfoSection title="Osnovni Podaci">
                <div className="space-y-3">
                  <InfoField label="Naziv pravnog subjekta" value={loggedInClient.nameOfTheLegalEntity} />
                  <InfoField label="Šifra klijenta" value={loggedInClient.customerCode} />
                  <InfoField label="PIB" value={loggedInClient.pib} />
                  <InfoField label="Matični broj" value={loggedInClient.identificationNumber} />
                  <InfoField label="Valuta plaćanja" value={loggedInClient.paymentCurrency} />
                </div>
              </InfoSection>

              <InfoSection title="Kontakt Informacije">
                <div className="space-y-3">
                  <InfoField label="Kontakt osoba" value={loggedInClient.contactPerson} />
                  <InfoField label="Email" value={loggedInClient.email} />
                  <InfoField label="Telefon" value={loggedInClient.contactNumber} />
                  <InfoField label="Grad" value={loggedInClient.city} />
                  <InfoField label="Adresa" value={loggedInClient.address} />
                </div>
              </InfoSection>

              {loggedInClient.deliveryAddressList.length > 0 && (
                <InfoSection title="Adrese za Isporuku">
                  <div className="space-y-2">
                    {loggedInClient.deliveryAddressList.map((addr, index) => (
                      <div 
                        key={index}
                        className="p-3 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors duration-200"
                      >
                        <div className="font-medium text-gray-900">{addr.name}</div>
                        <div className="text-sm text-gray-500">
                          {addr.city}, {addr.address}
                        </div>
                        <div className="text-sm text-gray-500 mt-1">
                          {addr.contactPerson} • {addr.contactNumber}
                        </div>
                      </div>
                    ))}
                  </div>
                </InfoSection>
              )}

              {loggedInClient.discounts.length > 0 && (
                <InfoSection title="Brendovski Popusti">
                  <div className="grid grid-cols-2 gap-3">
                    {loggedInClient.discounts.map((discount, index) => (
                      <div 
                        key={index}
                        className="p-3 bg-gray-50 rounded-md text-center"
                      >
                        <div className="font-medium text-gray-900">
                          {discount.brand.brandName}
                        </div>
                        <div className="text-lg font-bold text-blue-600">
                          {discount.discount}%
                        </div>
                      </div>
                    ))}
                  </div>
                </InfoSection>
              )}

              {loggedInClient.comment && (
                <InfoSection title="Dodatne Informacije">
                  <div className="prose prose-sm max-w-none">
                    <p className="text-gray-700">{loggedInClient.comment}</p>
                  </div>
                </InfoSection>
              )}

              <InfoSection title="Sistemske Informacije">
                <div className="space-y-3">
                  <InfoField label="Korisnička uloga" value={userRole} />
                  <InfoField label="Status naloga" value={loggedInClient.isActive ? "Aktivan" : "Neaktivan"} />
                </div>
              </InfoSection>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
