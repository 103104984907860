import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTable, useSortBy } from "react-table";
import DeliveryAddressService from "../services/DeliveryAddressService";
import CustomerService from "../services/CustomerService";
import { toastService } from '../services/toastService';

const ActionButton = ({ onClick, icon, label, variant = "primary", disabled = false }) => {
  const baseClasses = "inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200";
  const variants = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500",
    danger: "bg-red-600 hover:bg-red-700 text-white focus:ring-red-500",
  };

  return (
    <button 
      onClick={onClick}
      disabled={disabled}
      className={`
        ${baseClasses} 
        ${variants[variant]}
        ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
      `}
    >
      <i className={`fas ${icon} mr-2`}></i>
      {label}
    </button>
  );
};

const ClientDeliveryAddresses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { clientID } = location.state || {};
  const [customer, setCustomer] = useState(null);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadData = async () => {
    try {
      setLoading(true);
      const [customerResponse, addressesResponse] = await Promise.all([
        CustomerService.get(clientID),
        DeliveryAddressService.findDeliveryAddressesOfSpecificClient(clientID)
      ]);

      setCustomer(customerResponse.data);
      setDeliveryAddresses(Array.isArray(addressesResponse) ? addressesResponse : []);
      setError(null);
    } catch (err) {
      setError("Došlo je do greške prilikom učitavanja podataka");
      toastService.error("Greška pri učitavanju podataka");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [clientID]);

  const deleteDeliveryAddress = async (id) => {
    if (window.confirm("Da li ste sigurni da želite da obrišete ovu poslovnu jedinicu?")) {
      try {
        await DeliveryAddressService.remove(id);
        await loadData(); // Reload data after deletion
        toastService.success('Uspešno obrisana poslovna jedinica!');
      } catch (error) {
        toastService.error('Greška pri brisanju poslovne jedinice!');
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        id: 'row-number',
        Cell: ({ row }) => (
          <span className="text-gray-500 font-medium">
            {row.index + 1}
          </span>
        ),
        width: 50,
      },
      {
        Header: "Naziv Jedinice",
        accessor: "name",
        width: 200,
      },
      {
        Header: "Grad",
        accessor: "city",
        width: 120,
      },
      {
        Header: "Adresa",
        accessor: "address",
        width: 200,
      },
      {
        Header: "Kontakt Info",
        id: 'contact',
        Cell: ({ row: { original } }) => (
          <div>
            <div className="font-medium">{original.contactPerson}</div>
            <div className="text-sm text-gray-500">{original.contactNumber}</div>
            <div className="text-sm text-gray-500">{original.email}</div>
          </div>
        ),
        width: 200,
      },
      {
        Header: "Akcije",
        accessor: "actions",
        Cell: ({ row: { original } }) => (
          <div className="flex items-center space-x-2">
            <ActionButton
              onClick={() => navigate('/editAddressOfSpecifiedClient', {
                state: { 
                  deliveryAddressID: original.delivery_address_id,
                  clientID 
                }
              })}
              icon="fa-edit"
              label="Izmeni"
              variant="primary"
            />
            <ActionButton
              onClick={() => deleteDeliveryAddress(original.delivery_address_id)}
              icon="fa-trash"
              label="Obriši"
              variant="danger"
              disabled={true} // Temporarily disabled as per comment
            />
          </div>
        ),
        width: 200,
      },
    ],
    [clientID, navigate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: deliveryAddresses,
      initialState: {
        sortBy: [{ id: 'row-number', desc: false }]
      }
    },
    useSortBy
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">
            Poslovne Jedinice
          </h1>
          {customer && (
            <p className="mt-1 text-sm text-gray-600">
              Klijent: <span className="font-medium">{customer.nameOfTheLegalEntity}</span>
            </p>
          )}
        </div>
        <div className="flex space-x-3">
          <button
            onClick={() => navigate('/clients')}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <i className="fas fa-arrow-left mr-2"></i>
            Nazad
          </button>
          <button
            onClick={() => navigate('/addDeliveryAddressToSpecifiedClient', { state: { clientID } })}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <i className="fas fa-plus mr-2"></i>
            Dodaj Poslovnu Jedinicu
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`
                        px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
                        ${column.id === 'row-number' ? 'text-center' : ''}
                      `}
                      style={{ width: column.width }}
                    >
                      {column.render('Header')}
                      {column.id !== 'row-number' && column.id !== 'actions' && (
                        <span className="ml-2">
                          {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="hover:bg-gray-50">
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        className={`
                          px-6 py-4 whitespace-nowrap text-sm text-gray-900
                          ${cell.column.id === 'row-number' ? 'text-center' : ''}
                        `}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
              {rows.length === 0 && (
                <tr>
                  <td 
                    colSpan={columns.length} 
                    className="px-6 py-8 text-center text-sm text-gray-500"
                  >
                    Nema poslovnih jedinica za prikaz
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClientDeliveryAddresses;