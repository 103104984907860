import React, { useState, useRef, useEffect } from 'react';
import './AIChat.css';
import ReactMarkdown from 'react-markdown';
import AIService from '../services/AIService';
import VoiceRecorder from './VoiceRecorder';

// Language translations
const translations = {
  english: {
    welcomeMessage: "Hello! I'm your AI assistant. How can I help you today?",
    processingMessage: "I'm looking that up for you...",
    inputPlaceholder: "Type your message here...",
    sendButton: "Send",
    errorStreaming: "I'm sorry, I encountered an error during streaming. Please try again.",
    errorGeneration: "I'm sorry, I encountered an error while generating the response. Please try again.",
    errorProcessing: "I'm sorry, I encountered an error processing your request. Please try again later.",
    voiceRecordingNotSupported: "Voice recording is not supported in your browser."
  },
  serbian: {
    welcomeMessage: "Zdravo! Ja sam vaš AI asistent. Kako vam mogu pomoći danas?",
    processingMessage: "Tražim informacije za vas...",
    inputPlaceholder: "Unesite vašu poruku ovde...",
    sendButton: "Pošalji",
    errorStreaming: "Žao mi je, došlo je do greške tokom strimovanja. Molim vas, pokušajte ponovo.",
    errorGeneration: "Žao mi je, došlo je do greške prilikom generisanja odgovora. Molim vas, pokušajte ponovo.",
    errorProcessing: "Žao mi je, došlo je do greške prilikom obrade vašeg zahteva. Molim vas, pokušajte kasnije.",
    voiceRecordingNotSupported: "Glasovno snimanje nije podržano u vašem pretraživaču."
  }
};

// Demo responses in Serbian
const demoResponsesSerbian = [
  "Tu sam da pomognem! Šta biste želeli da znate o našim proizvodima ili uslugama?",
  "To je odlično pitanje. Naši proizvodi su dizajnirani sa kvalitetom i izdržljivošću na umu.",
  "Nudimo razne proizvode. Evo nekih kategorija:\n\n- Elektronika\n- Odeća\n- Kućni proizvodi\n- Namirnice",
  "Naš tim za korisničku podršku je dostupan od ponedeljka do petka, od 9 do 17 časova. Možete ih kontaktirati na podrska@primer.com.",
  "Hvala na vašem pitanju. Biće mi zadovoljstvo da vam pomognem.",
  "Razumem da tražite više informacija. Možete li mi dati više detalja o tome šta tražite?",
  "Naša politika povraćaja omogućava povraćaj u roku od 30 dana od kupovine uz važeći račun."
];

const TypingIndicator = () => (
  <div className="typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

const Message = ({ message, isAI }) => {
  // Ensure message is a string
  const messageText = typeof message === 'string' ? message : '';
  
  return (
    <div className={`message ${isAI ? 'ai-message' : 'user-message'}`}>
      <div className="message-avatar">
        {isAI ? '🤖' : '👤'}
      </div>
      <div className="message-content">
        {isAI ? (
          <ReactMarkdown>
            {messageText}
          </ReactMarkdown>
        ) : (
          <span>{messageText}</span>
        )}
      </div>
    </div>
  );
};

const AIChat = ({ language = 'english' }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isServiceReady, setIsServiceReady] = useState(false);
  const messagesEndRef = useRef(null);
  
  // Get translations for the selected language
  const t = translations[language] || translations.english;

  // Initialize service and add welcome message
  useEffect(() => {
    console.log("Initializing AIChat component with language:", language);
    try {
      setIsServiceReady(true);
      
      // Add welcome message
      const welcomeMessage = {
        text: t.welcomeMessage,
        isAI: true
      };
      setMessages([welcomeMessage]);
      console.log("Added welcome message:", welcomeMessage);
    } catch (error) {
      console.error("Error initializing AI service:", error);
      setIsServiceReady(false);
    }
  }, [t, language]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
    console.log("Current messages:", messages);
  }, [messages]);

  const handleSendMessage = async (e) => {
    if (e) e.preventDefault();
    if (!inputMessage.trim() || !isServiceReady) return;

    console.log("Sending message:", inputMessage);
    
    // Add user message
    const userMessage = inputMessage.trim();
    setMessages(prevMessages => {
      console.log("Adding user message to messages:", userMessage);
      return [...prevMessages, { text: userMessage, isAI: false }];
    });
    setInputMessage('');

    // Show typing indicator
    setIsTyping(true);
    
    try {
      console.log("Getting conversation history");
      // Get conversation history for context
      const conversationHistory = messages.map(msg => ({ 
        text: msg.text, 
        isAI: msg.isAI 
      }));
      
      console.log("Sending query to AIService:", userMessage);
      console.log("Conversation history:", conversationHistory);
      
      // Get AI response using AIService
      const response = await AIService.processQuery(userMessage, conversationHistory);
      
      console.log("Received response from AIService:", response);
      setIsTyping(false);
      
      // Add the AI response as a new message
      const aiResponseText = typeof response === 'string' ? response : t.errorProcessing;
      setMessages(prevMessages => {
        console.log("Adding AI response to messages:", aiResponseText);
        return [...prevMessages, { text: aiResponseText, isAI: true }];
      });
    } catch (error) {
      console.error("Error getting AI response:", error);
      setIsTyping(false);
      
      // Add error message
      setMessages(prevMessages => [...prevMessages, { text: t.errorProcessing, isAI: true }]);
    }
  };

  // Handle voice transcription
  const handleVoiceTranscription = (transcription) => {
    if (transcription && transcription.trim()) {
      console.log("Received voice transcription:", transcription.trim());
      
      // Set the transcribed text in the input field
      setInputMessage(transcription.trim());
      
      // Add a small delay before sending to allow the user to see what was transcribed
      setTimeout(() => {
        // Only auto-send if the service is ready
        if (isServiceReady) {
          handleSendMessage();
        } else {
          handleDemoSendMessage({ preventDefault: () => {} });
        }
      }, 500);
    } else {
      console.warn("Received empty transcription");
    }
  };

  // Fallback to demo mode if service is not ready
  const handleDemoSendMessage = (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    console.log("Using demo mode for message:", inputMessage);
    
    // Add user message
    const userMessage = inputMessage.trim();
    setMessages(prev => [...prev, { text: userMessage, isAI: false }]);
    setInputMessage('');

    // Show typing indicator
    setIsTyping(true);
    
    // Select appropriate demo responses based on language
    const demoResponses = language === 'serbian' ? demoResponsesSerbian : [
      "I'm here to help! What would you like to know about our products or services?",
      "That's a great question. Our products are designed with quality and durability in mind.",
      "We offer a variety of products. Here are some categories:\n\n- Electronics\n- Clothing\n- Home Goods\n- Groceries",
      "Our customer service team is available Monday through Friday, 9am to 5pm. You can reach them at support@example.com.",
      "Thank you for your question. I'd be happy to help you with that.",
      "I understand you're looking for more information. Could you please provide more details about what you're looking for?",
      "Our return policy allows returns within 30 days of purchase with a valid receipt."
    ];
    
    // Simulate AI response with random demo response
    setTimeout(() => {
      setIsTyping(false);
      const randomResponse = demoResponses[Math.floor(Math.random() * demoResponses.length)];
      setMessages(prev => [...prev, { text: randomResponse, isAI: true }]);
    }, 1500);
  };

  return (
    <div className="ai-chat-container">
      <div className="chat-messages">
        {messages.length > 0 ? (
          messages.map((message, index) => (
            <Message
              key={index}
              message={message.text}
              isAI={message.isAI}
            />
          ))
        ) : (
          <div className="empty-chat-message">
            <p>{t.welcomeMessage}</p>
          </div>
        )}
        {isTyping && (
          <div className="message ai-message">
            <div className="message-avatar">🤖</div>
            <div className="message-content">
              <TypingIndicator />
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      
      <form onSubmit={isServiceReady ? handleSendMessage : handleDemoSendMessage} className="chat-input-container">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder={t.inputPlaceholder}
          className="chat-input"
        />
        <div className="chat-input-actions">
          <VoiceRecorder 
            onTranscription={handleVoiceTranscription} 
            language={language}
            isDisabled={isTyping}
          />
          <button 
            type="submit" 
            className="send-button"
            disabled={!inputMessage.trim()}
          >
            {t.sendButton}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AIChat; 