import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import ClientDataService from '../services/CustomerService';
import BrandService from "../services/BrandService";
import { getAllowedRoles, phoneNumberRegEx } from './utils';
import { toastService } from '../services/toastService';
import '../styles/addClientStyle.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Reusable Components
const FormSection = ({ title, children }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
    <h3 className="text-lg font-medium text-gray-900 mb-4">{title}</h3>
    {children}
  </div>
);

const FormField = ({ label, name, type = "text", formik, children, hint }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
      {hint && <span className="ml-1 text-xs text-gray-500">({hint})</span>}
    </label>
    {children || (
      <input
        type={type}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={`
          w-full px-3 py-2 border rounded-md shadow-sm transition-colors duration-200
          ${formik.touched[name] && formik.errors[name]
            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
            : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
          }
        `}
      />
    )}
    {formik.touched[name] && formik.errors[name] && (
      <p className="mt-1 text-sm text-red-600">{formik.errors[name]}</p>
    )}
  </div>
);

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl mx-4 z-50">
        <div className="flex justify-between items-center px-6 py-4 border-b">
          <h3 className="text-lg font-medium text-gray-900">{title}</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="px-6 py-4">
          {children}
        </div>
      </div>
    </div>
  );
};

const AddClient = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [modalFormBrandDiscountVisible, setModalFormBrandDiscountVisible] = useState(false);
  const [modalFormDeliveryAddressVisible, setModalFormDeliveryAddressVisible] = useState(false);
  const [customerDeliveryAddresses, setCustomerDeliveryAddresses] = useState([]);
  const [brandDiscounts, setBrandDiscounts] = useState({});

  useEffect(() => {
    const loadBrands = async () => {
      try {
        const response = await BrandService.getAll();
        setBrands(response.data);
      } catch (error) {
        toastService.error('Greška pri učitavanju brendova');
      }
    };
    loadBrands();
  }, []);

  const validationSchema = Yup.object().shape({
    nameOfTheLegalEntity: Yup.string().required('Ime legalnog entiteta je obavezno'),
    city: Yup.string().required('Grad je obavezan'),
    address: Yup.string().required('Adresa je obavezna'),
    pib: Yup.string()
      .required('PIB je obavezan')
      .length(9, 'PIB mora imati tačno 9 cifara')
      .matches(/^[0-9]+$/, 'PIB mora sadržati samo brojeve'),
    identificationNumber: Yup.string()
      .required('Matični broj je obavezan')
      .length(8, 'Matični broj mora imati tačno 8 cifara')
      .matches(/^[0-9]+$/, 'Matični broj mora sadržati samo brojeve'),
    contactPerson: Yup.string().required('Kontakt osoba je obavezna'),
    contactNumber: Yup.string()
      .matches(phoneNumberRegEx, 'Format: +381XXXXXXXXX')
      .required('Kontakt telefon je obavezan'),
    email: Yup.string()
      .email('Neispravan format email-a')
      .required('Email je obavezan'),
    paymentCurrency: Yup.string().required('Valuta plaćanja je obavezna'),
    role: Yup.string().required('Uloga je obavezna'),
  });

  const deliveryAddressValidationSchema = Yup.object().shape({
    name: Yup.string().required('Naziv poslovne jedinice je obavezan'),
    city: Yup.string().required('Grad je obavezan'),
    address: Yup.string().required('Adresa je obavezna'),
    contactPerson: Yup.string(),
    contactNumber: Yup.string()
      .matches(/^[0-9]+$/, 'Kontakt broj mora sadržati samo brojeve'),
    email: Yup.string().email('Neispravan format email-a'),
  });

  const brandValidationSchema = Yup.object().shape({
    selectedBrand: Yup.string().required('Morate izabrati brend'),
    brandDiscount: Yup.string()
      .matches(/^[0-9]+$/, 'Rabat mora sadržati samo brojeve')
      .required('Rabat je obavezan'),
  });

  const formik = useFormik({
    initialValues: {
      customerCode: '',
      nameOfTheLegalEntity: '',
      city: '',
      address: '',
      pib: '',
      identificationNumber: '',
      contactPerson: '',
      contactNumber: '',
      email: '',
      paymentCurrency: 'RSD',
      role: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await ClientDataService.createCustomerWithDiscountAndAddresses(
          values,
          values.role,
          brandDiscounts,
          customerDeliveryAddresses
        );
        toastService.success('Klijent uspešno kreiran!');
        navigate('/clients');
      } catch (error) {
        toastService.error('Greška prilikom kreiranja klijenta!');
      } finally {
        setLoading(false);
      }
    },
  });

  const deliveryAddressFormik = useFormik({
    initialValues: {
      name: '',
      city: '',
      address: '',
      contactPerson: '',
      contactNumber: '',
      email: '',
    },
    validationSchema: deliveryAddressValidationSchema,
    onSubmit: (values, { resetForm }) => {
      setCustomerDeliveryAddresses(prev => [...prev, values]);
      setModalFormDeliveryAddressVisible(false);
      resetForm();
      toastService.success('Adresa uspešno dodata!');
    },
  });

  const brandFormik = useFormik({
    initialValues: {
      selectedBrand: '',
      brandDiscount: '',
    },
    validationSchema: brandValidationSchema,
    onSubmit: (values, { resetForm }) => {
      setBrandDiscounts(prev => ({
        ...prev,
        [values.selectedBrand]: values.brandDiscount
      }));
      setModalFormBrandDiscountVisible(false);
      resetForm();
      toastService.success('Rabat uspešno dodat!');
    },
  });

  const handleDeleteAddress = (index) => {
    setCustomerDeliveryAddresses(prev => prev.filter((_, i) => i !== index));
    toastService.success('Adresa uspešno obrisana!');
  };

  const handleDeleteDiscount = (brand) => {
    setBrandDiscounts(prev => {
      const newDiscounts = { ...prev };
      delete newDiscounts[brand];
      return newDiscounts;
    });
    toastService.success('Rabat uspešno obrisan!');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900">
            Dodaj Novog Klijenta
          </h1>
          <button
            onClick={() => navigate('/clients')}
            className="text-gray-600 hover:text-gray-900"
          >
            <i className="fas fa-times text-xl"></i>
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <FormSection title="Osnovni Podaci">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField 
                label="Šifra Klijenta" 
                name="customerCode" 
                formik={formik} 
              />
              <FormField 
                label="Ime Legalnog Entiteta" 
                name="nameOfTheLegalEntity" 
                formik={formik} 
              />
              <FormField label="Uloga" name="role" formik={formik}>
                <select
                  name="role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-3 py-2 border rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Izaberite ulogu</option>
                  {getAllowedRoles().map((role, index) => (
                    <option key={index} value={role}>{role}</option>
                  ))}
                </select>
              </FormField>
            </div>
          </FormSection>

          <FormSection title="Adresa i Kontakt">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField label="Grad" name="city" formik={formik} />
              <FormField label="Adresa" name="address" formik={formik} />
              <FormField label="Kontakt Osoba" name="contactPerson" formik={formik} />
              <FormField 
                label="Kontakt Telefon" 
                name="contactNumber" 
                formik={formik}
                hint="+381XXXXXXXXX"
              />
              <FormField 
                label="Email" 
                name="email" 
                type="email" 
                formik={formik} 
              />
              <FormField 
                label="Valuta Plaćanja" 
                name="paymentCurrency" 
                formik={formik} 
              />
            </div>
          </FormSection>

          <FormSection title="Poreski Podaci">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField 
                label="PIB" 
                name="pib" 
                formik={formik}
                hint="9 cifara"
              />
              <FormField 
                label="Matični Broj" 
                name="identificationNumber" 
                formik={formik}
                hint="8 cifara"
              />
            </div>
          </FormSection>

          <FormSection title="Dodatne Informacije">
            <div className="space-y-6">
              {/* Delivery Addresses */}
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h4 className="text-lg font-medium text-gray-900">Adrese za Dostavu</h4>
                  <button
                    type="button"
                    onClick={() => setModalFormDeliveryAddressVisible(true)}
                    className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700"
                  >
                    <i className="fas fa-plus mr-2"></i>
                    Dodaj Adresu
                  </button>
                </div>
                <div className="space-y-3">
                  {customerDeliveryAddresses.map((address, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-lg flex justify-between items-start">
                      <div>
                        <h4 className="font-medium text-gray-900">{address.name}</h4>
                        <p className="text-sm text-gray-600">{address.city}, {address.address}</p>
                        <p className="text-sm text-gray-600">
                          {address.contactPerson} • {address.contactNumber}
                        </p>
                        {address.email && (
                          <p className="text-sm text-gray-600">{address.email}</p>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => handleDeleteAddress(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  ))}
                  {customerDeliveryAddresses.length === 0 && (
                    <p className="text-sm text-gray-500 italic">
                      Nema dodatih adresa za dostavu
                    </p>
                  )}
                </div>
              </div>

              {/* Brand Discounts */}
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h4 className="text-lg font-medium text-gray-900">Rabati po Brendu</h4>
                  <button
                    type="button"
                    onClick={() => setModalFormBrandDiscountVisible(true)}
                    className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700"
                  >
                    <i className="fas fa-plus mr-2"></i>
                    Dodaj Rabat
                  </button>
                </div>
                <div className="space-y-3">
                  {Object.entries(brandDiscounts).map(([brand, discount], index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-lg flex justify-between items-center">
                      <div>
                        <h4 className="font-medium text-gray-900">{brand}</h4>
                        <p className="text-sm text-gray-600">Rabat: {discount}%</p>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleDeleteDiscount(brand)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  ))}
                  {Object.keys(brandDiscounts).length === 0 && (
                    <p className="text-sm text-gray-500 italic">
                      Nema dodatih rabata
                    </p>
                  )}
                </div>
              </div>
            </div>
          </FormSection>

          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={() => navigate('/clients')}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 
                hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Odustani
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium 
                text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              {loading ? 'Kreiranje...' : 'Kreiraj Klijenta'}
            </button>
          </div>
        </form>

        {/* Delivery Address Modal */}
        <Modal
          isOpen={modalFormDeliveryAddressVisible}
          onClose={() => setModalFormDeliveryAddressVisible(false)}
          title="Dodaj Adresu za Dostavu"
        >
          <form onSubmit={deliveryAddressFormik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField 
                label="Naziv Poslovne Jedinice" 
                name="name" 
                formik={deliveryAddressFormik} 
              />
              <FormField 
                label="Grad" 
                name="city" 
                formik={deliveryAddressFormik} 
              />
              <FormField 
                label="Adresa" 
                name="address" 
                formik={deliveryAddressFormik} 
              />
              <FormField 
                label="Kontakt Osoba" 
                name="contactPerson" 
                formik={deliveryAddressFormik} 
              />
              <FormField 
                label="Kontakt Telefon" 
                name="contactNumber" 
                formik={deliveryAddressFormik} 
              />
              <FormField 
                label="Email" 
                name="email" 
                type="email" 
                formik={deliveryAddressFormik} 
              />
            </div>
            <div className="flex justify-end gap-4 mt-6">
              <button
                type="button"
                onClick={() => setModalFormDeliveryAddressVisible(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700"
              >
                Odustani
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium"
              >
                Dodaj Adresu
              </button>
            </div>
          </form>
        </Modal>

        {/* Brand Discount Modal */}
        <Modal
          isOpen={modalFormBrandDiscountVisible}
          onClose={() => setModalFormBrandDiscountVisible(false)}
          title="Dodaj Rabat za Brend"
        >
          <form onSubmit={brandFormik.handleSubmit}>
            <div className="space-y-4">
              <FormField label="Brend" name="selectedBrand" formik={brandFormik}>
                <select
                  name="selectedBrand"
                  value={brandFormik.values.selectedBrand}
                  onChange={brandFormik.handleChange}
                  onBlur={brandFormik.handleBlur}
                  className="w-full px-3 py-2 border rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Izaberite Brend</option>
                  {brands.map((brand, index) => (
                    <option key={index} value={brand.brandName}>
                      {brand.brandName}
                    </option>
                  ))}
                </select>
              </FormField>
              <FormField 
                label="Rabat (%)" 
                name="brandDiscount" 
                type="number"
                formik={brandFormik} 
              />
            </div>
            <div className="flex justify-end gap-4 mt-6">
              <button
                type="button"
                onClick={() => setModalFormBrandDiscountVisible(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700"
              >
                Odustani
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium"
              >
                Dodaj Rabat
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default AddClient;   