import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ApplicationContext } from "./ApplicationContext";
import { useLogout } from "./useLogout";

// Reusable Components
const NavItem = ({ to, children, isMobile = false }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      to={to}
      className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200
        ${isActive 
          ? isMobile 
            ? 'bg-blue-700 text-white' 
            : 'bg-gray-800 text-white' 
          : isMobile
            ? 'text-gray-200 hover:bg-gray-700 hover:text-white'
            : 'text-gray-300 hover:bg-gray-700 hover:text-white'
        }
        ${isMobile ? 'block w-full text-left mb-1 flex items-center' : ''}
      `}
    >
      {children}
    </NavLink>
  );
};

const UserMenu = ({ isOpen, userName, onLogout }) => (
  <div className={`
    absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
    transform transition-all duration-200 ease-in-out z-50
    ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}
  `}>
    <div className="py-1">
      <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
        <span className="font-medium truncate block max-w-[180px]">{userName}</span>
      </div>
      <NavLink 
        to="/userInfo" 
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        O profilu
      </NavLink>
      <NavLink 
        to="/change-password" 
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Promeni Šifru
      </NavLink>
      <button
        onClick={onLogout}
        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Odjavi se
      </button>
    </div>
  </div>
);

const MobileMenu = ({ isOpen, userRole, userName, onLogout }) => (
  <div className={`
    md:hidden transition-all duration-300 ease-in-out bg-gray-800 rounded-b-lg shadow-lg
    ${isOpen ? 'max-h-screen opacity-100 py-2' : 'max-h-0 opacity-0 overflow-hidden py-0'}
  `}>
    <div className="px-2 pt-1 pb-2 space-y-1 sm:px-3">
      {userRole === "ROLE_ADMIN" && (
        <>
          <NavItem to="/shop" isMobile><i className="fas fa-store mr-2 text-blue-400"></i>Shop</NavItem>
          <NavItem to="/articles" isMobile><i className="fas fa-box mr-2 text-blue-400"></i>Artikli</NavItem>
          <NavItem to="/clients" isMobile><i className="fas fa-users mr-2 text-blue-400"></i>Klijenti</NavItem>
          <NavItem to="/brands" isMobile><i className="fas fa-tag mr-2 text-blue-400"></i>Brendovi</NavItem>
          <NavItem to="/indents" isMobile><i className="fas fa-shopping-cart mr-2 text-blue-400"></i>Porudžbine</NavItem>
          <NavItem to="/arcai" isMobile><i className="fas fa-robot mr-2 text-blue-400"></i>ArcAI</NavItem>
          <NavItem to="/basket" isMobile><i className="fas fa-shopping-basket mr-2 text-blue-400"></i>Korpa</NavItem>
        </>
      )}
      {userRole === "ROLE_USER" && (
        <>
          <NavItem to="/shop" isMobile><i className="fas fa-store mr-2 text-blue-400"></i>Shop</NavItem>
          <NavItem to="/indents" isMobile><i className="fas fa-shopping-cart mr-2 text-blue-400"></i>Porudžbine</NavItem>
          <NavItem to="/arcai" isMobile><i className="fas fa-robot mr-2 text-blue-400"></i>ArcAI</NavItem>
          <NavItem to="/basket" isMobile><i className="fas fa-shopping-basket mr-2 text-blue-400"></i>Korpa</NavItem>
        </>
      )}
      {userRole === "ROLE_FAKTURISTA" && (
        <>
          <NavItem to="/clients" isMobile><i className="fas fa-users mr-2 text-blue-400"></i>Klijenti</NavItem>
          <NavItem to="/indents" isMobile><i className="fas fa-shopping-cart mr-2 text-blue-400"></i>Porudžbine</NavItem>
          <NavItem to="/arcai" isMobile><i className="fas fa-robot mr-2 text-blue-400"></i>ArcAI</NavItem>
        </>
      )}
      {userRole === "ROLE_MAGACIONER" && (
        <>
          <NavItem to="/indents" isMobile><i className="fas fa-shopping-cart mr-2 text-blue-400"></i>Porudžbine</NavItem>
          <NavItem to="/arcai" isMobile><i className="fas fa-robot mr-2 text-blue-400"></i>ArcAI</NavItem>
        </>
      )}
      {userRole === "ROLE_REGISTRATION" && (
        <NavItem to="/userManualRegistration" isMobile><i className="fas fa-user-plus mr-2 text-blue-400"></i>Registracija Na Portal</NavItem>
      )}

      <div className="pt-3 pb-2 border-t border-gray-700 mt-3">
        <div className="px-3 py-2">
          <div className="text-base font-medium text-white truncate">{userName}</div>
        </div>
        <div className="space-y-1 mt-2">
          <NavLink
            to="/userInfo"
            className="block px-3 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded-md flex items-center"
          >
            <i className="fas fa-user mr-2 text-blue-400"></i>
            O profilu
          </NavLink>
          <NavLink
            to="/change-password"
            className="block px-3 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded-md flex items-center"
          >
            <i className="fas fa-key mr-2 text-blue-400"></i>
            Promeni Šifru
          </NavLink>
          <button
            onClick={onLogout}
            className="block w-full text-left px-3 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded-md flex items-center"
          >
            <i className="fas fa-sign-out-alt mr-2 text-blue-400"></i>
            Odjavi se
          </button>
        </div>
      </div>
    </div>
  </div>
);

const NavbarComponent = () => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { userRole, userName } = useContext(ApplicationContext);
  const logout = useLogout();
  const location = useLocation();

  // Close mobile menu when route changes
  useEffect(() => {
    setMobileMenuOpen(false);
    setUserMenuOpen(false);
  }, [location.pathname]);

  // Close menus when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuOpen && !event.target.closest('.user-menu-container')) {
        setUserMenuOpen(false);
      }
      if (mobileMenuOpen && !event.target.closest('.mobile-menu-button') && !event.target.closest('.mobile-menu')) {
        setMobileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [userMenuOpen, mobileMenuOpen]);

  const handleLogout = () => {
    setUserMenuOpen(false);
    setMobileMenuOpen(false);
    logout();
  };

  return (
    <nav className="bg-gray-900 sticky top-0 z-40 shadow-md">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
        <div className="flex items-center justify-between h-14 sm:h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <svg className="h-7 w-7 sm:h-8 sm:w-8 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
              <span className="ml-2 text-lg sm:text-xl font-bold text-white truncate max-w-[100px] sm:max-w-[120px] md:max-w-full">Virdzinija Portal</span>
            </Link>

            <div className="hidden md:block ml-4 lg:ml-8">
              <div className="flex items-center space-x-1 lg:space-x-3">
                {userRole === "ROLE_ADMIN" && (
                  <>
                    <NavItem to="/shop">Shop</NavItem>
                    <NavItem to="/articles">Artikli</NavItem>
                    <NavItem to="/clients">Klijenti</NavItem>
                    <NavItem to="/brands">Brendovi</NavItem>
                    <NavItem to="/indents">Porudžbine</NavItem>
                    <NavItem to="/arcai">ArcAI</NavItem>
                    <NavItem to="/basket">Korpa</NavItem>
                  </>
                )}
                {userRole === "ROLE_USER" && (
                  <>
                    <NavItem to="/shop">Shop</NavItem>
                    <NavItem to="/indents">Porudžbine</NavItem>
                    <NavItem to="/arcai">ArcAI</NavItem>
                    <NavItem to="/basket">Korpa</NavItem>
                  </>
                )}
                {userRole === "ROLE_FAKTURISTA" && (
                  <>
                    <NavItem to="/clients">Klijenti</NavItem>
                    <NavItem to="/indents">Porudžbine</NavItem>
                    <NavItem to="/arcai">ArcAI</NavItem>
                  </>
                )}
                {userRole === "ROLE_MAGACIONER" && (
                  <>
                    <NavItem to="/indents">Porudžbine</NavItem>
                    <NavItem to="/arcai">ArcAI</NavItem>
                  </>
                )}
                {userRole === "ROLE_REGISTRATION" && (
                  <NavItem to="/userManualRegistration">Registracija Na Portal</NavItem>
                )}
              </div>
            </div>
          </div>

          <div className="hidden md:block">
            <div className="relative ml-3 user-menu-container">
              <button
                onClick={() => setUserMenuOpen(!userMenuOpen)}
                className="flex items-center max-w-xs text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span className="sr-only">Open user menu</span>
                <div className="flex items-center">
                  <span className="text-gray-300 mr-2 truncate max-w-[120px]">{userName}</span>
                  <svg 
                    className={`h-5 w-5 text-gray-300 transition-transform duration-200 ${userMenuOpen ? 'transform rotate-180' : ''}`}
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </button>

              <UserMenu
                isOpen={userMenuOpen}
                userName={userName}
                onLogout={handleLogout}
              />
            </div>
          </div>

          <div className="md:hidden">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="mobile-menu-button inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded={mobileMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              {mobileMenuOpen ? (
                <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="mobile-menu">
        <MobileMenu
          isOpen={mobileMenuOpen}
          userRole={userRole}
          userName={userName}
          onLogout={handleLogout}
        />
      </div>
    </nav>
  );
};

export default NavbarComponent;
