import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import BrandDataService from "../services/BrandService";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toastService } from '../services/toastService';
import BrandsPDF from './BrandsPDF';

// Reusable Components
const SearchBar = ({ value, onChange, onSearch }) => (
  <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
    <div className="flex space-x-2">
      <input
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder="Pretražite po nazivu brenda..."
        className="flex-1 px-4 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
      />
      <button
        onClick={onSearch}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <i className="fas fa-search mr-2"></i>
        Pretraži
      </button>
    </div>
  </div>
);

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4">
        <div className="flex justify-between items-center px-6 py-4 border-b">
          <h3 className="text-lg font-medium text-gray-900">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="px-6 py-4">
          {children}
        </div>
      </div>
    </div>
  );
};

const BrandsList = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [editingBrand, setEditingBrand] = useState(null);
  const navigate = useNavigate();

  const loadBrands = async () => {
    try {
      setLoading(true);
      const response = await BrandDataService.getAll();
      setBrands(response.data);
      setError(null);
    } catch (err) {
      setError("Došlo je do greške prilikom učitavanja brendova");
      toastService.error("Greška pri učitavanju podataka");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBrands();
  }, []);

  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await BrandDataService.findByName(searchName);
      setBrands(response.data);
    } catch (err) {
      toastService.error("Greška pri pretraživanju");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      brandName: editingBrand?.brandName || '',
    },
    validationSchema: Yup.object().shape({
      brandName: Yup.string()
        .required('Naziv brenda je obavezan')
        .min(2, 'Naziv mora imati najmanje 2 karaktera'),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        if (editingBrand) {
          await BrandDataService.update(editingBrand.brand_id, values);
          toastService.success('Brend uspešno izmenjen!');
        }
        await loadBrands();
        setModalOpen(false);
        resetForm();
        setEditingBrand(null);
      } catch (err) {
        toastService.error('Greška pri izmeni brenda!');
      } finally {
        setLoading(false);
      }
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "#",
        id: 'row-number',
        Cell: ({ row }) => (
          <span className="text-gray-500 font-medium">
            {row.index + 1}
          </span>
        ),
        width: 50,
      },
      {
        Header: "Naziv Brenda",
        accessor: "brandName",
        width: 200,
      },
      {
        Header: "Akcije",
        id: 'actions',
        Cell: ({ row: { original } }) => (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => {
                setEditingBrand(original);
                setModalOpen(true);
              }}
              className="text-blue-600 hover:text-blue-800"
            >
              <i className="far fa-edit"></i>
            </button>
          </div>
        ),
        width: 100,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    { 
      columns, 
      data: brands
    },
    useSortBy
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <i className="fas fa-exclamation-circle text-red-400"></i>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">
          Brendovi
        </h1>
        <div className="flex space-x-3">
          <BrandsPDF brands={brands} />
          <button
            onClick={() => navigate('/brands/add')}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <i className="fas fa-plus mr-2"></i>
            Dodaj Brend
          </button>
        </div>
      </div>

      <SearchBar
        value={searchName}
        onChange={setSearchName}
        onSearch={handleSearch}
      />

      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`
                        px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
                        ${column.id === 'row-number' ? 'text-center' : ''}
                      `}
                      style={{ width: column.width }}
                    >
                      {column.render('Header')}
                      {column.id !== 'row-number' && column.id !== 'actions' && (
                        <span className="ml-2">
                          {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="hover:bg-gray-50">
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        className={`
                          px-6 py-4 whitespace-nowrap text-sm text-gray-900
                          ${cell.column.id === 'row-number' ? 'text-center' : ''}
                        `}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setEditingBrand(null);
          formik.resetForm();
        }}
        title="Izmena Brenda"
      >
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Naziv Brenda
            </label>
            <input
              type="text"
              name="brandName"
              value={formik.values.brandName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`
                mt-1 block w-full rounded-md shadow-sm
                ${formik.touched.brandName && formik.errors.brandName
                  ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                }
              `}
            />
            {formik.touched.brandName && formik.errors.brandName && (
              <p className="mt-1 text-sm text-red-600">{formik.errors.brandName}</p>
            )}
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={() => {
                setModalOpen(false);
                setEditingBrand(null);
                formik.resetForm();
              }}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Odustani
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {loading ? 'Čuvanje...' : 'Sačuvaj'}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default BrandsList;
