import React, { useContext, useState, useEffect } from "react";
import "../styles/Basket.css";
import { ApplicationContext } from "./ApplicationContext";
import IndentEntryService from "../services/IndentEntryService";
import { toastService } from '../services/toastService';
import { formatNumber, formatNumberKG, formatNumberWithoutPostfix, priceWithPDV, discountedPrice } from './utils';

// Reusable Components
const QuantityControl = ({ value, onDecrease, onIncrease, onChange, minimumQuantity }) => (
  <div className="flex items-center space-x-2">
    <button
      onClick={onDecrease}
      className="p-2 border rounded-md hover:bg-gray-100 transition-colors duration-200"
      disabled={value <= 0}
    >
      <i className="fas fa-minus text-gray-600"></i>
    </button>
    <input
      type="number"
      value={value}
      onChange={onChange}
      min={minimumQuantity}
      className="w-20 px-3 py-2 border rounded-md text-center focus:ring-blue-500 focus:border-blue-500"
    />
    <button
      onClick={onIncrease}
      className="p-2 border rounded-md hover:bg-gray-100 transition-colors duration-200"
    >
      <i className="fas fa-plus text-gray-600"></i>
    </button>
  </div>
);

const SummaryCard = ({ label, value }) => (
  <div className="bg-white p-4 rounded-lg shadow-sm">
    <div className="text-sm text-gray-600">{label}</div>
    <div className="text-lg font-semibold text-gray-900 mt-1">{value}</div>
  </div>
);

const WarningMessage = ({ children }) => (
  <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <i className="fas fa-exclamation-triangle text-yellow-400"></i>
      </div>
      <div className="ml-3">
        <p className="text-sm text-yellow-700">{children}</p>
      </div>
    </div>
  </div>
);

const Basket = () => {
    const { setBasketItems, basketItems, removeAllBasketItems, loggedInClient, removeBasketItem } = useContext(ApplicationContext);
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [comment, setComment] = useState("");
    const [missingItemQuantities, setMissingItemQuantities] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loggedInClient && loggedInClient.deliveryAddressList && loggedInClient.deliveryAddressList.length === 1) {
            setDeliveryAddress(loggedInClient.deliveryAddressList[0]);
        }
        const newMissingItemQuantities = validateItemQuantities(basketItems);
        setMissingItemQuantities(newMissingItemQuantities);
    }, [basketItems, loggedInClient]);

    const validateItemQuantities = (basketItems) => {
        let missingQuantities = {};
        let noccoItemsQuantity = 0;

        for (let item of basketItems) {
            if (item.article.brand.brandName === "NOCCO") {
                noccoItemsQuantity += item.quantity;
            }
            const remainder = item.quantity % item.article.minimumQuantityDemand;
            if (remainder !== 0) {
                missingQuantities[item.article.name] = {
                    article: item.article,
                    missingQuantity: item.article.minimumQuantityDemand - remainder
                };
            }
        }

        if (noccoItemsQuantity % 24 !== 0) {
            const noccoArticle = basketItems.find(item => item.article.brand.brandName === "NOCCO").article;
            missingQuantities['NOCCO'] = {
                article: noccoArticle,
                missingQuantity: 24 - (noccoItemsQuantity % 24)
            };
        }

        return missingQuantities;
    };

    const confirmOrder = async () => {
        if (!basketItems.length || !deliveryAddress) return;

        try {
            setLoading(true);
            const itemsToCreate = basketItems
                .filter((item) => item.quantity > 0)
                .map((item) => ({
                    customerId: loggedInClient.customer_id,
                    articleName: item.article.name,
                    requestedQuantity: item.quantity,
                    deliveryAddress,
                }));

            await IndentEntryService.createIndentEntries(itemsToCreate, comment);
            removeAllBasketItems();
            setComment("");
            toastService.success('Uspešno kreirana porudžbina!');
        } catch (error) {
            console.error(error);
            toastService.error('Greška pri kreiranju porudžbine!');
        } finally {
            setLoading(false);
        }
    };

    const brandDiscount = (brand) => {
        const brandName = brand.brandName;
        const discountForTheBrand = loggedInClient?.discounts?.find((item) => item.brand.brandName === brandName);
        if (discountForTheBrand) {
            return discountForTheBrand.discount;
        } else {
            return 0;
        }
    };

    const articlePriceWithDiscount = (article) => {
        return (Number(article.retailPrice) * (1 - Number(brandDiscount(article.brand)) / 100)).toFixed(2);
    }

    function handleDeliveryAddressChange(event) {
        const selectedValue = event.target.value;
        if (selectedValue) {
            const parsedDeliveryAddress = JSON.parse(selectedValue);
            console.log(parsedDeliveryAddress);
            setDeliveryAddress(parsedDeliveryAddress);
            console.log(deliveryAddress);
        } else {
            setDeliveryAddress("");
        }
    }

    const totalCost = basketItems
        ? basketItems.reduce((acc, item) => acc + articlePriceWithDiscount(item.article) * item.quantity, 0)
        : 0;

    const totalWeight = basketItems
        ? basketItems.reduce((acc, item) => {
            const brutoMass = Number(item.article.brutoMass);
            const requestedQuantity = Number(item.quantity);
            if (isNaN(brutoMass) || isNaN(requestedQuantity)) {
                return acc;
            }
            return acc + (brutoMass * requestedQuantity);
        }, 0)
        : 0;

    const totalNumberOfPackages = basketItems
        ? basketItems.reduce((acc, item, index) => {
            const quantityPerTransportPackage = item.article.quantityPerTransportPackage;
            const requestedQuantity = parseFloat(item.quantity);
            if (isNaN(quantityPerTransportPackage) || isNaN(requestedQuantity)) {
                return acc;
            }
            const newAcc = acc + requestedQuantity / quantityPerTransportPackage;
            return newAcc;
        }, 0)
        : 0;

    const handleQuantityChange = (article, newQuantity) => {
        const quantityPerTransportPackage = article.quantityPerTransportPackage;
        const parsedValue = parseInt(newQuantity) || 0;
        const newValue = Math.max(Math.max(parsedValue / quantityPerTransportPackage) * quantityPerTransportPackage, 0);

        const updatedBasketItems = basketItems.map((item) => {
            if (item.article.code === article.code) {
                return { ...item, quantity: newValue };
            }
            return item;
        });
        setBasketItems(updatedBasketItems);
    };

    const getPDV = (basketItems) => {
        if (basketItems && basketItems.length > 0) {
            return basketItems[0].article.pdv;
        }
        return 0;
    };

    const pdv = getPDV(basketItems);

    const allItemsHaveZeroQuantity = basketItems.every((item) => item.quantity === 0);
    const hasValidationErrors = Object.keys(missingItemQuantities).length > 0;

    if (loading) {
        return (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold text-gray-900">
                    <i className="fas fa-shopping-cart text-blue-500 mr-2"></i>
                    Korpa
                </h1>
            </div>

            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                <SummaryCard 
                    label="Ukupna Cena" 
                    value={formatNumber(totalCost)} 
                />
                <SummaryCard 
                    label="Ukupno Paketa" 
                    value={Number(Math.ceil(totalNumberOfPackages * 100) / 100).toFixed(2)} 
                />
                <SummaryCard 
                    label="Ukupna Težina" 
                    value={`${Number(totalWeight).toFixed(2)} KG`} 
                />
            </div>

            {/* Main Content */}
            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        {/* Table Header */}
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="hide-on-mobile">Redni Broj</th>
                                <th>Šifra Artikla</th>
                                <th>Artikal</th>
                                <th>Količina</th>
                                <th className="hide-on-mobile">Fakturna Cena</th>
                                <th className="hide-on-mobile">Rabat</th>
                                <th className="hide-on-mobile">Porez</th>
                                <th className="hide-on-mobile">Cena Sa Porezom</th>
                                <th className="hide-on-mobile">Broj Paketa</th>
                                <th className="hide-on-mobile">Težina</th>
                                <th>Iznos</th>
                                <th>Akcije</th>
                            </tr>
                        </thead>
                        {/* Table Body */}
                        <tbody className="bg-white divide-y divide-gray-200">
                            {basketItems.map((item, index) => (
                                <tr key={item.article.id} className="hover:bg-gray-50">
                                    <td className="hide-on-mobile">{index + 1}</td>
                                    <td>{item.article.code}</td>
                                    <td>{item.article.name}</td>
                                    <td>
                                        <QuantityControl
                                            value={item.quantity}
                                            onDecrease={() => handleQuantityChange(item.article, item.quantity - item.article.minimumQuantityDemand)}
                                            onIncrease={() => handleQuantityChange(item.article, item.quantity + item.article.minimumQuantityDemand)}
                                            onChange={(e) => handleQuantityChange(item.article, e.target.value)}
                                            minimumQuantity={item.article.quantityPerTransportPackage}
                                        />
                                    </td>
                                    <td className="hide-on-mobile">{formatNumber(item.article.wholesalePrice)}</td>
                                    <td className="hide-on-mobile">{brandDiscount(item.article.brand)} %</td>
                                    <td className="hide-on-mobile">{pdv} %</td>
                                    <td className="hide-on-mobile">{formatNumber(priceWithPDV(discountedPrice(item.article.wholesalePrice, brandDiscount(item.article.brand)), pdv))}</td>
                                    <td className="hide-on-mobile">{item.quantity / item.article.quantityPerTransportPackage}</td>
                                    <td className="hide-on-mobile">{formatNumberKG(item.article.brutoMass * item.quantity)}</td>
                                    <td>{formatNumber(priceWithPDV(discountedPrice(item.article.wholesalePrice, brandDiscount(item.article.brand)), pdv) * item.quantity)}</td>
                                    <td>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => removeBasketItem(item.article)}
                                        >
                                            Ukloni
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Order Form */}
            <div className="mt-6 space-y-4">
                <textarea
                    placeholder="Napomena..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    rows={4}
                />

                <select
                    value={JSON.stringify(deliveryAddress)}
                    onChange={(e) => setDeliveryAddress(JSON.parse(e.target.value || '""'))}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                >
                    <option value="">Izaberite Adresu Dostave</option>
                    {loggedInClient && loggedInClient.deliveryAddressList && loggedInClient.deliveryAddressList.map((deliveryAddress, index) => {
                        return (
                            <option key={index + 1} value={JSON.stringify(deliveryAddress)}>
                                {deliveryAddress.city}, {deliveryAddress.address}
                            </option>
                        );
                    })}
                </select>

                {/* Validation Warnings */}
                {!deliveryAddress && basketItems.length > 0 && !hasValidationErrors && (
                    <WarningMessage>
                        Morate izabrati <strong>adresu dostave</strong> kako bi aktivirali narudžbinu!
                    </WarningMessage>
                )}

                {hasValidationErrors && (
                    <WarningMessage>
                        {Object.entries(missingItemQuantities).map(([name, { article, missingQuantity }]) => (
                            <p key={name}>
                                {name === 'NOCCO'
                                    ? `Za brend ${name}, broj poručenih komada mora biti deljiv sa 24. Možete dodati još ${missingQuantity} komada.`
                                    : `Za artikal ${name}, broj poručenih komada mora biti deljiv sa ${article.quantityPerTransportPackage}. Možete dodati još ${missingQuantity} komada.`
                                }
                            </p>
                        ))}
                    </WarningMessage>
                )}

                {/* Submit Button */}
                <button
                    onClick={confirmOrder}
                    disabled={!deliveryAddress || basketItems.length === 0 || allItemsHaveZeroQuantity || hasValidationErrors}
                    className={`
                        w-full px-4 py-2 rounded-lg font-medium text-white
                        ${(!deliveryAddress || basketItems.length === 0 || allItemsHaveZeroQuantity || hasValidationErrors)
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                        }
                    `}
                >
                    {loading ? 'Kreiranje porudžbine...' : 'Potvrdi porudžbinu'}
                </button>
            </div>
        </div>
    );
};

export default Basket;
