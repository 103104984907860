import React from 'react';

const variants = {
  default: "bg-white",
  inactive: "bg-red-50 opacity-75"
};

const Card = ({ children, variant = "default", className = "" }) => {
  return (
    <div className={`
      rounded-lg shadow-md border border-gray-200
      transition-all duration-200 hover:shadow-lg
      ${variants[variant]}
      ${className}
    `}>
      {children}
    </div>
  );
};

export default Card; 