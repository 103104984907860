import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsOfService = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Uslovi Korišćenja</h1>
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
            >
              <i className="fas fa-arrow-left mr-2"></i>
              Nazad
            </button>
          </div>

          <div className="prose max-w-none">
            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Opšte odredbe</h2>
              <p className="text-gray-600 mb-4">
                Korišćenjem B2B portala Virdzinija ("Portal") prihvatate ove uslove korišćenja. Portal je namenjen 
                isključivo pravnim licima i preduzetnicima za potrebe veleprodajne kupovine.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Registracija i nalog</h2>
              <p className="text-gray-600 mb-4">
                Za korišćenje Portala neophodno je kreiranje korisničkog naloga. Korisnik je odgovoran za:
              </p>
              <ul className="list-disc pl-6 text-gray-600 mb-4">
                <li>Tačnost dostavljenih podataka</li>
                <li>Čuvanje pristupnih podataka</li>
                <li>Sve aktivnosti koje se obavljaju preko naloga</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Poručivanje i isporuka</h2>
              <p className="text-gray-600 mb-4">
                Porudžbine se realizuju prema definisanim uslovima isporuke. Virdzinija zadržava pravo da:
              </p>
              <ul className="list-disc pl-6 text-gray-600 mb-4">
                <li>Odbije porudžbinu ukoliko nisu ispunjeni uslovi</li>
                <li>Izmeni cene uz prethodno obaveštenje</li>
                <li>Ograniči količine za poručivanje</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Plaćanje</h2>
              <p className="text-gray-600 mb-4">
                Plaćanje se vrši prema dogovorenim uslovima. Cene su izražene u RSD i podložne su promeni. 
                Rabati i uslovi plaćanja definišu se posebnim ugovorom.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Intelektualna svojina</h2>
              <p className="text-gray-600 mb-4">
                Sav sadržaj na Portalu je vlasništvo kompanije Virdzinija i zaštićen je autorskim pravima. 
                Nije dozvoljeno kopiranje ili distribucija sadržaja bez saglasnosti.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Izmene uslova korišćenja</h2>
              <p className="text-gray-600 mb-4">
                Virdzinija zadržava pravo izmene ovih uslova korišćenja. Korisnici će biti obavešteni o 
                izmenama putem Portala ili email-a.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService; 