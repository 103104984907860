import axios from 'axios';

class VoiceService {
  constructor() {
    // Get the OpenAI provider configuration from the same place AIService uses
    this.openaiConfig = {
      apiEndpoint: process.env.REACT_APP_OPENAI_API_ENDPOINT || 'https://api.openai.com/v1',
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      model: 'whisper-1'
    };
    
    // Ensure the endpoint doesn't have trailing slashes and doesn't include /chat/completions
    this.openaiConfig.apiEndpoint = this.openaiConfig.apiEndpoint.replace(/\/+$/, '');
    if (this.openaiConfig.apiEndpoint.includes('/chat/completions')) {
      this.openaiConfig.apiEndpoint = this.openaiConfig.apiEndpoint.replace('/chat/completions', '');
    }
    
    console.log('VoiceService initialized with API endpoint:', this.openaiConfig.apiEndpoint);
    
    // Check environment variables
    this.checkEnvironmentVariables();
    
    // Map of language names to ISO codes for Whisper
    this.languageMap = {
      'english': 'en',
      'serbian': 'sr',
      'bosnian': 'bs',
      'croatian': 'hr',
      'german': 'de',
      'french': 'fr',
      'spanish': 'es',
      'italian': 'it',
      'portuguese': 'pt',
      'russian': 'ru',
      'chinese': 'zh',
      'japanese': 'ja',
      'korean': 'ko',
      'arabic': 'ar',
      'hindi': 'hi',
      'turkish': 'tr'
    };
  }
  
  /**
   * Check environment variables and log warnings if they're not set
   */
  checkEnvironmentVariables() {
    // Check for OpenAI API key
    if (!this.openaiConfig.apiKey) {
      console.warn('REACT_APP_OPENAI_API_KEY is not set. Voice transcription will not work.');
    }
    
    // Check for OpenAI API endpoint
    if (!process.env.REACT_APP_OPENAI_API_ENDPOINT) {
      console.log('REACT_APP_OPENAI_API_ENDPOINT is not set. Using default: https://api.openai.com/v1');
    } else {
      console.log('REACT_APP_OPENAI_API_ENDPOINT is set to:', process.env.REACT_APP_OPENAI_API_ENDPOINT);
    }
  }

  /**
   * Transcribe audio using OpenAI's Whisper model
   * @param {Blob} audioBlob - The audio blob to transcribe
   * @param {string} language - Optional language hint (e.g., 'serbian', 'english')
   * @returns {Promise<string>} - The transcribed text
   */
  async transcribeAudio(audioBlob, language = null) {
    try {
      if (!this.openaiConfig.apiKey) {
        console.error('OpenAI API key is not configured');
        throw new Error('OpenAI API key is not configured');
      }

      // Create a FormData object to send the audio file
      const formData = new FormData();
      
      // Ensure the file has a proper extension based on its MIME type
      const fileExtension = this.getFileExtension(audioBlob.type);
      formData.append('file', audioBlob, `recording.${fileExtension}`);
      formData.append('model', this.openaiConfig.model);
      
      // Add language hint if provided, using the language map
      if (language) {
        const languageCode = this.languageMap[language.toLowerCase()] || language;
        console.log(`Using language code: ${languageCode} for language: ${language}`);
        formData.append('language', languageCode);
      }
      
      // Set response format to text
      formData.append('response_format', 'text');

      // Use the direct OpenAI API endpoint as a fallback if there's an issue
      // This ensures we're using the correct endpoint structure
      const transcriptionEndpoint = this.openaiConfig.apiEndpoint === 'https://api.openai.com/v1' 
        ? 'https://api.openai.com/v1/audio/transcriptions'
        : `${this.openaiConfig.apiEndpoint}/audio/transcriptions`;
        
      console.log('Sending transcription request to OpenAI API:', transcriptionEndpoint);
      
      // Log request details for debugging
      console.log('Request details:', {
        endpoint: transcriptionEndpoint,
        model: this.openaiConfig.model,
        language: language ? (this.languageMap[language.toLowerCase()] || language) : 'auto',
        fileType: audioBlob.type,
        fileSize: audioBlob.size,
        fileExtension
      });
      
      // Make the API request using the same authorization approach as AIService
      const response = await axios.post(
        transcriptionEndpoint, 
        formData,
        {
          headers: {
            'Authorization': `Bearer ${this.openaiConfig.apiKey}`,
            'Content-Type': 'multipart/form-data',
          }
        }
      );

      console.log('Received transcription response');
      return response.data;
    } catch (error) {
      console.error('Error transcribing audio:', error);
      
      // Provide more detailed error information
      if (error.response) {
        console.error('API error response:', error.response.data);
        console.error('API error status:', error.response.status);
        console.error('API error headers:', error.response.headers);
        throw new Error(`Transcription failed: ${error.response.data.error?.message || 'API error'}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('Transcription failed: No response received from server');
      } else {
        console.error('Error message:', error.message);
        throw new Error(`Transcription failed: ${error.message}`);
      }
    }
  }
  
  /**
   * Get file extension based on MIME type
   * @param {string} mimeType - The MIME type
   * @returns {string} - The file extension
   */
  getFileExtension(mimeType) {
    const mimeToExt = {
      'audio/webm': 'webm',
      'audio/ogg': 'ogg',
      'audio/mp4': 'm4a',
      'audio/mpeg': 'mp3',
      'audio/wav': 'wav',
      'audio/x-wav': 'wav'
    };
    
    // Extract the base MIME type without codecs
    const baseMimeType = mimeType.split(';')[0];
    
    return mimeToExt[baseMimeType] || 'webm'; // Default to webm
  }

  /**
   * Check if the browser supports audio recording
   * @returns {Promise<boolean>} - Whether audio recording is supported
   */
  async isAudioRecordingSupported() {
    try {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    } catch (error) {
      console.error('Error checking audio recording support:', error);
      return false;
    }
  }
}

// Create a singleton instance
const voiceService = new VoiceService();
export default voiceService; 