import React, { useState, useEffect } from 'react';
import AIChat from './AIChat';
import AIService from '../services/AIService';

const ArcAI = () => {
  const [providers, setProviders] = useState([]);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("Initializing ArcAI component");
    try {
      // Get available providers
      const availableProviders = AIService.getAvailableProviders();
      console.log("Available providers:", availableProviders);
      setProviders(availableProviders);
      
      // Get current provider
      const provider = AIService.getCurrentProvider();
      console.log("Current provider:", provider);
      setCurrentProvider(provider);
      setIsLoading(false);
    } catch (error) {
      console.error("Error initializing ArcAI:", error);
      setIsLoading(false);
    }
  }, []);

  const handleProviderChange = (providerId) => {
    console.log("Changing provider to:", providerId);
    AIService.setProvider(providerId);
    const provider = AIService.getCurrentProvider();
    setCurrentProvider(provider);
  };

  return (
    <div className="container mx-auto p-4 bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen">
      <div className="mb-6 flex flex-col md:flex-row md:justify-between md:items-center bg-white p-6 rounded-lg shadow-sm">
        <div className="mb-4 md:mb-0">
          <h1 className="text-3xl font-bold mb-2 text-indigo-700">ArcAI Asistent</h1>
          <p className="text-gray-600">
            Postavite pitanja o našim proizvodima, uslugama ili zatražite pomoć sa vašim nalogom.
          </p>
          <div className="mt-2 flex items-center">
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mr-2">
              <svg className="mr-1 h-3 w-3" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 16c2.206 0 4-1.794 4-4V6c0-2.217-1.785-4.021-3.979-4.021a.933.933 0 0 0-.209.025A4.006 4.006 0 0 0 8 6v6c0 2.206 1.794 4 4 4z" />
                <path d="M11 19.931V22h2v-2.069c3.939-.495 7-3.858 7-7.931h-2c0 3.309-2.691 6-6 6s-6-2.691-6-6H4c0 4.072 3.061 7.436 7 7.931z" />
              </svg>
              Glasovna podrška
            </span>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              <svg className="mr-1 h-3 w-3" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20.3 12.04l1.01 3a1 1 0 0 1-1.26 1.27l-3.01-1a7 7 0 1 1 3.27-3.27zM11 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              Višejezična podrška
            </span>
          </div>
        </div>
        
        <div className="model-selector bg-gray-50 p-3 rounded-lg border border-gray-200">
          {isLoading ? (
            <div className="text-gray-500 flex items-center">
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Učitavanje modela...
            </div>
          ) : (
            <>
              <label htmlFor="model-select" className="block text-sm font-medium text-gray-700 mb-1">
                Izaberite AI model:
              </label>
              <select 
                id="model-select"
                className="bg-white border border-gray-300 text-gray-700 rounded-md px-4 py-2 text-sm w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                value={currentProvider ? currentProvider.id : ''}
                onChange={(e) => handleProviderChange(e.target.value)}
              >
                {providers.map(provider => (
                  <option key={provider.id} value={provider.id}>
                    {provider.name}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>
      
      <div className="bg-white rounded-xl shadow-lg overflow-hidden" style={{ height: "75vh" }}>
        <div className="bg-indigo-600 text-white px-6 py-3 flex justify-between items-center">
          <h2 className="text-lg font-medium">Chat sa asistentom</h2>
          <div className="flex items-center text-xs">
            <span className="flex items-center mr-3">
              <svg className="h-4 w-4 mr-1" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 16c2.206 0 4-1.794 4-4V6c0-2.217-1.785-4.021-3.979-4.021a.933.933 0 0 0-.209.025A4.006 4.006 0 0 0 8 6v6c0 2.206 1.794 4 4 4z" />
                <path d="M11 19.931V22h2v-2.069c3.939-.495 7-3.858 7-7.931h-2c0 3.309-2.691 6-6 6s-6-2.691-6-6H4c0 4.072 3.061 7.436 7 7.931z" />
              </svg>
              Kliknite na mikrofon za glasovni unos
            </span>
          </div>
        </div>
        <AIChat language="serbian" />
      </div>
      
      <div className="mt-4 text-center text-sm text-gray-500 bg-white p-3 rounded-lg shadow-sm">
        <span className="flex items-center justify-center">
          <span className="mr-2">Pokreće napredni AI modeli</span>
          <span className="inline-block w-1.5 h-1.5 bg-gray-300 rounded-full mx-1"></span>
          <span className="font-medium text-indigo-600">{currentProvider ? currentProvider.name : 'Učitavanje...'}</span>
        </span>
      </div>
    </div>
  );
};

export default ArcAI; 