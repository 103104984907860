import React, { useState, useEffect, useRef } from 'react';
import voiceService from '../services/VoiceService';

const VoiceRecorder = ({ onTranscription, language, isDisabled = false }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSupported, setIsSupported] = useState(true);
  const [recordingTime, setRecordingTime] = useState(0);
  const [error, setError] = useState(null);
  
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  
  // Check if browser supports audio recording
  useEffect(() => {
    const checkSupport = async () => {
      const supported = await voiceService.isAudioRecordingSupported();
      setIsSupported(supported);
      if (!supported) {
        setError('Your browser does not support audio recording');
      }
    };
    
    checkSupport();
    
    // Clean up timer on unmount
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      stopRecording();
    };
  }, []);
  
  // Start recording audio
  const startRecording = async () => {
    try {
      setError(null);
      audioChunksRef.current = [];
      
      // Request microphone access
      console.log('Requesting microphone access...');
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        } 
      });
      
      // Create media recorder with supported MIME type
      const mimeType = getSupportedMimeType();
      console.log(`Using MIME type: ${mimeType}`);
      
      const mediaRecorder = new MediaRecorder(stream, { mimeType });
      mediaRecorderRef.current = mediaRecorder;
      
      // Set up event handlers
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
          console.log(`Received audio chunk: ${event.data.size} bytes`);
        }
      };
      
      mediaRecorder.onstop = async () => {
        console.log('MediaRecorder stopped, processing audio...');
        // Process the recorded audio
        await processAudio();
        
        // Stop all tracks in the stream
        stream.getTracks().forEach(track => track.stop());
      };
      
      mediaRecorder.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        setError(`Recording error: ${event.error.message || 'Unknown error'}`);
        stopRecording();
      };
      
      // Start recording with 1 second chunks to ensure data is captured
      mediaRecorder.start(1000);
      console.log('MediaRecorder started');
      setIsRecording(true);
      
      // Start timer
      setRecordingTime(0);
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
      
    } catch (error) {
      console.error('Error starting recording:', error);
      
      // Provide more specific error messages
      if (error.name === 'NotAllowedError') {
        setError('Microphone access denied. Please allow microphone access to use voice input.');
      } else if (error.name === 'NotFoundError') {
        setError('No microphone found. Please connect a microphone and try again.');
      } else {
        setError(`Could not start recording: ${error.message}`);
      }
    }
  };
  
  // Get supported MIME type for audio recording
  const getSupportedMimeType = () => {
    const types = [
      'audio/webm',
      'audio/webm;codecs=opus',
      'audio/ogg;codecs=opus',
      'audio/mp4',
      'audio/mpeg',
      'audio/wav'
    ];
    
    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    
    // Fallback to default
    return '';
  };
  
  // Stop recording audio
  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      console.log('Stopping MediaRecorder...');
      mediaRecorderRef.current.stop();
    }
    
    // Clear timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    
    setIsRecording(false);
  };
  
  // Process the recorded audio
  const processAudio = async () => {
    if (audioChunksRef.current.length === 0) {
      console.warn('No audio chunks recorded');
      setError('No audio recorded. Please try again.');
      return;
    }
    
    try {
      setIsProcessing(true);
      
      // Get the MIME type from the recorder
      const mimeType = mediaRecorderRef.current?.mimeType || 'audio/webm';
      
      // Create a blob from the audio chunks
      const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
      console.log(`Created audio blob: ${audioBlob.size} bytes, type: ${mimeType}`);
      
      if (audioBlob.size < 100) {
        setError('Recording too short. Please try again and speak clearly.');
        setIsProcessing(false);
        return;
      }
      
      // Transcribe the audio
      console.log('Sending audio for transcription...');
      const transcription = await voiceService.transcribeAudio(audioBlob, language);
      
      console.log('Received transcription:', transcription);
      
      // Call the callback with the transcription
      if (onTranscription && transcription) {
        onTranscription(transcription);
      } else if (!transcription || transcription.trim() === '') {
        setError('Could not transcribe audio. Please try again and speak clearly.');
      }
      
    } catch (error) {
      console.error('Error processing audio:', error);
      
      // Provide more specific error messages based on the error
      if (error.message.includes('API key')) {
        setError('OpenAI API key is not configured or is invalid. Please contact the administrator.');
      } else if (error.message.includes('Invalid URL')) {
        setError('API configuration error. Please contact the administrator.');
      } else if (error.message.includes('invalid_api_key')) {
        setError('Invalid API key. Please contact the administrator to update the API key.');
      } else if (error.message.includes('Incorrect API key provided')) {
        setError('Incorrect API key provided. Please contact the administrator to update the API key.');
      } else {
        setError(`Transcription failed: ${error.message}`);
      }
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Format recording time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  
  // Handle click on the microphone button
  const handleMicrophoneClick = () => {
    if (isDisabled || !isSupported) return;
    
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };
  
  return (
    <div className="voice-recorder">
      {error && (
        <div className="text-red-500 text-xs mb-1 absolute bottom-full left-0 right-0 text-center whitespace-normal" style={{ width: '200px', marginBottom: '5px' }}>
          {error}
        </div>
      )}
      
      <button
        type="button"
        onClick={handleMicrophoneClick}
        disabled={isDisabled || !isSupported || isProcessing}
        className={`
          flex items-center justify-center p-2 rounded-full
          ${isDisabled || !isSupported ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
          ${isRecording ? 'bg-red-500 text-white recording' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}
          transition-colors duration-200
        `}
        title={isSupported ? (isRecording ? 'Stop recording' : 'Start recording') : 'Recording not supported'}
      >
        {isProcessing ? (
          <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        ) : isRecording ? (
          <>
            <span className="sr-only">Stop recording</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <rect x="6" y="6" width="12" height="12" strokeWidth="2" />
            </svg>
            <span className="ml-1 text-xs">{formatTime(recordingTime)}</span>
          </>
        ) : (
          <>
            <span className="sr-only">Start recording</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
            </svg>
          </>
        )}
      </button>
    </div>
  );
};

export default VoiceRecorder; 