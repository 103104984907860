import React from 'react';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Politika Privatnosti</h1>
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
            >
              <i className="fas fa-arrow-left mr-2"></i>
              Nazad
            </button>
          </div>

          <div className="prose max-w-none">
            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Uvod</h2>
              <p className="text-gray-600 mb-4">
                Virdzinija se obavezuje da će štititi vašu privatnost i lične podatke u skladu sa 
                Zakonom o zaštiti podataka o ličnosti Republike Srbije i drugim važećim propisima.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Prikupljanje podataka</h2>
              <p className="text-gray-600 mb-4">
                Prikupljamo sledeće podatke:
              </p>
              <ul className="list-disc pl-6 text-gray-600 mb-4">
                <li>Osnovne podatke o kompaniji</li>
                <li>Kontakt podatke ovlašćenih lica</li>
                <li>Podatke o porudžbinama i isporukama</li>
                <li>Podatke o načinu korišćenja Portala</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Svrha obrade podataka</h2>
              <p className="text-gray-600 mb-4">
                Podaci se obrađuju u svrhu:
              </p>
              <ul className="list-disc pl-6 text-gray-600 mb-4">
                <li>Realizacije porudžbina i isporuka</li>
                <li>Komunikacije sa korisnicima</li>
                <li>Unapređenja usluga</li>
                <li>Ispunjenja zakonskih obaveza</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Zaštita podataka</h2>
              <p className="text-gray-600 mb-4">
                Primenjujemo tehničke i organizacione mere za zaštitu vaših podataka. Pristup podacima 
                imaju samo ovlašćena lica koja su obavezana na čuvanje poverljivosti.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Prava korisnika</h2>
              <p className="text-gray-600 mb-4">
                Imate pravo da:
              </p>
              <ul className="list-disc pl-6 text-gray-600 mb-4">
                <li>Zatražite uvid u svoje podatke</li>
                <li>Zatražite ispravku podataka</li>
                <li>Zatražite brisanje podataka</li>
                <li>Povučete saglasnost za obradu</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Kontakt</h2>
              <p className="text-gray-600 mb-4">
                Za sva pitanja u vezi sa zaštitom podataka možete nas kontaktirati na:
                <br />
                Email: office@virdzinija.rs
                <br />
                Telefon: +381 63 1071864
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy; 