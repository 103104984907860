import React from 'react';

const variants = {
  primary: "bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800",
  secondary: "bg-gray-200 text-gray-700 hover:bg-gray-300 active:bg-gray-400",
  success: "bg-green-600 text-white hover:bg-green-700 active:bg-green-800"
};

const Button = ({ 
  children, 
  variant = "primary", 
  className = "", 
  disabled = false,
  ...props 
}) => {
  return (
    <button
      className={`
        px-4 py-2 rounded-md transition-colors duration-200
        disabled:bg-gray-400 disabled:cursor-not-allowed
        ${variants[variant]}
        ${className}
      `}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button; 