import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import ArticleService from "../services/ArticleService";
import BrandService from "../services/BrandService";
import { toastService } from '../services/toastService';

// Reusable Components
const FormSection = ({ title, children }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
    <h3 className="text-lg font-medium text-gray-900 mb-4">{title}</h3>
    {children}
  </div>
);

const FormField = ({ label, name, type = "text", formik, children, hint }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
      {hint && <span className="ml-1 text-xs text-gray-500">({hint})</span>}
    </label>
    {children || (
      <input
        type={type}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={`
          w-full px-3 py-2 border rounded-md shadow-sm transition-colors duration-200
          ${formik.touched[name] && formik.errors[name]
            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
            : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
          }
        `}
      />
    )}
    {formik.touched[name] && formik.errors[name] && (
      <p className="mt-1 text-sm text-red-600">{formik.errors[name]}</p>
    )}
  </div>
);

const ToggleButton = ({ label, name, value, onChange, options }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
    <div className="flex gap-2">
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          onClick={() => onChange(name, option.value)}
          className={`
            flex-1 px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200
            ${value === option.value
              ? 'bg-blue-600 text-white'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }
          `}
        >
          {option.label}
        </button>
      ))}
    </div>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex justify-center items-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
  </div>
);

const EditArticle = () => {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [existingImage, setExistingImage] = useState(null);
  const [imageCropOption, setImageCropOption] = useState("withCropping");
  const { id: articleID } = useParams();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Šifra Artikla je obavezna."),
    barcode: Yup.string().required("Barkod Artikla je obavezan."),
    name: Yup.string().required("Naziv Artikla je obavezan."),
    unitOfMeasurement: Yup.string(),
    quantityPerTransportPackage: Yup.number()
      .integer("Mora biti ceo broj")
      .min(1, "Mora biti pozitivan broj"),
    minimumQuantityDemand: Yup.number()
      .integer("Mora biti ceo broj")
      .min(0, "Ne može biti negativan broj"),
    brutoMass: Yup.number()
      .positive("Mora biti pozitivan broj")
      .required("Bruto masa je obavezna"),
    wholesalePrice: Yup.number()
      .required("Veleprodajna cena je obavezna.")
      .positive("Mora biti pozitivan broj"),
    pdv: Yup.number().required("PDV je obavezan"),
    isActive: Yup.string().required("Obavezno biranje statusa artikla."),
    isVisible: Yup.string().required("Obavezno biranje vidljivosti artikla."),
    brandName: Yup.string().required("Naziv brenda je obavezan."),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      barcode: "",
      name: "",
      unitOfMeasurement: "",
      quantityPerTransportPackage: "",
      minimumQuantityDemand: "",
      brutoMass: "",
      wholesalePrice: "",
      imageSource: "",
      pdv: "20",
      isActive: "true",
      isVisible: "true",
      brandName: "",
      articleImage: null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const formData = new FormData();
        
        // Add all non-image fields
        Object.keys(values).forEach(key => {
          if (key !== 'articleImage') {
            formData.append(key, String(values[key]));
          }
        });
        
        // Add image only if it exists
        if (values.articleImage) {
          formData.append('articleImage', values.articleImage);
          formData.append('cropImage', imageCropOption === 'withCropping');
        }
        
        await ArticleService.update(articleID, formData);
        toastService.success("Uspešno ažuriran artikal!");
        navigate('/articles');
      } catch (error) {
        toastService.error("Greška prilikom ažuriranja artikla!");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [articleResponse, brandsResponse] = await Promise.all([
          ArticleService.get(articleID),
          BrandService.getAll()
        ]);

        const article = articleResponse.data;
        setBrands(brandsResponse.data);

        formik.setValues({
          code: article.code || "",
          barcode: article.barcode || "",
          name: article.name || "",
          unitOfMeasurement: article.unitOfMeasurement || "",
          quantityPerTransportPackage: article.quantityPerTransportPackage || "",
          minimumQuantityDemand: article.minimumQuantityDemand || "",
          brutoMass: article.brutoMass || "",
          wholesalePrice: article.wholesalePrice || "",
          imageSource: article.imageSource || "",
          pdv: article.pdv || "20",
          isActive: article.isActive ? "true" : "false",
          isVisible: article.isVisible ? "true" : "false",
          brandName: article.brandName || "",
          articleImage: null,
        });

        // Load existing image
        if (article.code) {
          const imageName = `${article.code}.png`;
          const existingImageData = localStorage.getItem(imageName);
          setExistingImage(existingImageData);
        }
      } catch (error) {
        toastService.error("Greška prilikom učitavanja podataka!");
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [articleID]);

  if (loading) return <LoadingSpinner />;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900">
            Izmena Artikla
          </h1>
          <button
            onClick={() => navigate('/articles')}
            className="text-gray-600 hover:text-gray-900"
          >
            <i className="fas fa-times text-xl"></i>
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <FormSection title="Osnovni Podaci">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField label="Šifra Artikla" name="code" formik={formik} />
              <FormField label="Barkod Artikla" name="barcode" formik={formik} />
              <FormField label="Naziv Artikla" name="name" formik={formik} className="md:col-span-2" />
            </div>
          </FormSection>

          <FormSection title="Detalji Proizvoda">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField label="Jedinica mere" name="unitOfMeasurement" formik={formik} />
              <FormField 
                label="Količina po transportnom paketu" 
                name="quantityPerTransportPackage" 
                type="number"
                formik={formik} 
              />
              <FormField 
                label="Minimalna količina" 
                name="minimumQuantityDemand" 
                type="number"
                formik={formik} 
              />
              <FormField 
                label="Bruto masa (KG)" 
                name="brutoMass" 
                type="number"
                formik={formik} 
              />
            </div>
          </FormSection>

          <FormSection title="Cene i Brend">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField 
                label="Veleprodajna cena" 
                name="wholesalePrice" 
                type="number"
                formik={formik} 
                hint="RSD"
              />
              <FormField label="PDV" name="pdv" type="number" formik={formik} hint="%" />
              <FormField label="Brend" name="brandName" formik={formik}>
                <select
                  name="brandName"
                  value={formik.values.brandName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-3 py-2 border rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Izaberite Brend</option>
                  {brands.map((brand) => (
                    <option key={brand.id} value={brand.brandName}>
                      {brand.brandName}
                    </option>
                  ))}
                </select>
              </FormField>
            </div>
          </FormSection>

          <FormSection title="Status i Vidljivost">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <ToggleButton
                label="Status artikla"
                name="isActive"
                value={formik.values.isActive}
                onChange={formik.setFieldValue}
                options={[
                  { value: "true", label: "Aktivan" },
                  { value: "false", label: "Neaktivan" }
                ]}
              />
              <ToggleButton
                label="Vidljivost artikla"
                name="isVisible"
                value={formik.values.isVisible}
                onChange={formik.setFieldValue}
                options={[
                  { value: "true", label: "Vidljiv" },
                  { value: "false", label: "Nevidljiv" }
                ]}
              />
            </div>
          </FormSection>

          <FormSection title="Slika Artikla">
            <div className="space-y-4">
              <ToggleButton
                label="Opcije kropovanja"
                name="cropOption"
                value={imageCropOption}
                onChange={(_, value) => setImageCropOption(value)}
                options={[
                  { value: "withCropping", label: "Sa kropovanjem" },
                  { value: "withoutCropping", label: "Bez kropovanja" }
                ]}
              />

              <div className="mt-4">
                <input
                  type="file"
                  onChange={(e) => formik.setFieldValue("articleImage", e.currentTarget.files[0])}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                    file:rounded-md file:border-0 file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100
                    cursor-pointer"
                />
              </div>

              {existingImage && (
                <div className="mt-4">
                  <p className="text-sm font-medium text-gray-700 mb-2">Trenutna slika:</p>
                  <div className="relative w-32 h-32 rounded-lg overflow-hidden bg-gray-100">
                    <img
                      src={existingImage}
                      alt="Postojeća slika"
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
              )}
            </div>
          </FormSection>

          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={() => navigate('/articles')}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 
                hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Odustani
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium 
                text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              {loading ? 'Ažuriranje...' : 'Sačuvaj Izmene'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditArticle;
