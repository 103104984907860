import { useState, useContext } from "react";
import { ApplicationContext } from "../ApplicationContext";
import { useNavigate } from "react-router-dom";
import axiosInstance, { setAuthToken } from "../apiService";

export const useUserLogin = (initialUsername = "", initialPassword = "", initialError = "") => {
  const [username, setUsername] = useState(initialUsername);
  const [password, setPassword] = useState(initialPassword);
  const [error, setError] = useState(initialError);
  const {
    setLoggedInClient,
    setAuthenticated,
    setUserRole,
    setUserName,
    setEmail,
  } = useContext(ApplicationContext);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      setError("Korisničko ime i šifra su obavezni.");
      return;
    }

    setUserName(username);

    try {
      const response = await axiosInstance.post("auth/signin", {
        username,
        password,
      });

      if (response.data.accessToken) {
        const { accessToken, customer, roles } = response.data;
        localStorage.setItem("user", JSON.stringify(response.data));
        setAuthToken(accessToken);
        setUserRole(roles[0]);
        setAuthenticated(true);

        if (roles[0] === "ROLE_FAKTURISTA" || roles[0] === "ROLE_MAGACIONER") {
          navigate("/");
        } else if (roles[0] === "ROLE_REGISTRATION") {
          setEmail(password);
          navigate("/userManualRegistration");
        } else {
          setLoggedInClient(customer);
          navigate("/");
        }
      } else {
        setError("Netačni kredencijali");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data);
      } else {
        setError("Greška prilikom prijavljivanja");
      }
    }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    error,
    handleLogin,
  };
};
