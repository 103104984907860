import React from 'react';
import { useNavigate } from 'react-router-dom';

// Reusable Components
const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow duration-200">
    <div className="text-blue-500 mb-4">
      <i className={`fas ${icon} text-3xl`}></i>
    </div>
    <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const ContactButton = ({ icon, text, href, className }) => (
  <a
    href={href}
    className={`
      inline-flex items-center px-4 py-2 rounded-md text-sm font-medium
      transition-colors duration-200 ${className}
    `}
  >
    <i className={`fas ${icon} mr-2`}></i>
    {text}
  </a>
);

const HomePage = () => {
  const navigate = useNavigate();

  const features = [
    {
      icon: "fa-shopping-cart",
      title: "Jednostavno Naručivanje",
      description: "Brzo i lako naručite proizvode u velikim količinama kroz našu B2B platformu."
    },
    {
      icon: "fa-truck",
      title: "Brza Dostava",
      description: "Pouzdana i efikasna dostava vaših porudžbina na željenu adresu."
    },
    {
      icon: "fa-chart-line",
      title: "Praćenje Porudžbina",
      description: "Pratite status vaših porudžbina u realnom vremenu."
    }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-600 to-blue-800 text-white py-20">
        <div className="absolute inset-0 bg-black opacity-10"></div>
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 leading-tight">
              Dobrodošli na Virdzinija B2B Portal
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-blue-100">
              Vaše jedinstveno rešenje za narudžbine artikala u velikim količinama
            </p>
            <div className="flex flex-wrap gap-4">
              <button
                onClick={() => navigate('/shop')}
                className="bg-white text-blue-600 px-6 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors duration-200"
              >
                <i className="fas fa-shopping-cart mr-2"></i>
                Pregledajte Proizvode
              </button>
              <ContactButton
                href="tel:+381631071864"
                icon="fa-phone"
                text="Pozovite nas: 063-1071864"
                className="bg-blue-700 text-white hover:bg-blue-800"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Zašto Izabrati Nas?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Potrebna Vam Je Pomoć?
            </h2>
            <p className="text-lg text-gray-600 mb-8">
              Naš tim je tu da vam pomogne. Kontaktirajte Marinu za sva pitanja i podršku.
            </p>
            <ContactButton
              href="tel:+381631071864"
              icon="fa-phone"
              text="Pozovite: 063-1071864"
              className="bg-blue-600 text-white hover:bg-blue-700"
            />
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8 mt-auto">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-center md:text-left mb-4 md:mb-0">
              © 2024 Virdzinija B2B Portal
            </div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <div className="flex gap-4">
                <a href="/terms" className="text-gray-300 hover:text-white transition-colors duration-200">
                  Uslovi Korišćenja
                </a>
                <a href="/privacy" className="text-gray-300 hover:text-white transition-colors duration-200">
                  Privatnost
                </a>
              </div>
              <div className="text-sm text-gray-400">
                Developed by <a 
                  href="https://www.virtualarcsoftware.com/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                >
                  Virtual Arc Software
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
