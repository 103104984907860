import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import IndentDataService from "../services/IndentService";
import { useTable, useSortBy, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import { ApplicationContext } from "./ApplicationContext";
import { formatDate } from "./utils";
import { ThreeDots } from 'react-loader-spinner';
import "../styles/IndentList.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
const SearchFilter = ({ 
  searchCustomerName, setSearchCustomerName,
  searchCode, setSearchCode,
  searchDateFrom, setSearchDateFrom,
  searchDateTo, setSearchDateTo,
  onSearch, onReset,
  userRole,
  onShowActive,
  onShowInactive,
  onShowAll
}) => (
  <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {userRole !== "ROLE_USER" && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Klijent
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="Ime Klijenta"
            value={searchCustomerName}
            onChange={(e) => setSearchCustomerName(e.target.value)}
          />
        </div>
      )}
      
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          ID porudžbine
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          placeholder="ID porudžbenice"
          value={searchCode}
          onChange={(e) => setSearchCode(e.target.value)}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Od datuma
        </label>
        <input
          type="date"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          value={searchDateFrom}
          onChange={(e) => setSearchDateFrom(e.target.value)}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Do datuma
        </label>
        <input
          type="date"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          value={searchDateTo}
          onChange={(e) => setSearchDateTo(e.target.value)}
        />
      </div>
    </div>

    <div className="mt-4 flex flex-wrap gap-2">
      <button
        onClick={onSearch}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Pretraga
      </button>
      <button
        onClick={onReset}
        className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      >
        Reset
      </button>
      
      {userRole !== "ROLE_USER" && (
        <div className="flex flex-wrap gap-2">
          <button
            onClick={onShowActive}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Aktivne Porudžbine
          </button>
          <button
            onClick={onShowInactive}
            className="px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
          >
            Neaktivne Porudžbine
          </button>
          <button
            onClick={onShowAll}
            className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            Sve Porudžbine
          </button>
        </div>
      )}
    </div>
  </div>
);

const LoadingSpinner = () => (
  <div className="flex justify-center items-center min-h-[200px]">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
  </div>
);

const ActionButtons = ({ status, userRole, onDelete, onView, onActivate, onConfirmDelivery }) => (
  <div className="flex flex-wrap gap-2">
    {status === "PENDING" && (
      <button
        onClick={onDelete}
        className="px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-1"
      >
        <i className="fas fa-trash mr-1"></i> Izbriši
      </button>
    )}
    
    <button
      onClick={onView}
      className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
    >
      <i className="far fa-eye mr-1"></i> Pregled
    </button>

    {status === "PENDING" && (userRole === "ROLE_FAKTURISTA" || userRole === "ROLE_ADMIN") && (
      <button
        onClick={onActivate}
        className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1"
      >
        <i className="fas fa-check mr-1"></i> Aktiviraj
      </button>
    )}

    {status === "ACTIVATED" && (userRole === "ROLE_MAGACIONER" || userRole === "ROLE_ADMIN" || userRole === "ROLE_FAKTURISTA") && (
      <button
        onClick={onConfirmDelivery}
        className="px-3 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-1"
      >
        <i className="fas fa-check mr-1"></i> Potvrdi Isporuku
      </button>
    )}
  </div>
);

const IndentsList = (props) => {
    const [indents, setIndents] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { userRole } = useContext(ApplicationContext);
    const [searchCode, setSearchCode] = useState("");
    const [searchDateFrom, setSearchDateFrom] = useState("");
    const [searchDateTo, setSearchDateTo] = useState("");
    const [displayedIndents, setDisplayedIndents] = useState([]);
    const [searchCustomerName, setSearchCustomerName] = useState("");
    const [pageSize, setPageSize] = useState(10);

    // Memoize the columns configuration
    const columns = useMemo(() => [
        {
            Header: "Šifra Klijenta",
            accessor: "customerCode",
            className: "font-medium",
        },
        {
            Header: "Klijent",
            accessor: "customerNameOfTheLegalEntity",
            className: "font-medium",
        },
        {
            Header: "Adresa Dostave",
            accessor: row => `${row.deliveryAddressCity}, ${row.deliveryAddressLocation}`,
        },
        {
            Header: "ID Porudžbenice",
            accessor: "code",
            className: "font-medium",
        },
        {
            Header: "Status",
            accessor: "indentStatus",
            Cell: ({ value }) => (
                <span className={`
                    px-2 py-1 rounded-full text-xs font-medium
                    ${value === 'PENDING' ? 'bg-yellow-100 text-yellow-800' : ''}
                    ${value === 'ACTIVATED' ? 'bg-green-100 text-green-800' : ''}
                    ${value === 'CANCELED' ? 'bg-red-100 text-red-800' : ''}
                    ${value === 'DELIVERED' ? 'bg-blue-100 text-blue-800' : ''}
                `}>
                    {value}
                </span>
            ),
        },
        {
            Header: "Datum",
            accessor: "creationTime",
            Cell: ({ value }) => (
                <span className="text-gray-600">
                    {formatDate(value, true)}
                </span>
            ),
            sortType: (rowA, rowB) => {
                const a = new Date(rowA.values.creationTime);
                const b = new Date(rowB.values.creationTime);
                return a.getTime() - b.getTime();
            }
        },
        {
            Header: "Akcije",
            accessor: "actions",
            Cell: ({ row }) => (
                <ActionButtons
                    status={row.original.indentStatus}
                    userRole={userRole}
                    onDelete={() => deleteIndent(row.original)}
                    onView={() => viewIndent(row.original)}
                    onActivate={() => activateIndent(row.original)}
                    onConfirmDelivery={() => confirmDelivery(row.original)}
                />
            ),
        },
    ], [userRole]); // Only depend on userRole

    // Move DateInputWithPlaceholder component outside of IndentsList
    const tableInstance = useTable(
        {
            columns,
            data: displayedIndents,
            initialState: {
                pageSize,
                sortBy: [{ id: "creationTime", desc: true }],
            },
        },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize: setTablePageSize,
        state: { pageIndex },
    } = tableInstance;

    useEffect(() => {
        retrieveIndents();
    }, []);

    const retrieveIndents = () => {
        setLoading(true);
        IndentDataService.getAll()
            .then((response) => {
                const indentsData = response.data;
                setIndents(indentsData);
                setDisplayedIndents(indentsData);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    const handleResetClick = () => {
        setSearchCustomerName("");
        setSearchCode("");
        setSearchDateFrom("");
        setSearchDateTo("");
        setDisplayedIndents(indents);
    };

    const deleteIndent = (indent) => {
        if (!indent) return;
        
        IndentDataService.remove(indent.code)
            .then((response) => {
                retrieveIndents();
                toast.success('Uspešno obrisana porudžbina! [' + indent.code + ']');
            })
            .catch((e) => {
                toast.error(e);
            });
    };

    const activateIndent = (indent) => {
        if (!indent) return;
        
        IndentDataService.activateIndent(indent.code)
            .then(() => {
                retrieveIndents();
                toast.success('Uspešno aktivirana porudžbina! [' + indent.code + ']');
            })
            .catch((e) => {
                toast.error(e);
            });
    };

    const confirmDelivery = (indent) => {
        if (!indent) return;
        
        IndentDataService.confirmIndentDelivery(indent.code)
            .then(() => {
                retrieveIndents();
                toast.success('Uspešno isporučena porudžbina!! [' + indent.code + ']');
            })
            .catch((e) => {
                toast.error(e);
            });
    };

    const viewIndent = (indent) => {
        if (!indent) return;
        navigate(`/indents/entries/${indent.code}`);
    };

    const handleSearchClick = () => {
        const filteredIndents = indents.filter(indent => {
            const indentDate = new Date(indent.creationTime);

            const isCodeMatch = !searchCode || indent.code.includes(searchCode);
            const isDateFromMatch = !searchDateFrom || indentDate >= new Date(searchDateFrom);
            const isDateToMatch = !searchDateTo || indentDate <= new Date(searchDateTo);
            const isCustomerNameMatch = !searchCustomerName || indent.customerNameOfTheLegalEntity.toLowerCase().includes(searchCustomerName.toLowerCase());

            return isCodeMatch && isDateFromMatch && isDateToMatch && isCustomerNameMatch;
        });

        setDisplayedIndents(filteredIndents);
    };

    const handleShowActiveIndents = () => {
        setLoading(true);
        IndentDataService.getAllActiveIndents()
            .then((response) => {
                setIndents(response.data);
                setDisplayedIndents(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    const handleShowInactiveIndents = () => {
        setLoading(true);
        IndentDataService.getAllInactiveIndents()
            .then((response) => {
                setIndents(response.data);
                setDisplayedIndents(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    const handleShowAllIndents = () => {
        setLoading(true);
        IndentDataService.getAllIndents()
            .then((response) => {
                setIndents(response.data);
                setDisplayedIndents(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    const getRowBgColorClass = (status) => {
        switch (status) {
            case 'PENDING':
                return 'bg-orange-100';  // light orange
            case 'ACTIVATED':
                return 'bg-green-100';  // light green
            case 'CANCELED':
                return 'bg-red-100';  // light red
            case 'DELIVERED':
                return 'bg-blue-100';  // light blue
            default:
                return '';  // default (no background color)
        }
    };

    return (
        <div className="container mx-auto px-4 py-6">
            <SearchFilter
                searchCustomerName={searchCustomerName}
                setSearchCustomerName={setSearchCustomerName}
                searchCode={searchCode}
                setSearchCode={setSearchCode}
                searchDateFrom={searchDateFrom}
                setSearchDateFrom={setSearchDateFrom}
                searchDateTo={searchDateTo}
                setSearchDateTo={setSearchDateTo}
                onSearch={handleSearchClick}
                onReset={handleResetClick}
                userRole={userRole}
                onShowActive={handleShowActiveIndents}
                onShowInactive={handleShowInactiveIndents}
                onShowAll={handleShowAllIndents}
            />

            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <div className="overflow-x-auto">
                        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                style={{ width: column.width }}
                                            >
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className={`
                                                hover:bg-gray-50 transition-colors duration-150
                                                ${getRowBgColorClass(row.original.indentStatus)}
                                            `}
                                        >
                                            {row.cells.map(cell => (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200">
                <div className="flex-1 flex justify-between sm:hidden">
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                        Prethodna
                    </button>
                    <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                        Sledeća
                    </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <p className="text-sm text-gray-700">
                            Prikazano{' '}
                            <span className="font-medium">{pageIndex * pageSize + 1}</span>
                            {' '}-{' '}
                            <span className="font-medium">
                                {Math.min((pageIndex + 1) * pageSize, displayedIndents.length)}
                            </span>
                            {' '}od{' '}
                            <span className="font-medium">{displayedIndents.length}</span>
                            {' '}rezultata
                        </p>
                    </div>
                    <div className="flex gap-x-2">
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                                setTablePageSize(Number(e.target.value));
                            }}
                            className="form-select rounded-md border-gray-300 text-base"
                        >
                            {[10, 20, 30, 40, 50].map(size => (
                                <option key={size} value={size}>
                                    Prikaži {size}
                                </option>
                            ))}
                        </select>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                            <button
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <i className="fas fa-chevron-left"></i>
                            </button>
                            <button
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Move DateInputWithPlaceholder outside the main component
const DateInputWithPlaceholder = ({ placeholder, value, onChange }) => {
    const [isFocused, setIsFocused] = useState(false);

    return isFocused ? (
        <input
            type="date"
            className="form-control"
            value={value}
            onChange={onChange}
            onBlur={() => setIsFocused(false)}
        />
    ) : (
        <input
            type="text"
            className="form-control"
            value={value || placeholder}
            onFocus={() => setIsFocused(true)}
        />
    );
};

export default IndentsList;
