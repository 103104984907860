import React from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { removeSpecialCharacters } from "./utils";

const BrandsPDF = ({ brands }) => {
  const handlePrint = () => {
    const doc = new jsPDF();

    // Set the font to a standard font
    doc.setFont("Helvetica", "normal");

    // Page setup
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;

    // Header
    doc.setFontSize(16);
    doc.setFont("Helvetica", "bold");
    doc.text(
      removeSpecialCharacters("Lista Brendova"),
      pageWidth / 2,
      20,
      null,
      null,
      "center"
    );

    // Current date
    const currentDate = new Date().toLocaleDateString();
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    doc.text(
      removeSpecialCharacters(`Datum: ${currentDate}`),
      pageWidth - margin,
      30,
      null,
      null,
      "right"
    );

    // Table columns definition
    const columns = [
      { header: "#", dataKey: "index" },
      { header: "Naziv Brenda", dataKey: "brandName" },
    ];

    // Prepare data for PDF
    const data = brands.map((brand, index) => ({
      index: (index + 1).toString(),
      brandName: removeSpecialCharacters(brand.brandName),
    }));

    // Generate table
    autoTable(doc, {
      startY: 40,
      head: [columns.map(col => col.header)],
      body: data.map(row => [row.index, row.brandName]),
      styles: { fontSize: 10 },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: [255, 255, 255],
      },
      columnStyles: {
        0: { cellWidth: 30 }, // "#" column width
        1: { cellWidth: 'auto' }, // Brand name column
      },
      margin: { left: margin, right: margin },
      didDrawPage: (data) => {
        // Add page number at the bottom
        const pageNumber = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.text(
          `Strana ${pageNumber}`,
          pageWidth - margin,
          doc.internal.pageSize.getHeight() - 10,
          null,
          null,
          "right"
        );
      },
    });

    // Save the PDF
    doc.save("Lista_Brendova.pdf");
  };

  return (
    <button
      onClick={handlePrint}
      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      <i className="fas fa-print mr-2"></i>
      Štampaj
    </button>
  );
};

export default BrandsPDF; 