import axios from 'axios';
import axiosInstance from './api';
import AICommandService from './AICommandService';
import { toast } from 'react-toastify';

class AIService {
  constructor() {
    // Initialize available LLM providers
    this.providers = {
      deepseek: {
        name: 'DeepSeek R1',
        apiEndpoint: process.env.REACT_APP_DEEPSEEK_API_ENDPOINT,
        apiKey: process.env.REACT_APP_DEEPSEEK_API_KEY,
        model: 'deepseek-reasoner',
      },
      openai: {
        name: 'OpenAI',
        apiEndpoint: process.env.REACT_APP_OPENAI_API_ENDPOINT || 'https://api.openai.com/v1/chat/completions',
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        model: process.env.REACT_APP_OPENAI_MODEL || 'gpt-4o-2024-11-20',
      },
      anthropic: {
        name: 'Anthropic Claude',
        apiEndpoint: process.env.REACT_APP_ANTHROPIC_API_ENDPOINT || 'https://api.anthropic.com/v1/messages',
        apiKey: process.env.REACT_APP_ANTHROPIC_API_KEY,
        model: process.env.REACT_APP_ANTHROPIC_MODEL || 'claude-3-opus-20240229',
      },
      ollama: {
        name: 'Ollama Local',
        apiEndpoint: process.env.REACT_APP_OLLAMA_API_ENDPOINT || 'http://localhost:11434/api',
        model: process.env.REACT_APP_OLLAMA_MODEL || 'deepseek-r1:14b',
      },
      // Add more providers as needed
    };

    // Default provider
    this.currentProvider = process.env.REACT_APP_DEFAULT_LLM_PROVIDER || 'deepseek';
    
    // Get available commands from AICommandService
    this.availableCommands = AICommandService.getCommandsForAI();
  }

  // Set the current LLM provider
  setProvider(providerKey) {
    if (this.providers[providerKey]) {
      this.currentProvider = providerKey;
      return true;
    }
    return false;
  }

  // Get available LLM providers
  getAvailableProviders() {
    return Object.keys(this.providers).map(key => ({
      id: key,
      name: this.providers[key].name
    }));
  }

  // Get current provider
  getCurrentProvider() {
    return {
      id: this.currentProvider,
      name: this.providers[this.currentProvider].name
    };
  }

  // Main method to process user query
  async processQuery(userQuery, conversationHistory = []) {
    try {
      // First, analyze what the user is asking for
      const analysisPrompt = this.createAnalysisPrompt(userQuery, conversationHistory);
      const analysis = await this.callLLM(analysisPrompt, false); // Non-streaming for analysis
      
      // Parse the analysis to determine what data we need
      const requiredData = this.parseAnalysis(analysis);
      
      // Fetch the required data using AICommandService
      const contextData = await this.fetchRequiredDataUsingCommands(requiredData);
      
      // Generate the final response with the data
      const finalPrompt = this.createResponsePrompt(userQuery, conversationHistory, contextData);
      
      // Force disable streaming for all providers to ensure consistent behavior
      const useStreaming = false;
      console.log(`Using streaming: ${useStreaming} for provider: ${this.currentProvider}`);
      
      const response = await this.callLLM(finalPrompt, useStreaming);
      
      // Add debugging to check response type
      console.log("Response type:", typeof response);
      console.log("Response is streaming:", response && response.isStreaming);
      
      // If the response is a streaming object, return it directly
      // Otherwise, return the string response
      return response;
    } catch (error) {
      console.error("Error processing AI query:", error);
      throw new Error("Failed to process your query. Please try again later.");
    }
  }

  // Create a prompt to analyze what the user is asking for
  createAnalysisPrompt(userQuery, conversationHistory) {
    // Get available commands for the system prompt, but in a more compact format
    const compactCommands = this.getCompactCommandsDescription();
    
    // Filter and format conversation history to ensure first message is from user for DeepSeek R1
    // Also limit the conversation history to reduce tokens
    let formattedHistory = [];
    if (this.currentProvider === 'deepseek') {
      // For DeepSeek R1, ensure first message is from user
      const userMessages = conversationHistory.filter(msg => !msg.isAI);
      const aiMessages = conversationHistory.filter(msg => msg.isAI);
      
      // Limit to recent messages only - reduce from 3 to 2 for optimization
      const recentUserMessages = userMessages.slice(-2);
      const recentAiMessages = aiMessages.slice(-2);
      
      if (recentUserMessages.length > 0) {
        // Start with a user message
        formattedHistory.push({
          role: "user",
          content: recentUserMessages[0].text
        });
        
        // Then alternate between AI and user messages
        for (let i = 0; i < Math.max(recentAiMessages.length, recentUserMessages.length - 1); i++) {
          if (i < recentAiMessages.length) {
            formattedHistory.push({
              role: "assistant",
              content: recentAiMessages[i].text
            });
          }
          if (i + 1 < recentUserMessages.length) {
            formattedHistory.push({
              role: "user",
              content: recentUserMessages[i + 1].text
            });
          }
        }
      }
    } else {
      // For other providers, use the standard format but limit history
      formattedHistory = conversationHistory.slice(-4).map(msg => ({
        role: msg.isAI ? "assistant" : "user",
        content: msg.text
      }));
    }
    
    return {
      messages: [
        {
          role: "system",
          content: `You are an AI assistant that helps analyze user queries to determine what information is needed from our services. 
          
          Available commands (in format serviceName.commandName):
          ${compactCommands}
          
          Your task is to analyze the user's query and determine which commands need to be called and what specific data is required.
          Be concise and only request the data that's absolutely necessary.
          Respond in JSON format with the following structure:
          {
            "requiredCommands": [
              {
                "command": "serviceName.commandName",
                "parameters": [param1, param2, ...]
              }
            ],
            "explanation": "Brief explanation of what the user is asking for"
          }`
        },
        ...formattedHistory,
        {
          role: "user",
          content: userQuery
        }
      ]
    };
  }

  // Create a compact description of available commands to reduce token usage
  getCompactCommandsDescription() {
    const serviceDescriptions = {};
    
    // Group commands by service
    this.availableCommands.forEach(cmd => {
      const [service, command] = cmd.command.split('.');
      if (!serviceDescriptions[service]) {
        serviceDescriptions[service] = [];
      }
      
      // Create an even more compact command description
      const paramDesc = cmd.parameters.length > 0 
        ? `(${cmd.parameters.join(',')})` 
        : '';
      
      // Truncate descriptions to save tokens
      const shortDesc = cmd.description.length > 60 
        ? cmd.description.substring(0, 60) + '...' 
        : cmd.description;
      
      serviceDescriptions[service].push(`${command}${paramDesc}:${shortDesc}`);
    });
    
    // Format as a compact string
    let result = '';
    Object.entries(serviceDescriptions).forEach(([service, commands]) => {
      result += `\n${service}:\n  ${commands.join('\n  ')}\n`;
    });
    
    return result;
  }

  // Parse the LLM analysis to determine what data we need
  parseAnalysis(analysisResponse) {
    try {
      // Extract JSON from the response
      const jsonMatch = analysisResponse.match(/\{[\s\S]*\}/);
      if (jsonMatch) {
        return JSON.parse(jsonMatch[0]);
      }
      
      // Fallback if no JSON is found
      return {
        requiredCommands: [],
        explanation: "Could not determine required commands from analysis."
      };
    } catch (error) {
      console.error("Error parsing analysis:", error);
      return {
        requiredCommands: [],
        explanation: "Error parsing analysis response."
      };
    }
  }

  // Fetch required data using AICommandService
  async fetchRequiredDataUsingCommands(requiredData) {
    const results = {};
    
    if (!requiredData.requiredCommands || requiredData.requiredCommands.length === 0) {
      return { noDataRequired: true };
    }
    
    for (const commandRequest of requiredData.requiredCommands) {
      const { command, parameters } = commandRequest;
      
      if (!command) {
        results[`error_missing_command`] = "Command not specified";
        continue;
      }
      
      // Split command into service and method
      const [serviceName, commandName] = command.split('.');
      
      try {
        // Call the command using AICommandService
        const result = await AICommandService.executeCommand(serviceName, commandName, parameters || []);
        results[command] = result;
      } catch (error) {
        console.error(`Error calling ${command}:`, error);
        results[`${command}_error`] = error.message;
      }
    }
    
    return {
      results,
      explanation: requiredData.explanation
    };
  }

  // Create the final prompt with context data
  createResponsePrompt(userQuery, conversationHistory, contextData) {
    // Filter and format conversation history to ensure first message is from user for DeepSeek R1
    // Also limit the conversation history to reduce tokens
    let formattedHistory = [];
    if (this.currentProvider === 'deepseek') {
      // For DeepSeek R1, ensure first message is from user
      const userMessages = conversationHistory.filter(msg => !msg.isAI);
      const aiMessages = conversationHistory.filter(msg => msg.isAI);
      
      // Limit to recent messages only
      const recentUserMessages = userMessages.slice(-3);
      const recentAiMessages = aiMessages.slice(-3);
      
      if (recentUserMessages.length > 0) {
        // Start with a user message
        formattedHistory.push({
          role: "user",
          content: recentUserMessages[0].text
        });
        
        // Then alternate between AI and user messages
        for (let i = 0; i < Math.max(recentAiMessages.length, recentUserMessages.length - 1); i++) {
          if (i < recentAiMessages.length) {
            formattedHistory.push({
              role: "assistant",
              content: recentAiMessages[i].text
            });
          }
          if (i + 1 < recentUserMessages.length) {
            formattedHistory.push({
              role: "user",
              content: recentUserMessages[i + 1].text
            });
          }
        }
      }
    } else {
      // For other providers, use the standard format but limit history
      formattedHistory = conversationHistory.slice(-6).map(msg => ({
        role: msg.isAI ? "assistant" : "user",
        content: msg.text
      }));
    }
    
    // Limit the size of contextData to reduce tokens
    const compactContextData = this.createCompactContextData(contextData);
    
    return {
      messages: [
        {
          role: "system",
          content: `You are a helpful AI assistant for a business portal. You have access to various services and data about the business.
          
          The following data has been retrieved based on the user's query:
          ${compactContextData}
          
          Use this data to provide a helpful, accurate response to the user's query. If the data doesn't contain what you need,
          acknowledge the limitations and suggest what the user might do instead. Always be professional and courteous.`
        },
        ...formattedHistory,
        {
          role: "user",
          content: userQuery
        }
      ]
    };
  }

  // Create a compact version of the context data to reduce token usage
  createCompactContextData(contextData) {
    if (contextData.noDataRequired) {
      return JSON.stringify({ noDataRequired: true });
    }
    
    // Create a summarized version of the results
    const compactResults = {};
    
    if (contextData.results) {
      Object.entries(contextData.results).forEach(([key, value]) => {
        if (key.endsWith('_error')) {
          compactResults[key] = value; // Keep error messages as is
        } else if (Array.isArray(value)) {
          // For arrays, limit the number of items
          const maxItems = 10;
          compactResults[key] = value.length > maxItems 
            ? { 
                summary: `Array with ${value.length} items. Showing first ${maxItems}.`,
                items: value.slice(0, maxItems)
              }
            : value;
        } else if (typeof value === 'object' && value !== null) {
          // For objects, keep them as is but with a note about size
          compactResults[key] = value;
        } else {
          compactResults[key] = value;
        }
      });
    }
    
    return JSON.stringify({
      results: compactResults,
      explanation: contextData.explanation
    }, null, 1); // Use less indentation to save tokens
  }

  // Call the LLM API based on the current provider
  async callLLM(prompt, useStreaming = false) {
    const provider = this.providers[this.currentProvider];
    
    if (!provider) {
      throw new Error(`Provider ${this.currentProvider} not configured`);
    }
    
    if (this.currentProvider !== 'ollama' && !provider.apiKey) {
      throw new Error(`API key for ${provider.name} not configured`);
    }
    
    try {
      let response;
      
      switch (this.currentProvider) {
        case 'deepseek':
          response = await this.callDeepseek(prompt, provider, useStreaming);
          break;
        case 'openai':
          response = await this.callOpenAI(prompt, provider, useStreaming);
          break;
        case 'anthropic':
          response = await this.callAnthropic(prompt, provider, useStreaming);
          break;
        case 'ollama':
          response = await this.callOllama(prompt, provider, useStreaming);
          break;
        default:
          throw new Error(`Provider ${this.currentProvider} not implemented`);
      }
      
      return response;
    } catch (error) {
      console.error(`Error calling ${provider.name} API:`, error);
      
      // Show a toast notification for API errors
      toast.error(`Failed to get response from ${provider.name}. ${error.message}`);
      
      throw new Error(`Failed to get response from ${provider.name}. ${error.message}`);
    }
  }

  // Call DeepSeek API with streaming support
  async callDeepseek(prompt, provider, useStreaming = false) {
    if (!useStreaming) {
      const response = await axios.post(
        provider.apiEndpoint,
        {
          model: provider.model || "deepseek-reasoner",
          messages: prompt.messages,
          temperature: 0.7,
          max_tokens: 2000
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${provider.apiKey}`
          }
        }
      );
      
      return response.data.choices[0].message.content;
    } else {
      // Return a streaming response handler
      return {
        isStreaming: true,
        stream: async (onChunk, onComplete, onError) => {
          try {
            const response = await axios.post(
              provider.apiEndpoint,
              {
                model: provider.model || "deepseek-reasoner",
                messages: prompt.messages,
                temperature: 0.7,
                max_tokens: 2000,
                stream: true
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${provider.apiKey}`
                },
                responseType: 'stream'
              }
            );
            
            let fullResponse = '';
            
            response.data.on('data', (chunk) => {
              try {
                const lines = chunk.toString().split('\n').filter(line => line.trim() !== '');
                
                for (const line of lines) {
                  if (line.includes('[DONE]')) continue;
                  
                  if (line.startsWith('data:')) {
                    const jsonData = JSON.parse(line.slice(5));
                    if (jsonData.choices && jsonData.choices[0].delta.content) {
                      const content = jsonData.choices[0].delta.content;
                      fullResponse += content;
                      onChunk(content);
                    }
                  }
                }
              } catch (e) {
                console.error('Error parsing stream chunk:', e);
              }
            });
            
            response.data.on('end', () => {
              onComplete(fullResponse);
            });
            
            response.data.on('error', (err) => {
              onError(err);
            });
          } catch (error) {
            onError(error);
          }
        }
      };
    }
  }

  // Call OpenAI API with function calling capabilities and streaming
  async callOpenAI(prompt, provider, useStreaming = false) {
    // Set up the API key - use the hardcoded key directly to ensure it works
    const apiKey = "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";
    
    console.log("Using OpenAI API key:", apiKey.substring(0, 10) + "...");
    
    if (!apiKey) {
      console.error("OpenAI API key is not configured");
      throw new Error("OpenAI API key is not configured");
    }
    
    // Define tools for function calling
    const tools = this.createOpenAITools();
    
    // Common request parameters
    const requestParams = {
      model: provider.model,
      messages: prompt.messages,
      temperature: 0.7,
      max_tokens: 2000
    };
    
    // Only add tools if we have any
    if (tools && tools.length > 0) {
      requestParams.tools = tools;
      requestParams.tool_choice = "auto"; // Let the model decide when to use tools
    }
    
    // Force disable streaming for OpenAI for now
    useStreaming = false;
    
    // Non-streaming code (existing implementation)
    console.log("Making non-streaming OpenAI request");
    
    try {
      // Initial request with tools
      const initialResponse = await axios.post(
        provider.apiEndpoint,
        requestParams,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          }
        }
      );
      
      console.log("OpenAI response status:", initialResponse.status);
      
      // Check if the model wants to call functions
      const message = initialResponse.data.choices[0].message;
      
      if (message.tool_calls && message.tool_calls.length > 0) {
        console.log("Tool calls detected, handling them");
        // The model wants to call one or more functions
        const updatedMessages = [...prompt.messages, message];
        
        // Execute each function call
        for (const toolCall of message.tool_calls) {
          if (toolCall.type === 'function') {
            const functionName = toolCall.function.name;
            let functionArgs;
            
            try {
              functionArgs = JSON.parse(toolCall.function.arguments);
              console.log(`Parsed function arguments:`, functionArgs);
            } catch (error) {
              console.error(`Error parsing function arguments:`, error);
              console.log(`Raw arguments:`, toolCall.function.arguments);
              functionArgs = { command: "getById", parameters: ["5"] }; // Fallback for debugging
            }
            
            console.log(`Executing function ${functionName} with args:`, functionArgs);
            
            // Execute the function
            const functionResult = await this.executeFunction(functionName, functionArgs);
            console.log(`Function ${functionName} result:`, functionResult);
            
            // Add the function result to the messages
            updatedMessages.push({
              role: "tool",
              tool_call_id: toolCall.id,
              content: JSON.stringify(functionResult)
            });
          }
        }
        
        // Make a second request with the function results
        console.log("Making second request with function results");
        
        const finalResponse = await axios.post(
          provider.apiEndpoint,
          {
            model: provider.model,
            messages: updatedMessages,
            temperature: 0.7,
            max_tokens: 2000
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${apiKey}`
            }
          }
        );
        
        console.log("Final response status:", finalResponse.status);
        return finalResponse.data.choices[0].message.content || "I need to look up that information for you.";
      }
      
      // If no function calls, return the original response content
      // Handle the case where content might be null
      return message.content || "I need to look up that information for you.";
    } catch (error) {
      console.error("Error in OpenAI API call:", error);
      if (error.response) {
        console.error("OpenAI API error response:", error.response.data);
      }
      throw new Error(`OpenAI API error: ${error.message}`);
    }
  }

  // Call Anthropic API with streaming support
  async callAnthropic(prompt, provider, useStreaming = false) {
    const messages = prompt.messages.map(msg => {
      if (msg.role === 'system') {
        return { role: 'system', content: msg.content };
      } else if (msg.role === 'user') {
        return { role: 'user', content: msg.content };
      } else {
        return { role: 'assistant', content: msg.content };
      }
    });
    
    if (!useStreaming) {
      const response = await axios.post(
        provider.apiEndpoint,
        {
          model: provider.model,
          messages: messages,
          max_tokens: 2000
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': provider.apiKey,
            'anthropic-version': '2023-06-01'
          }
        }
      );
      
      return response.data.content[0].text;
    } else {
      // Return a streaming response handler
      return {
        isStreaming: true,
        stream: async (onChunk, onComplete, onError) => {
          try {
            const response = await axios.post(
              provider.apiEndpoint,
              {
                model: provider.model,
                messages: messages,
                max_tokens: 2000,
                stream: true
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'x-api-key': provider.apiKey,
                  'anthropic-version': '2023-06-01'
                },
                responseType: 'stream'
              }
            );
            
            let fullResponse = '';
            
            response.data.on('data', (chunk) => {
              try {
                const lines = chunk.toString().split('\n').filter(line => line.trim() !== '');
                
                for (const line of lines) {
                  if (line.startsWith('data:')) {
                    const jsonData = JSON.parse(line.slice(5));
                    if (jsonData.type === 'content_block_delta' && jsonData.delta && jsonData.delta.text) {
                      const content = jsonData.delta.text;
                      fullResponse += content;
                      onChunk(content);
                    }
                  }
                }
              } catch (e) {
                console.error('Error parsing stream chunk:', e);
              }
            });
            
            response.data.on('end', () => {
              onComplete(fullResponse);
            });
            
            response.data.on('error', (err) => {
              onError(err);
            });
          } catch (error) {
            onError(error);
          }
        }
      };
    }
  }

  // Call Ollama API with streaming support
  async callOllama(prompt, provider, useStreaming = false) {
    // Determine which endpoint to use based on the prompt structure
    const isAnalysisPrompt = prompt.messages.some(msg => 
      msg.role === 'system' && msg.content.includes('analyze user queries to determine what information is needed')
    );
    
    // For analysis prompts, we need JSON output (no streaming)
    if (isAnalysisPrompt) {
      return this.callOllamaGenerate(prompt, provider, true);
    } else {
      // Check if we need to use function calling
      const tools = this.createOllamaTools();
      if (tools.length > 0) {
        return this.callOllamaWithFunctions(prompt, provider, tools, useStreaming);
      } else {
        // For regular chat without functions
        return this.callOllamaChat(prompt, provider, useStreaming);
      }
    }
  }
  
  // Call Ollama Chat API with streaming support
  async callOllamaChat(prompt, provider, useStreaming = false) {
    if (!useStreaming) {
      try {
        const response = await axios.post(
          `${provider.apiEndpoint}/chat`,
          {
            model: provider.model,
            messages: prompt.messages,
            stream: false,
            options: {
              temperature: 0.7
            }
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        
        return response.data.message.content;
      } catch (error) {
        console.error("Error in callOllamaChat (non-streaming):", error);
        throw error;
      }
    } else {
      // Return a streaming response handler
      return {
        isStreaming: true,
        stream: async (onChunk, onComplete, onError) => {
          try {
            console.log("Starting Ollama streaming request");
            const response = await axios.post(
              `${provider.apiEndpoint}/chat`,
              {
                model: provider.model,
                messages: prompt.messages,
                stream: true,
                options: {
                  temperature: 0.7
                }
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                },
                responseType: 'stream'
              }
            );
            
            let fullResponse = '';
            
            response.data.on('data', (chunk) => {
              try {
                const chunkStr = chunk.toString();
                console.log("Received chunk:", chunkStr);
                
                const lines = chunkStr.split('\n').filter(line => line.trim() !== '');
                
                for (const line of lines) {
                  if (line.trim() === '') continue;
                  
                  try {
                    const jsonData = JSON.parse(line);
                    if (jsonData.message && jsonData.message.content) {
                      const content = jsonData.message.content;
                      // For Ollama, each chunk contains the full response so far
                      // We need to extract just the new part
                      const newContent = content.substring(fullResponse.length);
                      if (newContent) {
                        fullResponse = content;
                        console.log("Sending chunk to UI:", newContent);
                        onChunk(newContent);
                      }
                    }
                  } catch (parseError) {
                    console.error("Error parsing JSON in stream chunk:", parseError, "Line:", line);
                  }
                }
              } catch (e) {
                console.error('Error processing stream chunk:', e);
              }
            });
            
            response.data.on('end', () => {
              console.log("Stream ended, full response:", fullResponse);
              onComplete(fullResponse || "No response received from Ollama.");
            });
            
            response.data.on('error', (err) => {
              console.error('Stream error:', err);
              onError(err);
            });
          } catch (error) {
            console.error('Error in Ollama streaming request:', error);
            onError(error);
          }
        }
      };
    }
  }

  // Call Ollama with function calling capabilities and streaming
  async callOllamaWithFunctions(prompt, provider, tools, useStreaming = false) {
    // Initial request with system message that includes function definitions
    const systemMessage = prompt.messages.find(msg => msg.role === 'system');
    const systemContent = systemMessage ? systemMessage.content : '';
    
    // Create a new messages array without the original system message
    const messagesWithoutSystem = prompt.messages.filter(msg => msg.role !== 'system');
    
    // Add function definitions to system message
    const functionsDescription = this.getOllamaFunctionDescriptions(tools);
    const enhancedSystemMessage = {
      role: 'system',
      content: `${systemContent}\n\n${functionsDescription}\n\nWhen you need to access data, use the available functions by responding with: <function_call name="function_name" arguments={"param1": "value1"}></function_call>`
    };
    
    // Create enhanced messages with function definitions
    const enhancedMessages = [enhancedSystemMessage, ...messagesWithoutSystem];
    
    if (!useStreaming) {
      // Make the initial request
      const response = await axios.post(
        `${provider.apiEndpoint}/chat`,
        {
          model: provider.model,
          messages: enhancedMessages,
          stream: false,
          options: {
            temperature: 0.7
          }
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      let responseContent = response.data.message.content;
      
      // Check if the response contains a function call
      const functionCallMatch = responseContent.match(/<function_call name="([^"]+)" arguments=({[^}]+})><\/function_call>/);
      
      if (functionCallMatch) {
        // Extract function name and arguments
        const functionName = functionCallMatch[1];
        const functionArgs = JSON.parse(functionCallMatch[2]);
        
        // Execute the function
        const functionResult = await this.executeOllamaFunction(functionName, functionArgs);
        
        // Add the function call and result to the messages
        const updatedMessages = [
          ...enhancedMessages,
          {
            role: 'assistant',
            content: responseContent
          },
          {
            role: 'function',
            name: functionName,
            content: JSON.stringify(functionResult)
          }
        ];
        
        // Make a second request with the function results
        const finalResponse = await axios.post(
          `${provider.apiEndpoint}/chat`,
          {
            model: provider.model,
            messages: updatedMessages,
            stream: false,
            options: {
              temperature: 0.7
            }
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        
        return finalResponse.data.message.content;
      }
      
      // If no function calls, return the original response
      return responseContent;
    } else {
      // Return a streaming response handler for function calling
      return {
        isStreaming: true,
        stream: async (onChunk, onComplete, onError) => {
          try {
            // First, make a non-streaming request to check for function calls
            const initialResponse = await axios.post(
              `${provider.apiEndpoint}/chat`,
              {
                model: provider.model,
                messages: enhancedMessages,
                stream: false,
                options: {
                  temperature: 0.7
                }
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            );
            
            let responseContent = initialResponse.data.message.content;
            
            // Check if the response contains a function call
            const functionCallMatch = responseContent.match(/<function_call name="([^"]+)" arguments=({[^}]+})><\/function_call>/);
            
            if (functionCallMatch) {
              // Extract function name and arguments
              const functionName = functionCallMatch[1];
              const functionArgs = JSON.parse(functionCallMatch[2]);
              
              // Notify that we're calling a function
              onChunk(`[Calling function ${functionName}...]`);
              
              // Execute the function
              const functionResult = await this.executeOllamaFunction(functionName, functionArgs);
              
              // Add the function call and result to the messages
              const updatedMessages = [
                ...enhancedMessages,
                {
                  role: 'assistant',
                  content: responseContent
                },
                {
                  role: 'function',
                  name: functionName,
                  content: JSON.stringify(functionResult)
                }
              ];
              
              // Make a streaming request with the function results
              const response = await axios.post(
                `${provider.apiEndpoint}/chat`,
                {
                  model: provider.model,
                  messages: updatedMessages,
                  stream: true,
                  options: {
                    temperature: 0.7
                  }
                },
                {
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  responseType: 'stream'
                }
              );
              
              let fullResponse = '';
              
              response.data.on('data', (chunk) => {
                try {
                  const lines = chunk.toString().split('\n').filter(line => line.trim() !== '');
                  
                  for (const line of lines) {
                    if (line.trim() === '') continue;
                    
                    const jsonData = JSON.parse(line);
                    if (jsonData.message && jsonData.message.content) {
                      const content = jsonData.message.content;
                      // For Ollama, each chunk contains the full response so far
                      // We need to extract just the new part
                      const newContent = content.substring(fullResponse.length);
                      if (newContent) {
                        fullResponse = content;
                        onChunk(newContent);
                      }
                    }
                  }
                } catch (e) {
                  console.error('Error parsing stream chunk:', e);
                }
              });
              
              response.data.on('end', () => {
                onComplete(fullResponse);
              });
              
              response.data.on('error', (err) => {
                onError(err);
              });
            } else {
              // No function calls, so we can stream the original response
              onChunk(responseContent);
              onComplete(responseContent);
            }
          } catch (error) {
            onError(error);
          }
        }
      };
    }
  }

  // Create tools definition for OpenAI function calling
  createOpenAITools() {
    const tools = [];
    
    // Group commands by service
    const serviceCommands = {};
    this.availableCommands.forEach(cmd => {
      const [service, command] = cmd.command.split('.');
      if (!serviceCommands[service]) {
        serviceCommands[service] = [];
      }
      serviceCommands[service].push({
        command,
        description: cmd.description,
        parameters: cmd.parameters
      });
    });
    
    // Create a function for each service
    Object.entries(serviceCommands).forEach(([service, commands]) => {
      // Create a function tool for this service
      const serviceTool = {
        type: "function",
        function: {
          name: `query_${service}`,
          description: `Get information from the ${service} service`,
          parameters: {
            type: "object",
            properties: {
              command: {
                type: "string",
                enum: commands.map(cmd => cmd.command),
                description: `The command to execute in the ${service} service`
              },
              parameters: {
                type: "array",
                items: {
                  type: "string"
                },
                description: "Parameters for the command"
              }
            },
            required: ["command", "parameters"],
            additionalProperties: false
          },
          strict: true
        }
      };
      
      tools.push(serviceTool);
    });
    
    return tools;
  }
  
  // Execute a function called by OpenAI
  async executeFunction(functionName, args) {
    // Extract service name from function name (e.g., query_articles -> articles)
    const serviceName = functionName.replace('query_', '');
    const { command, parameters = [] } = args;
    
    console.log(`Executing function for service: ${serviceName}, command: ${command}, parameters:`, parameters);
    
    try {
      // Log the parameters type to debug
      console.log(`Parameters type: ${typeof parameters}, isArray: ${Array.isArray(parameters)}`);
      console.log(`Parameters stringified:`, JSON.stringify(parameters));
      
      // Ensure parameters is an array
      let paramsArray = Array.isArray(parameters) ? parameters : [parameters];
      
      // Special handling for customers.getById
      if (serviceName === 'customers' && command === 'getById') {
        // If parameters is a string like "5", convert it to an array ["5"]
        if (typeof parameters === 'string') {
          paramsArray = [parameters];
        }
        // If parameters is a number like 5, convert it to an array ["5"]
        else if (typeof parameters === 'number') {
          paramsArray = [String(parameters)];
        }
        // If parameters is an array with a number, convert it to strings
        else if (Array.isArray(parameters) && parameters.length > 0) {
          paramsArray = parameters.map(p => String(p));
        }
        
        console.log(`Special handling for customers.getById, converted parameters:`, paramsArray);
      }
      
      // Execute the command using AICommandService
      console.log(`Calling AICommandService.executeCommand with:`, serviceName, command, paramsArray);
      const result = await AICommandService.executeCommand(serviceName, command, paramsArray);
      console.log(`Command execution result:`, result);
      
      // Format the result in a more user-friendly way
      let formattedResult;
      
      if (serviceName === 'customers' && command === 'getById') {
        formattedResult = {
          success: true,
          data: result,
          message: `Found customer with ID ${paramsArray[0]}: ${result.clientName || 'Unknown'}`
        };
      } else {
        formattedResult = {
          success: true,
          data: result
        };
      }
      
      return formattedResult;
    } catch (error) {
      console.error(`Error executing function ${functionName}:`, error);
      
      // Create a more user-friendly error message
      let errorMessage = error.message;
      
      if (serviceName === 'customers' && command === 'getById' && parameters) {
        const customerId = Array.isArray(parameters) ? parameters[0] : parameters;
        errorMessage = `Could not find customer with ID ${customerId}. ${error.message}`;
      }
      
      return {
        success: false,
        error: errorMessage
      };
    }
  }

  // Create function descriptions for Ollama
  getOllamaFunctionDescriptions(tools) {
    let description = 'Available functions:\n\n';
    
    tools.forEach(tool => {
      description += `Function: ${tool.function.name}\n`;
      description += `Description: ${tool.function.description}\n`;
      description += 'Parameters:\n';
      
      const properties = tool.function.parameters.properties;
      Object.entries(properties).forEach(([paramName, paramDetails]) => {
        description += `  - ${paramName}: ${paramDetails.description} (${paramDetails.type})\n`;
        if (paramDetails.enum) {
          description += `    Allowed values: ${paramDetails.enum.join(', ')}\n`;
        }
      });
      
      description += '\n';
    });
    
    return description;
  }
  
  // Create tools definition for Ollama function calling (reuse OpenAI tools)
  createOllamaTools() {
    return this.createOpenAITools();
  }
  
  // Execute a function called by Ollama
  async executeOllamaFunction(functionName, args) {
    // Extract service name from function name (e.g., query_articles -> articles)
    const serviceName = functionName.replace('query_', '');
    const { command, parameters = [] } = args;
    
    try {
      // Execute the command using AICommandService
      const result = await AICommandService.executeCommand(serviceName, command, parameters);
      return {
        success: true,
        data: result
      };
    } catch (error) {
      console.error(`Error executing function ${functionName}:`, error);
      return {
        success: false,
        error: error.message
      };
    }
  }

  // Call Ollama Generate API (for JSON responses)
  async callOllamaGenerate(prompt, provider, formatJson = false) {
    // Combine all messages into a single prompt for the generate endpoint
    let combinedPrompt = '';
    
    // Add system message if present
    const systemMessage = prompt.messages.find(msg => msg.role === 'system');
    if (systemMessage) {
      combinedPrompt += `System: ${systemMessage.content}\n\n`;
    }
    
    // Add conversation history
    for (const msg of prompt.messages) {
      if (msg.role === 'system') continue; // Already handled
      
      if (msg.role === 'user') {
        combinedPrompt += `User: ${msg.content}\n\n`;
      } else if (msg.role === 'assistant') {
        combinedPrompt += `Assistant: ${msg.content}\n\n`;
      }
    }
    
    // Request body
    const requestBody = {
      model: provider.model,
      prompt: combinedPrompt,
      stream: false,
      options: {
        temperature: 0.7
      }
    };
    
    // Add JSON format if needed
    if (formatJson) {
      requestBody.format = 'json';
    }
    
    const response = await axios.post(
      `${provider.apiEndpoint}/generate`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    
    return response.data.response;
  }
}

export default new AIService(); 