import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import ArticleDataService from '../services/ArticleService';
import { useNavigate } from "react-router-dom";
import { toastService } from '../services/toastService';

// Reusable Components
const SearchBar = ({ value, onChange }) => (
  <div className="mb-6">
    <div className="relative">
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder="Pretraži artikle..."
        className="w-full px-4 py-2 pl-10 pr-8 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <i className="fas fa-search text-gray-400"></i>
      </div>
    </div>
  </div>
);

const ActionButton = ({ onClick, icon, label, variant = "primary" }) => {
  const baseClasses = "px-3 py-1.5 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200";
  const variants = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500",
    success: "bg-green-600 hover:bg-green-700 text-white focus:ring-green-500",
    warning: "bg-yellow-600 hover:bg-yellow-700 text-white focus:ring-yellow-500",
    danger: "bg-red-600 hover:bg-red-700 text-white focus:ring-red-500",
  };

  return (
    <button 
      onClick={onClick}
      className={`${baseClasses} ${variants[variant]}`}
    >
      <i className={`fas ${icon} mr-2`}></i>
      {label}
    </button>
  );
};

const ArticleTable = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    retrieveArticles();
  }, []);

  const retrieveArticles = async () => {
    try {
      setLoading(true);
      const response = await ArticleDataService.getAll();
      
      // Group and sort data
      const groupedData = response.data.reduce((groups, article) => {
        const brandName = article.brand.brandName;
        if (!groups[brandName]) groups[brandName] = [];
        groups[brandName].push(article);
        return groups;
      }, {});

      // Sort within groups
      Object.values(groupedData).forEach(group => 
        group.sort((a, b) => a.name.localeCompare(b.name))
      );

      setArticles(Object.values(groupedData).flat());
      setError(null);
    } catch (err) {
      setError("Došlo je do greške prilikom učitavanja artikala");
      toastService.error("Greška pri učitavanju podataka");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (id, action) => {
    try {
      setLoading(true);
      await ArticleDataService[action](id);
      await retrieveArticles();
      toastService.success('Uspešno ažuriran status artikla!');
    } catch (err) {
      toastService.error('Greška pri ažuriranju statusa artikla!');
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Šifra',
        accessor: 'code',
        width: '8%',
      },
      {
        Header: 'GTIN',
        accessor: 'barcode',
        width: '10%',
      },
      {
        Header: 'Naziv',
        accessor: 'name',
        width: '25%',
      },
      {
        Header: 'Brend',
        accessor: 'brand.brandName',
        width: '12%',
      },
      {
        Header: 'Cena',
        accessor: 'wholesalePrice',
        Cell: ({ value }) => (
          <span className="font-medium whitespace-nowrap">
            {new Intl.NumberFormat('sr-RS', { 
              style: 'currency', 
              currency: 'RSD' 
            }).format(value)}
          </span>
        ),
        width: '12%',
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        Cell: ({ value }) => (
          <span className={`
            px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap
            ${value ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}
          `}>
            {value ? 'Aktivan' : 'Neaktivan'}
          </span>
        ),
        width: '8%',
      },
      {
        Header: 'Vidljivost',
        accessor: 'isVisible',
        Cell: ({ value }) => (
          <span className={`
            px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap
            ${value ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'}
          `}>
            {value ? 'Vidljiv' : 'Sakriven'}
          </span>
        ),
        width: '8%',
      },
      {
        Header: 'Akcije',
        accessor: 'actions',
        Cell: ({ row: { original } }) => (
          <div className="flex gap-1 whitespace-nowrap">
            <button
              onClick={() => navigate(`/articles/edit/${original.article_id}`)}
              className="px-2 py-1 text-xs font-medium text-blue-600 hover:text-blue-800"
              title="Izmeni"
            >
              <i className="fas fa-edit"></i>
            </button>
            <button
              onClick={() => handleStatusChange(
                original.article_id,
                original.isActive ? 'deactivateArticle' : 'activateArticle'
              )}
              className={`px-2 py-1 text-xs font-medium ${
                original.isActive ? 'text-yellow-600 hover:text-yellow-800' : 'text-green-600 hover:text-green-800'
              }`}
              title={original.isActive ? 'Deaktiviraj' : 'Aktiviraj'}
            >
              <i className={`fas ${original.isActive ? 'fa-times' : 'fa-check'}`}></i>
            </button>
            <button
              onClick={() => handleStatusChange(
                original.article_id,
                original.isVisible ? 'hideArticle' : 'showArticle'
              )}
              className={`px-2 py-1 text-xs font-medium ${
                original.isVisible ? 'text-red-600 hover:text-red-800' : 'text-green-600 hover:text-green-800'
              }`}
              title={original.isVisible ? 'Sakrij' : 'Prikaži'}
            >
              <i className={`fas ${original.isVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </button>
          </div>
        ),
        width: '17%',
      },
    ],
    [navigate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
    state: { pageIndex },
  } = useTable(
    { 
      columns, 
      data: articles,
      initialState: { 
        pageSize,
        sortBy: [{ id: 'brand.brandName', desc: false }]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Lista Artikala</h1>
        <ActionButton
          onClick={() => navigate('/articles/add')}
          icon="fa-plus"
          label="Dodaj Artikal"
          variant="success"
        />
      </div>

      <SearchBar
        onChange={e => setGlobalFilter(e.target.value)}
      />

      <div className="bg-white rounded-lg shadow-sm">
        <div className="overflow-x-auto">
          <table {...getTableProps()} className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      style={{ width: column.width }}
                    >
                      {column.render('Header')}
                      <span className="ml-1">
                        {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr 
                    {...row.getRowProps()}
                    className={`
                      hover:bg-gray-50 transition-colors duration-150
                      ${!row.original.isActive ? 'bg-red-50' : ''}
                    `}
                  >
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        className="px-3 py-2 text-sm text-gray-900"
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Prethodna
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Sledeća
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Prikazano{' '}
                <span className="font-medium">{pageIndex * pageSize + 1}</span>
                {' '}-{' '}
                <span className="font-medium">
                  {Math.min((pageIndex + 1) * pageSize, articles.length)}
                </span>
                {' '}od{' '}
                <span className="font-medium">{articles.length}</span>
                {' '}rezultata
              </p>
            </div>
            <div className="flex gap-x-2">
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                  setTablePageSize(Number(e.target.value));
                }}
                className="form-select rounded-md border-gray-300 text-base"
              >
                {[10, 20, 30, 40, 50].map(size => (
                  <option key={size} value={size}>
                    Prikaži {size}
                  </option>
                ))}
              </select>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <i className="fas fa-chevron-left"></i>
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <i className="fas fa-chevron-right"></i>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleTable;
