import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomerService from "../services/CustomerService";
import { useParams, useNavigate } from 'react-router-dom';
import { toastService } from '../services/toastService';

// Reusable Components
const FormField = ({ label, name, type = "text", formik }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <input
      type={type}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      className={`
        w-full px-3 py-2 border rounded-md shadow-sm
        ${formik.touched[name] && formik.errors[name]
          ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
          : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
        }
      `}
    />
    {formik.touched[name] && formik.errors[name] && (
      <p className="mt-1 text-sm text-red-600">{formik.errors[name]}</p>
    )}
  </div>
);

const RadioGroup = ({ label, name, options, formik }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
    <div className="space-x-4">
      {options.map((option) => (
        <label key={option.value} className="inline-flex items-center">
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={formik.values[name] === option.value}
            onChange={formik.handleChange}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
          />
          <span className="ml-2 text-sm text-gray-700">{option.label}</span>
        </label>
      ))}
    </div>
    {formik.touched[name] && formik.errors[name] && (
      <p className="mt-1 text-sm text-red-600">{formik.errors[name]}</p>
    )}
  </div>
);

const EditClient = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id: clientID } = useParams();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    nameOfTheLegalEntity: Yup.string()
      .required('Ime legalnog entiteta je obavezno.')
      .min(2, 'Ime mora imati najmanje 2 karaktera'),
    city: Yup.string()
      .required('Grad je obavezan.')
      .min(2, 'Naziv grada mora imati najmanje 2 karaktera'),
    address: Yup.string()
      .required('Adresa je obavezna.')
      .min(5, 'Adresa mora imati najmanje 5 karaktera'),
    pib: Yup.string()
      .required('PIB je obavezan.')
      .length(9, 'PIB mora imati tačno 9 brojeva.')
      .matches(/^[0-9]+$/, 'PIB mora sadržati samo brojeve.'),
    identificationNumber: Yup.string()
      .required('Matični broj je obavezan.')
      .length(8, 'Matični broj mora imati tačno 8 brojeva.')
      .matches(/^[0-9]+$/, 'Matični broj mora sadržati samo brojeve.'),
    contactPerson: Yup.string()
      .required('Kontakt osoba je obavezna.')
      .min(2, 'Ime kontakt osobe mora imati najmanje 2 karaktera'),
    contactNumber: Yup.string()
      .required('Kontakt broj je obavezan.')
      .matches(/^[0-9+\-\s]*$/, 'Nevažeći format broja telefona'),
    email: Yup.string()
      .required('Email je obavezan.')
      .email('Nevažeća email adresa'),
    isActive: Yup.string()
      .required('Status klijenta je obavezan.'),
    paymentCurrency: Yup.string()
      .required('Valuta plaćanja je obavezna.')
  });

  const formik = useFormik({
    initialValues: {
      customerCode: "",
      nameOfTheLegalEntity: "",
      city: "",
      address: "",
      pib: "",
      identificationNumber: "",
      contactPerson: "",
      contactNumber: "",
      email: "",
      isActive: "true",
      comment: "",
      paymentCurrency: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await CustomerService.update(clientID, values);
        toastService.success('Uspešno ažuriran klijent!');
        navigate('/clients');
      } catch (err) {
        toastService.error('Greška pri ažuriranju klijenta!');
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const loadClient = async () => {
      try {
        setLoading(true);
        const response = await CustomerService.get(clientID);
        const client = response.data;
        formik.setValues({
          customerCode: client.customerCode || "",
          nameOfTheLegalEntity: client.nameOfTheLegalEntity || "",
          city: client.city || "",
          address: client.address || "",
          pib: client.pib || "",
          identificationNumber: client.identificationNumber || "",
          contactPerson: client.contactPerson || "",
          contactNumber: client.contactNumber || "",
          email: client.email || "",
          isActive: client.isActive ? 'true' : 'false',
          comment: client.comment || "",
          paymentCurrency: client.paymentCurrency || "",
        });
        setError(null);
      } catch (err) {
        setError("Došlo je do greške prilikom učitavanja podataka");
        toastService.error("Greška pri učitavanju podataka");
      } finally {
        setLoading(false);
      }
    };

    loadClient();
  }, [clientID]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold text-gray-900">
              Izmena Klijenta
            </h1>
            <button
              onClick={() => navigate('/clients')}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <i className="fas fa-arrow-left mr-2"></i>
              Nazad
            </button>
          </div>

          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormField
                label="Šifra Klijenta"
                name="customerCode"
                formik={formik}
              />
              <FormField
                label="Naziv Legalnog Entiteta"
                name="nameOfTheLegalEntity"
                formik={formik}
              />
              <FormField
                label="Grad"
                name="city"
                formik={formik}
              />
              <FormField
                label="Adresa"
                name="address"
                formik={formik}
              />
              <FormField
                label="PIB"
                name="pib"
                formik={formik}
              />
              <FormField
                label="Matični Broj"
                name="identificationNumber"
                formik={formik}
              />
              <FormField
                label="Kontakt Osoba"
                name="contactPerson"
                formik={formik}
              />
              <FormField
                label="Kontakt Broj"
                name="contactNumber"
                formik={formik}
              />
              <FormField
                label="Email"
                name="email"
                type="email"
                formik={formik}
              />
              <FormField
                label="Valuta Plaćanja"
                name="paymentCurrency"
                formik={formik}
              />
            </div>

            <RadioGroup
              label="Status klijenta"
              name="isActive"
              formik={formik}
              options={[
                { value: "true", label: "Aktivan" },
                { value: "false", label: "Neaktivan" }
              ]}
            />

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Komentar
              </label>
              <textarea
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={4}
                className="w-full px-3 py-2 border rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="flex justify-end space-x-3 pt-6 border-t">
              <button
                type="button"
                onClick={() => navigate('/clients')}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Odustani
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {loading ? 'Ažuriranje...' : 'Sačuvaj Izmene'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditClient;

