import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import IndentDataService from "../services/IndentService";
import { toastService } from '../services/toastService';

const StatusBadge = ({ status }) => {
  const statusStyles = {
    PENDING: "bg-yellow-100 text-yellow-800",
    ACTIVATED: "bg-green-100 text-green-800",
    CANCELED: "bg-red-100 text-red-800",
    DELIVERED: "bg-blue-100 text-blue-800"
  };

  return (
    <span className={`px-3 py-1 rounded-full text-sm font-medium ${statusStyles[status] || "bg-gray-100 text-gray-800"}`}>
      {status}
    </span>
  );
};

const DetailRow = ({ label, value }) => (
  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{value}</dd>
  </div>
);

const Indent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [indent, setIndent] = useState({
    indent_id: "",
    code: "",
    customerId: "",
    indentStatus: "",
    customerNameOfTheLegalEntity: "",
    deliveryAddressCity: "",
    deliveryAddressLocation: "",
    creationTime: "",
    totalAmount: 0,
    items: []
  });

  useEffect(() => {
    loadIndentData();
  }, [id]);

  const loadIndentData = async () => {
    try {
      setLoading(true);
      const response = await IndentDataService.get(id);
      setIndent(response.data);
      setError(null);
    } catch (err) {
      setError("Došlo je do greške prilikom učitavanja porudžbine");
      toastService.error("Greška pri učitavanju podataka");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (newStatus) => {
    try {
      setLoading(true);
      await IndentDataService.update(indent.id, { ...indent, indentStatus: newStatus });
      await loadIndentData();
      toastService.success("Status porudžbine je uspešno ažuriran");
    } catch (err) {
      toastService.error("Greška pri ažuriranju statusa");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Da li ste sigurni da želite da obrišete ovu porudžbinu?")) {
      try {
        await IndentDataService.remove(indent.code);
        toastService.success("Porudžbina je uspešno obrisana");
        navigate("/indents");
      } catch (err) {
        toastService.error("Greška pri brisanju porudžbine");
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-md">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        {/* Header Section */}
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Detalji Porudžbine
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                ID Porudžbine: {indent.code}
              </p>
            </div>
            <StatusBadge status={indent.indentStatus} />
          </div>
        </div>

        {/* Details Section */}
        <div className="border-t border-gray-200">
          <dl>
            <DetailRow label="Klijent" value={indent.customerNameOfTheLegalEntity} />
            <DetailRow label="Adresa Dostave" 
              value={`${indent.deliveryAddressCity}, ${indent.deliveryAddressLocation}`} 
            />
            <DetailRow label="Datum Kreiranja" 
              value={new Date(indent.creationTime).toLocaleDateString('sr-RS')} 
            />
            <DetailRow label="Ukupan Iznos" 
              value={new Intl.NumberFormat('sr-RS', { 
                style: 'currency', 
                currency: 'RSD' 
              }).format(indent.totalAmount)} 
            />
          </dl>
        </div>

        {/* Items Table */}
        <div className="px-4 py-5 sm:px-6">
          <h4 className="text-lg font-medium text-gray-900 mb-4">Stavke Porudžbine</h4>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Artikal
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Količina
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cena
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ukupno
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {indent.items?.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.article.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.quantity}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Intl.NumberFormat('sr-RS', { 
                        style: 'currency', 
                        currency: 'RSD' 
                      }).format(item.price)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Intl.NumberFormat('sr-RS', { 
                        style: 'currency', 
                        currency: 'RSD' 
                      }).format(item.price * item.quantity)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Actions Section */}
        <div className="px-4 py-5 sm:px-6 border-t border-gray-200">
          <div className="flex flex-wrap gap-2 justify-end">
            <button
              onClick={() => navigate("/indents")}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Nazad
            </button>
            
            {indent.indentStatus === "PENDING" && (
              <>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Obriši
                </button>
                <button
                  onClick={() => handleStatusUpdate("ACTIVATED")}
                  className="px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Aktiviraj
                </button>
              </>
            )}
            
            {indent.indentStatus === "ACTIVATED" && (
              <button
                onClick={() => handleStatusUpdate("DELIVERED")}
                className="px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Potvrdi Isporuku
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indent;