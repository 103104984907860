import React, { useState, useEffect, useContext } from "react";
import ArticleDataService from "../services/ArticleService";
import logo from "./../images/logo.jpg";
import { ApplicationContext } from "./ApplicationContext";
import './ShopComponent.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Credits from "./Credits";
import axiosInstance from '../services/api';
import { Button, Card, Input } from './common';
import { gridStyles } from '../styles/tailwind.classes';
import { toastService } from '../services/toastService';


const images = require.context("./../images/", true);

const isNewProduct = (creationTime) => {
  if (!creationTime) return false;
  const now = new Date();
  const productDate = new Date(creationTime);
  const diffTime = Math.abs(now - productDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 45;
};

const ShopComponent = (props) => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const { loggedInClient, addOrUpdateBasketItem } = useContext(ApplicationContext);
  const [quantities, setQuantities] = useState({});

  console.log('Image directory:', process.env.REACT_APP_IMAGE_DIRECTORY); // Check the value

  const handleQuantityChange = (id, value) => {
    const newValue = Math.max(value, 0);

    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: newValue,
    }));
  };

  useEffect(() => {
    retrieveArticles();
  }, []);

  const retrieveArticles = () => {
    ArticleDataService.getAll()
      .then((response) => {
        const allArticles = response.data;
        const articles = allArticles.filter(article => article.isVisible === true);

        // Sort articles: new products first, then by ID
        articles.sort((a, b) => {
          const aIsNew = isNewProduct(a.creationTime);
          const bIsNew = isNewProduct(b.creationTime);
          
          if (aIsNew && !bIsNew) return -1;
          if (!aIsNew && bIsNew) return 1;
          
          return Number(a.article_id) - Number(b.article_id);
        });

        // Group sorted articles by brandName
        let groups = articles.reduce((acc, article) => {
          const brandName = article.brand.brandName;
          if (!acc[brandName]) {
            acc[brandName] = [];
          }
          acc[brandName].push(article);
          return acc;
        }, {});

        // Flatten the grouped articles
        const sortedArticles = Object.keys(groups)
          .sort()
          .flatMap(brandName => groups[brandName]);

        setArticles(sortedArticles);
        setFilteredArticles(sortedArticles);
        setBrands(Object.keys(groups).sort());
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addToBasket = (article, quantity) => {
    if (quantity > 0) {
      addOrUpdateBasketItem(article, quantity);
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [article.article_id]: "",
      }));
    }
  };

  const brandDiscount = (brand) => {
    const brandName = brand.brandName;
    const discountForTheBrand = loggedInClient?.discounts?.find((item) => item.brand.brandName === brandName);
    if (discountForTheBrand) {
      return discountForTheBrand.discount;
    } else {
      return 0;
    }
  };

  const handleBrandFilterChange = (brandName) => {
    setSelectedBrand(brandName);
    if (brandName) {
      if (brandName === "ACTIVE PHARMA") {
        setFilteredArticles(articles.filter(article => article.brand.brandName.startsWith(brandName)));
      } else {
        setFilteredArticles(articles.filter(article => article.brand.brandName === brandName));
      }
    } else {
      setFilteredArticles(articles);
    }
  };

  const numberFormatter = new Intl.NumberFormat("sr-RS", {
    style: "currency",
    currency: "RSD",
  });

  const discountedPrice = (price, discount) => {
    return (Number(price) * (1 - Number(discount / 100)).toFixed(2));
  }

  const updatedArticles = filteredArticles.map((article) => {
    const newWholesalePrice = (Number(article.retailPrice) * (1 - Number(brandDiscount(article.brand)) / 100)).toFixed(2);
    return { ...article, wholesalePrice: newWholesalePrice };
  });

  const handleValueValidation = (id, value, minQuantityDemand) => {
    const parsedValue = parseInt(value) || 0;
    const newValue = Math.max(Math.round(parsedValue / minQuantityDemand) * minQuantityDemand, 0);

    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: newValue,
    }));
  };

  /* PROD ENVIROMENT */
  const findArticleImage = (code) => {
    const baseUrl = "http://virdzinijaportal.com/uploads"; // Hardcoded base URL
    return `${baseUrl}/${code}.png`;
  };
  

  /* TEST ENVIROMENT */
 /*  const findArticleImage = (code) => {
    const checkFileExists = (path) => {
      try {
        images(path);
        return true;
      } catch (error) {
        return false;
      }
    };

    if (checkFileExists(`./${code}.jpg`)) {
      return images(`./${code}.jpg`);
    } else if (checkFileExists(`./${code}.png`)) {
      return images(`./${code}.png`);
    } else {
      return logo;
    }
  };
 */
  const addedBrands = new Set();

  // Reusable component for displaying labeled values
  const LabeledValue = ({ label, value, className = "" }) => (
    <div className={`mb-2 ${className}`}>
      <label className="label-text">{label}</label>
      <div className="value-text">{value}</div>
    </div>
  );

  // Quantity Input Component
  const QuantityInput = ({ article, currentQuantity, onQuantityChange, onBlur }) => (
    <div className="flex items-center space-x-2">
      <button
        className="btn-quantity"
        onClick={() => currentQuantity > 0 && onQuantityChange(currentQuantity - article.minimumQuantityDemand)}
      >
        -
      </button>
      <input
        type="number"
        className="input-base text-center w-20"
        min={article.minimumQuantityDemand}
        value={currentQuantity}
        onChange={(e) => onQuantityChange(e.target.value)}
        onBlur={onBlur}
      />
      <button
        className="btn-quantity"
        onClick={() => onQuantityChange(currentQuantity + article.minimumQuantityDemand)}
      >
        +
      </button>
    </div>
  );

  // Brand Filter Component
  const BrandFilter = ({ selectedBrand, brands, onBrandChange }) => (
    <div className="w-full max-w-xs mb-8">
      <h5 className="text-lg font-semibold mb-2">Filtriranje po brendu</h5>
      <select
        className="filter-select"
        value={selectedBrand}
        onChange={(e) => onBrandChange(e.target.value)}
      >
        <option value="">Svi brendovi</option>
        {brands.map((brandName, index) => {
          if (brandName.toLowerCase().includes("active pharma")) {
            if (addedBrands.has("ACTIVE PHARMA")) {
              return null;
            } else {
              addedBrands.add("ACTIVE PHARMA");
              return (
                <option key={index} value="ACTIVE PHARMA">
                  ACTIVE PHARMA
                </option>
              );
            }
          } else {
            return (
              <option key={index} value={brandName}>
                {brandName}
              </option>
            );
          }
        })}
      </select>
    </div>
  );

  // First, add a memo wrapper for the LazyLoadImage to prevent unnecessary re-renders
  const MemoizedProductImage = React.memo(({ src, alt }) => (
    <div className="w-full h-full flex items-center justify-center">
      <LazyLoadImage
        src={src}
        alt={alt}
        className="object-contain max-w-[90%] max-h-[90%]"
        effect="opacity"
        wrapperClassName="w-full h-full !flex items-center justify-center"
      />
    </div>
  ));

  // Memoize the ProductCard component
  const ProductCard = React.memo(({ 
    article, 
    onAddToBasket,
    initialQuantity = "",
    minimumQuantityDemand
  }) => {
    // Move quantity state to component level
    const [quantity, setQuantity] = useState(initialQuantity);
    
    const finalPrice = discountedPrice(
      article.wholesalePrice, 
      brandDiscount(article.brand)
    ).toFixed(2);

    const imagePath = React.useMemo(() => findArticleImage(article.code), [article.code]);

    // Local quantity handlers
    const handleQuantityChange = (value) => {
      const newValue = Math.max(value, 0);
      setQuantity(newValue);
    };

    const handleValueValidation = (value) => {
      const parsedValue = parseInt(value) || 0;
      const newValue = Math.max(Math.round(parsedValue / minimumQuantityDemand) * minimumQuantityDemand, 0);
      setQuantity(newValue);
    };

    const handleAddToBasket = () => {
      onAddToBasket(article, quantity);
      setQuantity("");
      toastService.success('Uspešno dodavanje artikla ' + article.name + ' u korpu!');
    };

    return (
      <Card
        variant={article.isActive ? 'default' : 'inactive'}
        className="flex flex-col h-full relative"
      >
        {isNewProduct(article.creationTime) && (
          <div className="absolute -top-3 -right-3 z-10">
            <div className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white px-4 py-1.5 
              rounded-full text-sm font-bold shadow-lg transform rotate-12 
              animate-pulse border-2 border-white
              flex items-center justify-center">
              <span className="drop-shadow-md">NOVO!</span>
            </div>
          </div>
        )}
        
        <div className="p-4 flex flex-col h-full">
          <div className="h-60 w-full mb-4 bg-white rounded-md">
            <MemoizedProductImage
              src={imagePath}
              alt={article.name}
            />
          </div>

          <div className="flex flex-col flex-grow">
            <div className="flex justify-center mb-3">
              <span className="px-3 py-1 bg-blue-100 text-blue-800 text-sm rounded-full">
                {article.code}
              </span>
            </div>
            
            <h3 className="text-center font-semibold text-gray-800 mb-4 min-h-[4.5rem] line-clamp-3 text-sm sm:text-base leading-tight">
              {article.name}
            </h3>

            <div className="hidden md:flex md:flex-col space-y-2 mb-4">
              <div className="text-sm text-gray-600 flex justify-between">
                <span>Transportno Pakovanje:</span>
                <span className="font-medium">{article.quantityPerTransportPackage} KOM</span>
              </div>
              <div className="text-sm text-gray-600 flex justify-between">
                <span>Minimalna Količina:</span>
                <span className="font-medium">{article.minimumQuantityDemand} KOM</span>
              </div>
              <div className="text-sm text-gray-600 flex justify-between">
                <span>Fakturna Cena:</span>
                <span className="font-medium">{numberFormatter.format(article.wholesalePrice)}</span>
              </div>
            </div>

            <div className="mt-auto">
              <div className="text-center mb-4">
                <p className="text-sm text-gray-600">
                  Cena sa rabatom [{brandDiscount(article.brand)}%]
                </p>
                <p className="text-lg font-bold text-gray-900">
                  {numberFormatter.format(finalPrice)}
                </p>
              </div>

              {article.isActive ? (
                <div className="space-y-3">
                  <div className="flex justify-center items-center space-x-2">
                    <Button
                      variant="secondary"
                      onClick={() => handleQuantityChange(Math.max((quantity || 0) - minimumQuantityDemand, 0))}
                      className="w-12 h-10 flex items-center justify-center"
                    >
                      -
                    </Button>
                    
                    <Input
                      type="number"
                      value={quantity}
                      onChange={(e) => handleQuantityChange(e.target.value)}
                      onBlur={(e) => handleValueValidation(e.target.value)}
                      className="text-center w-20 h-10"
                    />
                    
                    <Button
                      variant="secondary"
                      onClick={() => handleQuantityChange((quantity || 0) + minimumQuantityDemand)}
                      className="w-12 h-10 flex items-center justify-center"
                    >
                      +
                    </Button>
                  </div>

                  <Button
                    variant="success"
                    disabled={!quantity || quantity % minimumQuantityDemand !== 0}
                    onClick={handleAddToBasket}
                    className="w-full h-10"
                  >
                    Dodaj u korpu
                  </Button>
                </div>
              ) : (
                <div className="bg-red-500 text-white text-center py-2 rounded-md">
                  Nedostupno
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  }, (prevProps, nextProps) => {
    return (
      prevProps.article.article_id === nextProps.article.article_id &&
      prevProps.article.creationTime === nextProps.article.creationTime &&
      prevProps.initialQuantity === nextProps.initialQuantity &&
      prevProps.minimumQuantityDemand === nextProps.minimumQuantityDemand
    );
  });

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-6">
      <div className="flex flex-col w-full">
        <div className="mb-8">
          <div className="max-w-xs mx-auto w-full px-4">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">
              Filtriranje po brendu
            </h2>
            <select
              value={selectedBrand}
              onChange={(e) => handleBrandFilterChange(e.target.value)}
              className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Svi brendovi</option>
              {brands.map((brandName, index) => (
                <option key={index} value={brandName}>
                  {brandName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={gridStyles.responsive}>
          {filteredArticles.map((article) => (
            <ProductCard
              key={article.article_id}
              article={article}
              onAddToBasket={addToBasket}
              minimumQuantityDemand={article.minimumQuantityDemand}
            />
          ))}
        </div>

        <Credits className="mt-8" />
      </div>
    </div>
  );
};

export default ShopComponent;
