import ArticleService from './ArticleService';
import BrandService from './BrandService';
import CustomerService from './CustomerService';
import DeliveryAddressService from './DeliveryAddressService';
import DiscountService from './DiscountService';
import IndentService from './IndentService';
import IndentEntryService from './IndentEntryService';
import StatisticsService from './StatisticsService';
import UserService from './UserService';

/**
 * AICommandService
 * 
 * This service provides a structured list of available commands that the AI can use
 * to retrieve data from the system. It focuses on GET requests to ensure read-only
 * operations for safety.
 */
class AICommandService {
  constructor() {
    // Initialize the commands map
    this.commands = this.buildCommandsMap();
  }

  /**
   * Build a structured map of all available GET commands from services
   */
  buildCommandsMap() {
    return {
      articles: {
        getAll: {
          description: "Get all articles",
          method: ArticleService.getAll,
          parameters: [],
          example: "Get a list of all articles"
        },
        getAllActive: {
          description: "Get all active articles",
          method: ArticleService.getAllActiveArticles,
          parameters: [],
          example: "Get a list of all active articles"
        },
        getById: {
          description: "Get article by ID",
          method: ArticleService.get,
          parameters: ["id"],
          example: "Get article with ID 123"
        },
        findByName: {
          description: "Find articles by name",
          method: ArticleService.findByName,
          parameters: ["articleName"],
          example: "Find articles with name 'Shirt'"
        }
      },
      brands: {
        getAll: {
          description: "Get all brands",
          method: BrandService.getAll,
          parameters: [],
          example: "Get a list of all brands"
        },
        getById: {
          description: "Get brand by ID",
          method: BrandService.get,
          parameters: ["id"],
          example: "Get brand with ID 123"
        },
        findByName: {
          description: "Find brands by name",
          method: BrandService.findByName,
          parameters: ["brandName"],
          example: "Find brands with name 'Nike'"
        }
      },
      customers: {
        getAll: {
          description: "Get all customers",
          method: CustomerService.getAll,
          parameters: [],
          example: "Get a list of all customers"
        },
        getById: {
          description: "Get customer by ID",
          method: CustomerService.get,
          parameters: ["id"],
          example: "Get customer with ID 123"
        },
        findByName: {
          description: "Find customers by name",
          method: CustomerService.findByName,
          parameters: ["clientName"],
          example: "Find customers with name 'Acme Corp'"
        },
        findByEmail: {
          description: "Find customers by email",
          method: CustomerService.findByEmail,
          parameters: ["email"],
          example: "Find customer with email 'john@example.com'"
        }
      },
      indents: {
        getAll: {
          description: "Get all indents",
          method: IndentService.getAll,
          parameters: [],
          example: "Get a list of all indents"
        },
        getAllActive: {
          description: "Get all active indents",
          method: IndentService.getAllActiveIndents,
          parameters: [],
          example: "Get a list of all active indents"
        },
        getAllInactive: {
          description: "Get all inactive indents",
          method: IndentService.getAllInactiveIndents,
          parameters: [],
          example: "Get a list of all inactive indents"
        },
        getAllIndents: {
          description: "Get all indents (both active and inactive)",
          method: IndentService.getAllIndents,
          parameters: [],
          example: "Get a list of all indents regardless of status"
        },
        getById: {
          description: "Get indent by ID",
          method: IndentService.get,
          parameters: ["id"],
          example: "Get indent with ID 123"
        },
        findByCode: {
          description: "Find indents by code",
          method: IndentService.findByCode,
          parameters: ["code"],
          example: "Find indent with code 'ORD-2023-001'"
        }
      },
      statistics: {
        getYears: {
          description: "Get years available for statistics",
          method: StatisticsService.getYears,
          parameters: [],
          example: "Get all years available for statistics"
        }
      }
    };
  }

  /**
   * Get all available commands
   * @returns {Object} Map of all available commands
   */
  getAllCommands() {
    return this.commands;
  }

  /**
   * Get commands for a specific service
   * @param {string} serviceName - Name of the service
   * @returns {Object} Commands for the specified service
   */
  getServiceCommands(serviceName) {
    return this.commands[serviceName] || {};
  }

  /**
   * Execute a command
   * @param {string} serviceName - Name of the service
   * @param {string} commandName - Name of the command
   * @param {Array} params - Parameters for the command
   * @returns {Promise} Promise that resolves with the command result
   */
  async executeCommand(serviceName, commandName, params = []) {
    try {
      console.log(`AICommandService.executeCommand called with:`, {
        serviceName,
        commandName,
        params,
        paramsType: typeof params,
        isArray: Array.isArray(params)
      });
      
      const service = this.commands[serviceName];
      if (!service) {
        console.error(`Service '${serviceName}' not found`);
        throw new Error(`Service '${serviceName}' not found`);
      }

      const command = service[commandName];
      if (!command) {
        console.error(`Command '${commandName}' not found in service '${serviceName}'`);
        throw new Error(`Command '${commandName}' not found in service '${serviceName}'`);
      }

      // Check if we have the right number of parameters
      console.log(`Command expects ${command.parameters.length} parameters, got ${params.length}`);
      console.log(`Command parameters:`, command.parameters);
      console.log(`Provided parameters:`, params);
      
      if (command.parameters.length !== params.length) {
        console.error(`Command '${commandName}' expects ${command.parameters.length} parameters, but got ${params.length}`);
        throw new Error(`Command '${commandName}' expects ${command.parameters.length} parameters, but got ${params.length}`);
      }

      // Execute the command with the provided parameters
      console.log(`Executing ${serviceName}.${commandName} with params:`, params);
      const result = await command.method(...params);
      console.log(`Command result:`, result);
      return result.data || result;
    } catch (error) {
      console.error(`Error executing command ${serviceName}.${commandName}:`, error);
      throw error;
    }
  }

  /**
   * Get a list of all available commands in a format suitable for AI
   * @returns {Array} Array of command descriptions
   */
  getCommandsForAI() {
    const commandsList = [];

    // Iterate through all services and commands
    Object.entries(this.commands).forEach(([serviceName, serviceCommands]) => {
      Object.entries(serviceCommands).forEach(([commandName, commandDetails]) => {
        commandsList.push({
          command: `${serviceName}.${commandName}`,
          description: commandDetails.description,
          parameters: commandDetails.parameters,
          example: commandDetails.example
        });
      });
    });

    return commandsList;
  }
}

export default new AICommandService(); 